import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useEffect, useState } from 'react';
import formatters from '../../../Core/Grid/inputFormatter';
import '../../../Core/BudgetCore/budget.scss';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 1450,
        '& .MuiTableRow-root:nth-of-type(even)': {
            backgroundColor: '#fafafa'
        },
        '& .MuiTableCell-root': {
            padding: '7px 2px 7px 2px !important',
            border: '1px solid rgba(0,0,0,0.03)'
            // backgroundColor: 'white'
        },
        '& .MuiTableCell-stickyHeader': {
            padding: '5px 2px !important',
            fontSize: '12px',
            fontWeight: 600
        },
        '& MuiTableCell-root:hover': {
            backgroundColor: '#f5f5f5'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    textError: {
        color: 'white'
    },
    textSucc: {
        color: 'black'
    }
}));

const headCells = [
    {
        columnHeader: 'Branch',
        columnValue: 'branch',
        width: '18%'
    },
    {
        columnHeader: 'Total Profit',
        columnValue: 'totalProfit',
        width: '13%',
        formatter: 'CurrencyThousandSeparatorWithoutZero',
        align: 'right',
        isMakeTotal: true
    },
    {
        columnHeader: 'Total Budget',
        columnValue: 'budget',
        width: '13%',
        formatter: 'CurrencyThousandSeparatorWithoutZero',
        align: 'right',
        isMakeTotal: true
    },
    { columnHeader: 'Variance', columnValue: 'variance', width: '11%', formatter: 'CurrencyThousandSeparatorWithoutZero', align: 'right', isMakeTotal: true },
    { columnHeader: 'Total Volume', columnValue: 'retailVol', width: '13%', formatter: 'ThousandSeparatorWithoutZero', align: 'right', isMakeTotal: true },
    { columnHeader: 'Retail', columnValue: 'retailVol', width: '13%', formatter: 'ThousandSeparatorWithoutZero', align: 'right', isMakeTotal: true },
    { columnHeader: 'Trade', columnValue: 'tradeVol', width: '13%', formatter: 'ThousandSeparatorWithoutZero', align: 'right', isMakeTotal: true }
];

function EnhancedTableHead(props) {
    const { order, orderBy } = props;

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell.columnValue}
                            align={headCell.align ? headCell.align : headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.columnValue ? order : false}
                            width={headCell.width ? headCell.width : 'auto'}
                            style={{
                                minWidth: headCell.minWidth ? headCell.minWidth : 'auto'
                            }}
                        >
                            {headCell.columnHeader}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

export const ProfitUsedTable = (props) => {
    const [state, setState] = useState({
        btn: 1,
        tableRow: [],
        totalRow: [],
        isLoader: true
    });
    const classes = useStyles();
    useEffect(() => {
        if (props.rows.length) {
            let totalRow = {
                totalProfit: 0,
                budget: 0,
                variance: 0,
                totalVol: 0,
                retailVol: 0,
                motabilityVol: 0,
                fleetVol: 0,
                tradeVol: 0
            };
            props.rows.forEach((a) => {
                headCells.forEach((x) => {
                    let storedValue = a[x.columnValue];

                    if (x.isMakeTotal) {
                        totalRow[`${x.columnValue}`] += storedValue;
                    }
                });
            });

            setState((st) => ({ ...st, tableRow: props.rows, totalRow: [totalRow] }));
        }
    }, [props.rows]);

    return (
        <div className="kpiCard">
            <TableContainer>
                <Table className={classes.table} aria-label="simple table" stickyHeader>
                    <EnhancedTableHead classes={classes} rowCount={state?.tableRow?.length} className="type3_Text" SelectedBtId={state.selectedFilterBtn} />
                    <TableBody>
                        {state.tableRow.map((row, i) => {
                            return (
                                <TableRow hover role="checkbox" key={i}>
                                    {headCells.map((q) => {
                                        let storedValue = null;
                                        let formattedStoredValue = row[q.columnValue];
                                        if (q.isPerformOp) {
                                            q.columnValue.forEach((k) => {
                                                storedValue += row[k];
                                            });
                                        } else {
                                            storedValue = row[q.columnValue];
                                        }
                                        if (q.formatter) {
                                            formattedStoredValue = formatters[q.formatter](storedValue) || storedValue;
                                        }
                                        return (
                                            <TableCell
                                                key={storedValue}
                                                className={`type3_Text`}
                                                align={q.align ? q.align : q.numeric ? 'right' : 'left'}
                                                style={{ color: storedValue < 0 ? 'red' : 'black' }}
                                            >
                                                {formattedStoredValue}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                        {state.totalRow.map((totRow, i) => {
                            return (
                                <TableRow hover role="checkbox" key={i}>
                                    {headCells.map((q, ind) => {
                                        let storedValue = totRow[q.columnValue];
                                        let formattedStoredValue = totRow[q.columnValue];
                                        if (q.formatter) {
                                            formattedStoredValue = storedValue ? formatters[q.formatter](storedValue) : '';
                                        }
                                        return (
                                            <TableCell
                                                key={ind}
                                                className={`type3_Text`}
                                                align={q.align ? q.align : q.numeric ? 'right' : 'left'}
                                                style={{ color: storedValue < 0 ? 'red' : 'black' }}
                                            >
                                                <b>{q.columnValue == 'branch' ? 'Total' : storedValue ? formattedStoredValue : ''}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

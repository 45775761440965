import { Grid } from '@material-ui/core';
import React from 'react';
import TabComponent from '../../../Core/Controls/Tabs';
import UncappedVehicled from '.';

// F => Failed, A => Awaiting CAP, M => Manually Updated
const tabList = [
    {
        name: `Awaiting CAP`,
        id: 'A'
    },
    {
        name: `Failed CAP`,
        id: 'F'
    },
    {
        name: `Manually Updated`,
        id: 'M'
    },
    {
        name: `All`,
        id: ''
    }
];

const UnCappedVehicleTabComponent = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const getTabIndex = tabList.findIndex((tab) => tab.id === params.get('Type'));
    let typeParamIndex = getTabIndex > -1 ? getTabIndex : undefined;
    const tabs = tabList.map((q) => {
        return { label: `${q.name}`, body: <UncappedVehicled unCappedID={q.id} key={q.id} /> };
    });

    return (
        <div className="budgetScreen">
            <Grid container spacing={1}>
                <Grid item xs={6} sm={9} md={9} lg={6} xl={10}>
                    <div className="mainHeading alignLeft screenMainHeader">Uncapped Vehicles</div>
                </Grid>
                <Grid item xs={12} style={{ height: '660px', width: '100%' }}>
                    <TabComponent tabList={tabs} selectedIndex={+typeParamIndex || undefined} />
                </Grid>
            </Grid>
        </div>
    );
};

export default UnCappedVehicleTabComponent;

import { Grid } from '@material-ui/core';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CallMadeIcon from '@material-ui/icons/CallMade';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { BudgetSendBtn, SmallLinkedButton } from './budgetButton';
import { BasicTable } from './table';
import formatters from '../Grid/inputFormatter';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../Controls/ScreenResolution';
import './budget.scss';
import React from 'react';

const setPercentage = (profit, budget) => {
    if (!profit) {
        return 0;
    } else {
        if (budget == null || budget == 0) {
            return 100;
        } else {
            let value = (+profit / +budget) * 100;

            return Math.round(value) <= 100 ? Math.round(value) : 100;
        }
    }
};

const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));

//This comment refers to the 'Branch Overview' component.

export const DepartmentCard = (props) => {
    const { profit, budget, labour, showLink } = props;
    const history = useHistory();
    const sendLink = () => {
        history.push({
            pathname: props.url,
            state: props.type || ''
        });
    };

    return (
        <Grid container style={{ textAlign: 'justify' }} justifyContent="space-around" alignItems="center" className="BOprofitloss_Card">
            <Grid item xs={12}>
                {showLink ? (
                    <span
                        style={{
                            padding: '6px 10px 0px 0px',
                            float: 'right'
                        }}
                    >
                        <BudgetSendBtn onClick={sendLink} />
                    </span>
                ) : null}
            </Grid>
            <Grid item>
                <div className={showLink ? 'BOringPropertyAfterSale' : 'BOringProperty'}>
                    <CircularProgressbar
                        value={setPercentage(profit, budget)}
                        text={`${setPercentage(profit, budget) || 0}%`}
                        styles={buildStyles({
                            textColor:
                                setPercentage(profit, budget) < 50
                                    ? '#D92641'
                                    : setPercentage(profit, budget) >= 50 && setPercentage(profit, budget) < 75
                                    ? '#ff9f00'
                                    : setPercentage(profit, budget) >= 75
                                    ? '#569101'
                                    : '#D92641',
                            pathColor:
                                setPercentage(profit, budget) < 50
                                    ? '#D92641'
                                    : setPercentage(profit, budget) >= 50 && setPercentage(profit, budget) < 75
                                    ? '#ff9f00'
                                    : setPercentage(profit, budget) >= 75
                                    ? '#569101'
                                    : '#D92641',
                            textSize: '27px'
                        })}
                    />
                </div>
            </Grid>
            <Grid item xs={8} sm={7}>
                <div className="BOsecondaryHeading" style={{ padding: '0px 0px 12px 0px', marginTop: showLink ? -13 : 0 }}>
                    {props.dept}
                </div>
                <div
                    style={{
                        marginBottom: 5,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div className="BOtype3_Text w50">Total {labour ? 'Labour' : 'Profit'}</div>
                    <div className="BOtype1_Text alignLeft w50">{formatters.CurrencyThousandSeparatorWithoutZero(profit)}</div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="BOtype3_Text alignLeft w50">Total Budget</div>
                    <div className="BOtype1_Text alignLeft w50">{formatters.CurrencyThousandSeparatorWithoutZero(budget)}</div>
                </div>
            </Grid>
        </Grid>
    );
};

export const DepartmentBudgetCard = (props) => {
    const [width] = useWindowSize();
    const history = useHistory();
    const { icon, value, label, profitVal, showLink, est_profit } = props;
    const sendLink = () => {
        history.push({
            pathname: props.url,
            state: props.type || ''
        });
    };

    return (
        <div className="BObranchOverViewKip_card" style={{ margin: '12px 6px' }}>
            {showLink ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '7px 0px'
                    }}
                >
                    <BudgetSendBtn onClick={sendLink} />
                </div>
            ) : null}

            <div className="BOtype1_Text" style={{ paddingBottom: '4px' }}>
                {value || 0}
            </div>
            <div className="BOtype2_Text">{label || 'N/A'}</div>

            {!props.hideBudget ? (
                <>
                    <div className="BOtype3_Text" style={{ paddingTop: width > 600 ? '12px' : '6px' }}>
                        BUDGET
                    </div>
                    <div
                        className="BOtype1_Text p4"
                        style={{
                            color: icon == 'up' ? '#569101' : '#E51A54'
                        }}
                    >
                        <span>{profitVal || 0}</span>
                        <span style={{ verticalAlign: 'middle' }}>
                            {icon === 'down' ? <ArrowDownwardIcon className="BOtype2_Text" /> : <ArrowUpwardIcon className="BOtype2_Text" />}
                        </span>
                    </div>
                </>
            ) : (
                <>
                    <div className="BOtype3_Text" style={{ paddingTop: width > 600 ? '12px' : '6px' }}>
                        EST. PROFIT
                    </div>
                    <div className="BOtype1_Text p4" style={{ color: est_profit >= 0 ? '#569101' : '#E51A54' }}>
                        <span>{formatters.CurrencyThousandSeparatorWithoutZero(est_profit) || 0}</span>
                    </div>
                </>
            )}
        </div>
    );
};

//This comment refers to the 'Profit and Loss' component.

export const ProfitLossCards = (props) => {
    const [WindowWidths] = useWindowSize();

    const { totalCalProfit, profit, budget, branchName, retailsUnits, motabilityUnits, tradeUnits, newUnits, usedUnits, branchID } = props;
    return (
        <Grid
            container
            justifyContent={WindowWidths <= 600 ? 'space-between' : 'space-around'}
            alignItems="center"
            className="SPnLprofitloss_Card"
            style={{ padding: '0px 10px ' }}
        >
            <Grid item xs={12}>
                <Grid container>
                    <Grid
                        xs
                        item
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="SPnLsecondaryHeading p4">{branchName}</div>
                    </Grid>
                    {props.selectedFilterBtnId === 'New' || props.selectedFilterBtnId === 'Used' ? (
                        <Grid item>
                            <BudgetSendBtn onClick={() => props.onRedirectToNewUnits(branchID, props.selectedFilterBtnId)} />
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
            <Grid item>
                <div className="PanLringProperty">
                    <CircularProgressbar
                        value={totalCalProfit}
                        text={`${totalCalProfit}%`}
                        styles={buildStyles({
                            textColor:
                                totalCalProfit < 50
                                    ? '#D92641'
                                    : totalCalProfit >= 50 && totalCalProfit < 75
                                    ? '#ff9f00'
                                    : totalCalProfit >= 75
                                    ? '#569101'
                                    : '#D92641',
                            pathColor:
                                totalCalProfit < 50
                                    ? '#D92641'
                                    : totalCalProfit >= 50 && totalCalProfit < 75
                                    ? '#ff9f00'
                                    : totalCalProfit >= 75
                                    ? '#569101'
                                    : '#D92641',
                            textSize: '27px',
                            dominantBaseline: 'central'
                        })}
                    />
                </div>
            </Grid>
            <Grid item>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="SPnLtype3_Text alignLeft ">Total Profit</div>
                    <div className="SPnLtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(profit)}</div>
                </div>

                <div style={{ width: '100%' }}>
                    <div className="SPnLtype3_Text alignLeft ">Total Budget</div>
                    <div className="SPnLtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(budget)}</div>
                </div>
            </Grid>
            {props.selectedFilterBtnId === 'New' || props.selectedFilterBtnId === 'Used' ? (
                <Grid item>
                    <div style={{ width: '100%', marginBottom: 10 }}>
                        <div className="SPnLtype3_Text alignLeft ">Retail Units</div>
                        <div className="SPnLtype1_Text  alignLeft">{formatters.ThousandSeparatorWithoutZero(retailsUnits)}</div>
                    </div>

                    <div style={{ width: '100%' }}>
                        <div className="SPnLtype3_Text alignLeft ">{props.selectedFilterBtnId === 'New' ? 'Motability Units' : 'Trade Units'}</div>
                        <div className="SPnLtype1_Text  alignLeft">
                            {props.selectedFilterBtnId === 'New'
                                ? formatters.ThousandSeparatorWithoutZero(motabilityUnits)
                                : formatters.ThousandSeparatorWithoutZero(tradeUnits)}
                        </div>
                    </div>
                </Grid>
            ) : (
                <Grid item style={{ margin: '10px 0px' }}>
                    <div style={{ marginBottom: 10 }}>
                        <Grid item xs>
                            <div className="SPnLtype3_Text alignLeft">New Units &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                <div className="SPnLtype1_Text alignLeft"> {formatters.ThousandSeparatorWithoutZero(newUnits)}</div>
                            </Grid>
                            <Grid item>
                                <div className="SPnLtype3_Text alignLeft">
                                    <BudgetSendBtn onClick={() => props.onRedirectToNewUnits(branchID, 'New')} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid item xs>
                            <div className="SPnLtype3_Text alignLeft">Used Units &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                <div className="SPnLtype1_Text alignLeft"> {formatters.ThousandSeparatorWithoutZero(usedUnits)}</div>
                            </Grid>
                            <Grid item>
                                <div className="SPnLtype3_Text alignLeft">
                                    <BudgetSendBtn onClick={() => props.onRedirectToNewUnits(branchID, 'Used')} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

//This comment refers to the 'Aftersale Profit and Loss' component.

export const ASalePandLCard = (props) => {
    const { totalCalProfit, profit, budget, branchID } = props;
    return (
        <Grid container justifyContent="space-around" alignItems="center" className="ASPnLprofitloss_Card">
            <Grid item xs={12}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <div className="ASPnLsecondaryHeading p4">{props.label}</div>
                    <BudgetSendBtn onClick={() => props.onRedirectFun(branchID)} />
                </div>
            </Grid>
            <Grid item>
                <div className="PanLringProperty">
                    <CircularProgressbar
                        value={totalCalProfit}
                        text={`${totalCalProfit}%`}
                        styles={buildStyles({
                            textColor:
                                totalCalProfit < 50
                                    ? '#D92641'
                                    : totalCalProfit >= 50 && totalCalProfit < 75
                                    ? '#ff9f00'
                                    : totalCalProfit >= 75
                                    ? '#569101'
                                    : '#D92641',
                            pathColor:
                                totalCalProfit < 50
                                    ? '#D92641'
                                    : totalCalProfit >= 50 && totalCalProfit < 75
                                    ? '#ff9f00'
                                    : totalCalProfit >= 75
                                    ? '#569101'
                                    : '#D92641',
                            textSize: '27px'
                        })}
                    />
                </div>
            </Grid>
            <Grid item style={{ margin: 10 }}>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="ASPnLtype3_Text alignLeft ">TOTAL LABOUR</div>
                    <div className="ASPnLtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(profit)}</div>
                </div>

                <div style={{ width: '100%' }}>
                    <div className="ASPnLtype3_Text alignLeft ">Total Budget</div>
                    <div className="ASPnLtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(budget)}</div>
                </div>
            </Grid>
        </Grid>
    );
};

//This comment refers to the 'Profit and Loss Detail' component.

export const DetailsCards = (props) => {
    const { up, value, label, profitVal, budgetLable } = props;
    return (
        <div className="containerPadding">
            <Grid container className="PnLDbranchOverViewKip_card" alignItems="center" alignContent="center">
                <Grid item xs={12}>
                    {label === 'Total Units' ? (
                        <>
                            <div className="PnLDtype3_Text" style={{ marginBottom: -5 }}>
                                {label}
                            </div>
                            <small style={{ fontSize: 10 }}>(excl. Fleet/Trade)</small>
                        </>
                    ) : (
                        <div className="PnLDtype3_Text p4">{label || 'N/A'}</div>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <div className="PnLDtype1_Text p4">{value || 0}</div>
                </Grid>
                <Grid item xs={12}>
                    <div className="PnLDtype3_Text p4">{budgetLable ? budgetLable : 'BUDGET'}</div>
                </Grid>

                <Grid item xs={12}>
                    <div
                        className="PnLDtype1_Text p4"
                        style={{
                            padding: 0,
                            color: up == undefined ? 'black' : up ? '#569101' : '#E51A54'
                        }}
                    >
                        <span>{profitVal || 0}</span>
                        {up !== undefined && (
                            <span style={{ verticalAlign: 'middle' }}>
                                {!up ? <ArrowDownwardIcon className="PnLDtype2_Text" /> : <ArrowUpwardIcon className="PnLDtype2_Text" />}
                            </span>
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export const DetailsCardsWithoutBudget = (props) => {
    const { value, label, up, showBudget } = props;
    return (
        <div className="containerPadding">
            <Grid container className="PnLDbranchOverViewKip_card" alignItems="center" alignContent="center">
                <Grid item xs={12}>
                    <div className="PnLDtype3_Text p4">{label || 'N/A'}</div>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <div
                            className="PnLDtype1_Text p4"
                            style={{
                                color: showBudget ? (!up ? '#E51A54' : '#569101') : 'black'
                            }}
                        >
                            <span>{value || 0}</span>
                            <span style={{ verticalAlign: 'middle' }}>
                                {showBudget ? !up ? <ArrowDownwardIcon className="PnLDtype2_Text" /> : <ArrowUpwardIcon className="PnLDtype2_Text" /> : null}
                            </span>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export const ProfitLossDetailsCards = (props) => {
    let history = useHistory();
    const { data } = props;
    let onRedirectToNewUnits = (link, searchQuery) => {
        history.push({ pathname: link, search: searchQuery || '' });
    };
    return (
        <Grid container justifyContent="space-around" alignItems="center" className="kpiCard">
            <Grid item xs={12}>
                <div
                    style={{
                        display: 'flex',
                        alignTtems: 'center',
                        alignContent: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <div className="PnLDsecondaryHeading p4">{props.label}</div>
                    <div>
                        <BudgetSendBtn onClick={() => onRedirectToNewUnits(props.link, props.searchQuery)} />
                    </div>
                </div>
            </Grid>
            <Grid item xs={4}>
                {!data.labour ? (
                    <div style={{ width: '100%', marginBottom: 10 }}>
                        <div className="PnLDtype3_Text alignLeft ">Total Profit</div>
                        <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.profit)}</div>
                    </div>
                ) : (
                    <div style={{ width: '100%', marginBottom: 10 }}>
                        <div className="PnLDtype3_Text alignLeft ">TOTAL LABOUR </div>
                        <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.labour)}</div>
                    </div>
                )}

                <div style={{ width: '100%' }}>
                    <div className="PnLDtype3_Text alignLeft ">Budget Variance</div>
                    <div className="PnLDtype1_Text  alignLeft" style={{ color: data?.variance >= 0 ? 'green' : 'red' }}>
                        {formatters.CurrencyThousandSeparatorWithoutZero(Math.abs(data?.variance))}
                    </div>
                </div>
            </Grid>
            <Grid item xs={4}>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="PnLDtype3_Text alignLeft">Total Budget</div>
                    <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.budget)}</div>
                </div>

                <div style={{ width: '100%' }}>
                    {data.TotalProfitPreviousYear ? (
                        <>
                            <div className="PnLDtype3_Text alignLeft">Total Prior Year </div>
                            <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.TotalProfitPreviousYear)}</div>
                        </>
                    ) : (
                        <div style={{ width: '100%' }}>
                            {data.ppu ? (
                                <>
                                    <div className="PnLDtype3_Text alignLeft">Total PPU</div>
                                    <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.ppu)}</div>
                                </>
                            ) : (
                                <>
                                    <div className="PnLDtype3_Text alignLeft">&nbsp;</div>
                                    <div className="PnLDtype1_Text  alignLeft">&nbsp;</div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </Grid>
            <Grid item xs={3}>
                <div className="PanLDetailringProperty">
                    <CircularProgressbar
                        value={setPercentage(data.labour ? data.labour : data?.profit, data?.budget)}
                        text={`${setPercentage(data.labour ? data.labour : data?.profit, data?.budget)}%`}
                        styles={buildStyles({
                            textColor:
                                setPercentage(data.labour ? data.labour : data?.profit, data?.budget) < 50
                                    ? '#D92641'
                                    : setPercentage(data.labour ? data.labour : data?.profit, data?.budget) >= 50 &&
                                      setPercentage(data.labour ? data.labour : data?.profit, data?.budget) < 75
                                    ? '#ff9f00'
                                    : setPercentage(data.labour ? data.labour : data?.profit, data?.budget) >= 75
                                    ? '#569101'
                                    : '#D92641',
                            pathColor:
                                setPercentage(data.labour ? data.labour : data?.profit, data?.budget) < 50
                                    ? '#D92641'
                                    : setPercentage(data.labour ? data.labour : data?.profit, data?.budget) >= 50 &&
                                      setPercentage(data.labour ? data.labour : data?.profit, data?.budget) < 75
                                    ? '#ff9f00'
                                    : setPercentage(data.labour ? data.labour : data?.profit, data?.budget) >= 75
                                    ? '#569101'
                                    : '#D92641',
                            textSize: '27px'
                        })}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <BasicTable data={props.table} />
            </Grid>
            <Grid item xs={12}></Grid>
        </Grid>
    );
};
//This comment refers to the 'Used Vehicle Stock and Used Stock Valuation' components.

export const UsedStocksCard = (props) => {
    const { CurrentStockValue, AverageStockValue, PreviousYearAverage, showStock, showOverage, setState } = props;

    const handleRedirect = (link, branchID) => {
        let array = [];
        if (branchID) {
            array.push(branchID);
        }

        setState((st) => ({
            ...st,
            groupIds: [branchID]
        }));
        let query = `ID=${branchID}`;
        props.history.push({
            pathname: link,
            search: query
        });
    };

    return (
        <div>
            <Grid container className="USVUnitKipTwo_card">
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={9}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className="USVsecondaryHeading alignLeft">{props.branch}</div>
                                </Grid>
                                <Grid item>
                                    <div style={{ width: '100%' }}>
                                        <div className="USVtype3_Text">Current Stock Value</div>
                                        <div className="USVtype1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(CurrentStockValue)}</div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{ width: '100%', marginBottom: 5, display: 'flex' }}>
                                        <div className="USVtype3_Text">Average Stock Value</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className="USVtype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(AverageStockValue)}
                                        </div>
                                    </div>

                                    <div style={{ width: '100%', display: 'flex' }}>
                                        <div className="USVtype3_Text">Previous Year Average</div>
                                        &nbsp;&nbsp;&nbsp;
                                        <div className="USVtype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(PreviousYearAverage)}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container justifyContent="flex-end">
                                {showStock && (
                                    <Grid item>
                                        <SmallLinkedButton
                                            style={{ marginBottom: 5 }}
                                            active={props.active}
                                            onClick={() => handleRedirect(props.stockLink, props.branchID)}
                                        >
                                            STOCK LIST &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                                        </SmallLinkedButton>
                                    </Grid>
                                )}

                                {showOverage && (
                                    <Grid item>
                                        <SmallLinkedButton onClick={() => handleRedirect(props.priorityLink, props.branchID)}>
                                            OVERAGE &nbsp; &nbsp;
                                            <CallMadeIcon style={{ fontSize: 10 }} />
                                        </SmallLinkedButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export const UsedStocksCardMobileView = (props) => {
    const { CurrentStockValue, AverageStockValue, PreviousYearAverage, showStock, showOverage, setState } = props;

    const handleRedirect = (link, branchID) => {
        let array = [];
        if (branchID) {
            array.push(branchID);
        }
        setState((st) => ({
            ...st,
            groupIds: [branchID]
        }));
        let query = `ID=${branchID}`;
        props.history.push({
            pathname: link,
            search: query
        });
    };

    return (
        <div>
            <Grid container className="USVUnitKipTwo_card" spacing={0}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={7}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className="USVsecondaryHeading alignLeft">{props.branch}</div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={5}>
                            <Grid container justifyContent="flex-end">
                                {showStock && (
                                    <Grid item xs={6} style={{ padding: '0px 6px' }}>
                                        <SmallLinkedButton active={props.active} onClick={() => handleRedirect(props.stockLink, props.branchID)}>
                                            STOCK LIST
                                        </SmallLinkedButton>
                                    </Grid>
                                )}

                                {showOverage && (
                                    <Grid item xs={6} style={{ padding: '0px 6px' }}>
                                        <SmallLinkedButton onClick={() => handleRedirect(props.priorityLink, props.branchID)}>OVERAGE</SmallLinkedButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            <div style={{ width: '100%', marginTop: 0 }}>
                                <div className="USVtype3_Text">Current Stock Value</div>
                                <div className="USVtype1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(CurrentStockValue)}</div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', marginTop: 5 }}>
                                <div>
                                    <div style={{ fontSize: 10 }}>Average Stock Value</div>
                                    <div className="USVtype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {formatters.CurrencyThousandSeparatorWithoutZero(AverageStockValue)}
                                    </div>
                                </div>

                                <div style={{ marginLeft: 8 }}>
                                    <div style={{ fontSize: 10 }}>Previous Year Average</div>
                                    <div className="USVtype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {formatters.CurrencyThousandSeparatorWithoutZero(PreviousYearAverage)}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            style={{
                                background: '#569101',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '12px',
                                marginTop: 10
                            }}
                        >
                            <div>
                                <div style={{ textAlign: 'center', fontWeight: 600 }}>{props.Units}</div>
                                <div style={{ fontWeight: 600 }}>UNITS</div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export const UsedStocksCardTWO = (props) => {
    const [WindowWidths] = useWindowSize();
    const { list } = props;
    return (
        <>
            {list.length > 0 ? (
                <Grid container justifyContent="space-evenly" alignItems="center" className="USVUnitKipThree_card" spacing={1}>
                    {list.map((m, index) => {
                        return (
                            <Grid item style={{ padding: '10px 3px' }} key={index}>
                                <Grid container direction="column" spacing={WindowWidths >= 600 ? 1 : 0} alignItems="center">
                                    <Grid item className="USVtype3_Text">
                                        {m.valuationDate1}
                                    </Grid>
                                    <Grid item className="USVtype1_Text">
                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.stockValue1)}
                                    </Grid>

                                    {list.length - 1 == index ? (
                                        <Grid item className="kpifonts">
                                            &nbsp;
                                        </Grid>
                                    ) : (
                                        <Grid
                                            item
                                            className="USVtype3_Text"
                                            style={{
                                                fontWeight: 'bold',
                                                color: m.getCalculatedvalue >= 0 ? '#569101' : '#E51A54'
                                            }}
                                        >
                                            {parseFloat(m.getCalculatedvalue).toFixed(2)}%
                                            {m.getCalculatedvalue >= 0 ? (
                                                <ArrowUpwardIcon className="USVtype3_Text" />
                                            ) : (
                                                <ArrowDownwardIcon className="USVtype3_Text" />
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Grid container justifyContent="space-evenly" alignItems="center" className="USVUnitKipThree_card">
                    <Grid item>
                        <div>
                            <div className="panLlabel mb10 mt10">No Record Found</div>
                        </div>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

//This comment refers to the 'Aged Vehicle Stock' component.

export const AgedStockCardMobileView = (props) => {
    const { data, showOverage, showStock } = props;
    let stockTurnColor = data.stockTurn <= 5 ? 'red' : data.stockTurn < 10 ? '#FFBF00' : '#569101';
    const handleRedirect = (link, branchID) => {
        let array = [];
        if (branchID) {
            array.push(branchID);
        }
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: array }));
        props.history.push({
            pathname: link,
            branchID: branchID
        });
    };

    const renderGridItem = (title, value) => (
        <Grid item>
            <Grid container spacing={0} direction="column" alignItems="center">
                <Grid item className="AVStype4_Text">
                    {title}
                </Grid>
                <Grid item className="AVStype1_Text">{`${value}`}</Grid>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Grid container className="AVSUnitKipTwo_card">
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={7}>
                            <div className="AVStype2_Text">{data.branchShortName}</div>
                            <div className="AVStype1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data.total)}</div>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container alignContent="flex-end">
                                {showStock && (
                                    <Grid item xs={6} style={{ padding: '0px 6px' }}>
                                        <SmallLinkedButton active={props.active} onClick={() => handleRedirect(props.stockLink, props.branchID)}>
                                            STOCK LIST
                                        </SmallLinkedButton>
                                    </Grid>
                                )}
                                {showOverage && (
                                    <Grid item xs={6} style={{ padding: '0px 6px' }}>
                                        <SmallLinkedButton onClick={() => handleRedirect(props.priorityLink, props.branchID)}>OVERAGE</SmallLinkedButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item xs={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ marginTop: '13px' }}>
                                    <Grid container spacing={1}>
                                        <Grid item className="AVStype3_Text">
                                            Total CAP Clean
                                        </Grid>
                                        <Grid item className="AVStype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(data.totalCapClean)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '10px' }}>
                                    <Grid container spacing={1}>
                                        <Grid item className="AVStype3_Text">
                                            Total Variance&nbsp;&nbsp;
                                        </Grid>
                                        <Grid
                                            item
                                            className="AVStype3_Text"
                                            style={{
                                                textAlign: 'left',
                                                fontWeight: 'bold',
                                                color: data.totalCapClean - data.total > 0 ? '#569101' : '#E51A54'
                                            }}
                                        >
                                            <span>{formatters.CurrencyThousandSeparatorWithoutZero(data.totalCapClean - data.total)}</span>
                                            <span style={{ verticalAlign: 'middle' }}>
                                                {data.totalCapClean - data.total < 0 ? (
                                                    <ArrowDownwardIcon className="AVStype3_Text" />
                                                ) : (
                                                    <ArrowUpwardIcon className="type3_Text" />
                                                )}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} direction="column">
                                <Grid item style={{ padding: 0 }}>
                                    <div style={{ fontSize: 9, textAlign: 'center' }}>Avg DIS</div>
                                    <div div style={{ textAlign: 'center', fontSize: 15 }}>
                                        {data.avgDis}
                                    </div>
                                </Grid>
                                <Grid item style={{ padding: 5 }}>
                                    <div style={{ borderBottom: '1px solid' }}></div>
                                </Grid>
                                <Grid item style={{ padding: 0 }}>
                                    <div style={{ fontSize: 9, textAlign: 'center' }}>Stock Turn</div>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            color: stockTurnColor,
                                            fontSize: 15
                                        }}
                                    >
                                        {data.stockTurn}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            style={{
                                background: '#569101',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '12px',
                                marginRight: 5,
                                padding: 6
                            }}
                        >
                            <div>
                                <div style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>{props.units}</div>
                                <div style={{ fontSize: 15, fontWeight: 600 }}>UNITS</div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Grid container spacing={1} justifyContent="space-evenly">
                        {props.list.map((m) => {
                            if (m.branchID === props.branchID) {
                                return (
                                    <>
                                        {renderGridItem('0 - 30 DAYS', m.totalCount0to30)}
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        {renderGridItem('31 - 60 DAYS', m.totalCount31to60)}
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        {renderGridItem('61 - 90 DAYS', m.totalCount61to90)}
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        {renderGridItem('91 - 120 DAYS', m.totalCount91to120)}
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        {renderGridItem('121 - 150 DAYS', m.totalCount121to150)}
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        {renderGridItem('150+ DAYS', m.totalCount150Plus)}
                                    </>
                                );
                            }
                            return null;
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export const AgedStockCard = (props) => {
    const { data, showOverage, showStock } = props;
    let stockTurnColor = data.stockTurn <= 5 ? 'red' : data.stockTurn < 10 ? '#FFBF00' : '#569101';
    const handleRedirect = (link, branchID) => {
        let array = [];
        if (branchID) {
            array.push(branchID);
        }
        let query = `ID=${branchID}`;
        props.history.push({
            pathname: link,
            search: query
        });
    };

    return (
        <>
            <Grid container className="AVSUnitKipTwo_card">
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <div className="AVStype2_Text">{data.branch}</div>
                            <div className="AVStype1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data.total)}</div>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container direction="column" alignContent="flex-end" spacing={1}>
                                {showStock && (
                                    <Grid item>
                                        <SmallLinkedButton active={props.active} onClick={() => handleRedirect(props.stockLink, props.branchID)}>
                                            STOCK LIST &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                                        </SmallLinkedButton>
                                    </Grid>
                                )}
                                {showOverage && (
                                    <Grid item>
                                        <SmallLinkedButton onClick={() => handleRedirect(props.priorityLink, props.branchID)}>
                                            OVERAGE &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                                        </SmallLinkedButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent="space-between" style={{ marginTop: '8px' }}>
                        <Grid item xs={7}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item className="AVStype3_Text">
                                            Total CAP Clean
                                        </Grid>
                                        <Grid item className="AVStype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(data.totalCapClean)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item className="AVStype3_Text">
                                            Total Variance&nbsp;&nbsp;
                                        </Grid>
                                        <Grid
                                            item
                                            className="AVStype3_Text"
                                            style={{
                                                textAlign: 'left',
                                                fontWeight: 'bold',
                                                color: data.totalCapClean - data.total > 0 ? '#569101' : '#E51A54'
                                            }}
                                        >
                                            <span>{formatters.CurrencyThousandSeparatorWithoutZero(data.totalCapClean - data.total)}</span>
                                            <span style={{ verticalAlign: 'middle' }}>
                                                {data.totalCapClean - data.total < 0 ? (
                                                    <ArrowDownwardIcon className="AVStype3_Text" />
                                                ) : (
                                                    <ArrowUpwardIcon className="type3_Text" />
                                                )}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <div className="AVStype3_Text">Avg DIS</div>
                                    <div className="AVStype1_Text" style={{ textAlign: 'center' }}>
                                        {data.avgDis}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div style={{ borderRight: '1px solid', height: '100%' }}></div>
                                </Grid>
                                <Grid item>
                                    <div className="AVStype3_Text">Stock Turn</div>
                                    <div
                                        className="AVStype1_Text"
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            color: stockTurnColor
                                        }}
                                    >
                                        {data.stockTurn}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export const AgedStocksCardTWO = (props) => {
    const { list } = props;
    return (
        <div className="kpiCard">
            <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                {list
                    .filter((m) => m.branchID === props.branchID)
                    .map((m) => (
                        <Grid item key={m.valuationDays1}>
                            <div>
                                <div className="panLlabel mb10">{m.valuationDays1}</div>
                                <div className="panLvalue mb10">{`${m.totalCount1} (${m.percent1}%)`}</div>
                                <div className="budgetCard2BudgetNum" style={{ textAlign: 'center', fontSize: 18 }}>
                                    {formatters.CurrencyThousandSeparatorWithoutZero(m.stockValue1)}
                                </div>
                            </div>
                        </Grid>
                    ))}
            </Grid>
        </div>
    );
};

export const AgedStocksDayRangeCard = (props) => {
    const [WindowWidths] = useWindowSize();

    const renderGridItem = (title, count, percentage, stockValue) => (
        <Grid item xs={4} sm={2} style={{ padding: '15px 3px' }}>
            <Grid container spacing={WindowWidths >= 600 ? 1 : 0} direction="column" alignItems="center">
                <Grid item className="AVStype3_Text">
                    {title}
                </Grid>
                <Grid item className="AVStype1_Text">{`${count} (${percentage}%)`}</Grid>
                <Grid item className="AVStype3_Text">
                    {formatters.CurrencyThousandSeparatorWithoutZero(stockValue)}
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <Grid container justifyContent={'space-evenly'} alignItems="center" className="AVSUnitKipThree_card">
            {props.list.map((m, i) => {
                if (m.branchID === props.branchID) {
                    return (
                        <React.Fragment key={`${i}_AVS_DAYS`}>
                            {renderGridItem('0 - 30 DAYS', m.totalCount0to30, m.totalPerc0to30, m.stockValue0to30)}
                            {renderGridItem('31 - 60 DAYS', m.totalCount31to60, m.totalPerc31to60, m.stockValue31to60)}
                            {renderGridItem('61 - 90 DAYS', m.totalCount61to90, m.totalPerc61to90, m.stockValue61to90)}
                            {renderGridItem('91 - 120 DAYS', m.totalCount91to120, m.totalPerc91to120, m.stockValue91to120)}
                            {renderGridItem('121 - 150 DAYS', m.totalCount121to150, m.totalPerc121to150, m.stockValue121to150)}
                            {renderGridItem('150+ DAYS', m.totalCount150Plus, m.totalPerc150Plus, m.stockValue150Plus)}
                        </React.Fragment>
                    );
                }
                return null;
            })}
        </Grid>
    );
};

//This comment refers to the 'Sales Reporting' component.

export const SalesCard = (props) => {
    const { value, label, subLabelOne, subLabelTwo } = props;
    return (
        <div className="containerPadding">
            <Grid container className="SRbranchOverViewKip_card" alignItems="center" alignContent="center">
                <Grid item xs={12} className="SRtype3_Text p4">
                    {label || 'N/A'}
                </Grid>
                <Grid item xs={12} className="SRtype1_Text p4">
                    {formatters.ThousandSeparatorWithoutZero(value) || 0}
                </Grid>

                <Grid item xs={12} style={{ display: 'flex ' }}>
                    <div style={{ width: '100%', borderRight: '1px solid' }}>
                        <div className="SRtype3_Text p4">{subLabelOne}</div>
                        <div className="SRtype3_Text p4" style={{ color: '#569101' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(props.newProfit)}
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        <div className="SRtype3_Text p4">{subLabelTwo}</div>
                        <div className="SRtype3_Text p4" style={{ color: '#569101' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(props.newPpu)}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useEffect, useState } from 'react';
import formatters from '../../../Core/Grid/inputFormatter';
import '../../../Core/BudgetCore/budget.scss';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 1450,
        '& .MuiTableRow-root:nth-of-type(even)': {
            backgroundColor: '#fafafa'
        },
        '& .MuiTableCell-root': {
            padding: '7px 6px 7px 6px !important',
            borderBottom: 'none'
        },
        '& .MuiTableCell-stickyHeader': {
            padding: '5px 2px !important',
            fontSize: '12px',
            fontWeight: 600
        },
        '& MuiTableCell-root:hover': {
            backgroundColor: '#f5f5f5'
        },
        '& .MuiTableCell-head': {
            lineHeight: '18px !important',
            verticalAlign: 'top !important'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    textError: {
        color: 'white'
    },
    textSucc: {
        color: 'black'
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'white'
        }
    }
}))(TableRow);

const headerColumn = [
    {
        columnHeader: 'Branch',
        columnValue: 'branch',
        columnName: 'branch',
        width: '8%',
        align: 'left'
    },
    {
        columnHeader: 'Total N + U Profit',
        columnValue: 'totalProfit',
        columnName: 'totalProfit',
        isMakeTotal: true,
        width: '4%',
        formatter: 'CurrencyThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'Total N + U Budget',
        columnValue: 'totalBudget',
        columnName: 'totalBudget',
        isMakeTotal: true,
        width: '4%',
        formatter: 'CurrencyThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'Total N & U Variance',
        columnValue: 'totalVariance',
        columnName: 'totalVariance',
        isMakeTotal: true,
        width: '4%',
        formatter: 'CurrencyThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'Total Volume New',
        columnValue: 'totalVol',
        isMakeTotal: true,
        columnName: 'totalVol',
        width: '4%',
        formatter: 'ThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: '',
        columnValue: 'emptyFirst',
        width: '2%',
        align: 'left'
    },
    {
        columnHeader: 'New Retail',
        columnValue: 'newRetailVol',
        columnName: 'newRetailVol',
        isMakeTotal: true,
        width: '4%',
        formatter: 'ThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'Motability',
        columnValue: 'newMotabilityVol',
        columnName: 'newMotabilityVol',
        isMakeTotal: true,
        width: '4%',
        formatter: 'ThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'Fleet',
        columnValue: 'newFleetVol',
        columnName: 'newFleetVol',
        isMakeTotal: true,
        width: '4%',
        formatter: 'ThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'Total New',
        columnValue: ['newFleetVol', 'newMotabilityVol', 'newRetailVol'],
        columnName: 'totalNew',
        isPerformOp: '+',
        // isCalculated: true,
        isMakeTotal: true,
        width: '4%',
        formatter: 'ThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'New Profit',
        columnValue: ['newMotabilityProfit', 'newRetailProfit'],
        columnName: 'newProfit',
        isPerformOp: '+',
        // isCalculated: true,
        isMakeTotal: true,
        width: '4%',
        formatter: 'CurrencyThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'New Budget',
        columnValue: 'newBudget',
        columnName: 'newBudget',
        isMakeTotal: true,
        width: '5%',
        formatter: 'CurrencyThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'Variance to Budget',
        columnValue: ['newBudget', 'newProfit'],
        columnName: 'newVarianceBudget',
        isCalculated: true,
        isMakeTotal: true,
        isPerformOp: '-',
        width: '4%',
        formatter: 'CurrencyThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: '',
        columnValue: 'emptyFirst',
        width: '2%',
        align: 'left'
    },
    {
        columnHeader: 'Used Retail',
        columnValue: 'usedRetailVol',
        columnName: 'usedRetailVol',
        isMakeTotal: true,
        width: '4%',
        formatter: 'ThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'Trade',
        columnValue: 'usedTradeVol',
        columnName: 'usedTradeVol',
        isMakeTotal: true,
        width: '4%',
        formatter: 'ThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'Total Used',
        columnValue: ['usedRetailVol', 'usedTradeVol'],
        columnName: 'totalUsed',
        isPerformOp: '+',
        isMakeTotal: true,
        width: '4%',
        formatter: 'ThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'Used Profit',
        columnValue: ['usedRetailProfit', 'usedTradeProfit'],
        columnName: 'usedProfit',
        isMakeTotal: true,
        isPerformOp: '+',
        width: '4%',
        formatter: 'CurrencyThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'Used Budget',
        columnValue: 'usedBudget',
        columnName: 'usedBudget',
        isMakeTotal: true,
        width: '5%',
        formatter: 'CurrencyThousandSeparatorWithoutZero',
        align: 'center'
    },
    {
        columnHeader: 'Variance to Budget',
        columnValue: ['usedBudget', 'usedProfit'],
        columnName: 'usedVarianceBudget',
        isPerformOp: '-',
        isCalculated: true,
        isMakeTotal: true,
        width: '4%',
        formatter: 'CurrencyThousandSeparatorWithoutZero',
        align: 'center'
    }
];

function EnhancedTableHead(props) {
    return (
        <TableHead>
            <TableRow>
                {headerColumn.map((headCell, index) => {
                    return (
                        <TableCell
                            key={headCell.columnValue}
                            // align={headCell.align ? headCell.align : headCell.numeric ? 'right' : 'left'}
                            align={headCell.align ? headCell.align : 'center'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            width={headCell.width ? headCell.width : 'auto'}
                            style={{
                                minWidth: headCell.minWidth ? headCell.minWidth : 'auto',
                                backgroundColor: headCell.columnHeader ? 'auto' : 'white',
                                borderTop: index != 0 && headCell.columnHeader ? '1px solid black' : 'none',
                                borderLeft: index == 1 || index == 6 || index == 14 ? '1px solid black' : 'none',
                                borderRight: index == 4 || index == 12 || index == 19 ? '1px solid black' : 'none'
                            }}
                        >
                            {headCell.columnHeader}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

export const CombineProfitTable = (props) => {
    const [state, setState] = useState({
        btn: 1,
        tableRow: [],
        totalRow: [],
        isLoader: true
    });
    const classes = useStyles();

    useEffect(() => {
        if (props.rows.length) {
            let totalRow = {
                totalBudget: 0,
                totalVariance: 0,
                totalVol: 0,
                newRetailVol: 0,
                newMotabilityVol: 0,
                newFleetVol: 0,
                totalNew: 0,
                usedRetailVol: 0,
                usedTradeVol: 0,
                totalUsed: 0,
                totalProfit: 0,
                newProfit: 0,
                newBudget: 0,
                newVarianceBudget: 0,
                usedVarianceBudget: 0,
                usedProfit: 0,
                usedBudget: 0
            };
            let sortedRows = props.rows.map((a) => {
                let tempObj = {};
                headerColumn.forEach((x) => {
                    let storedValue = null;
                    if (x.isPerformOp == '+') {
                        if (x.isCalculated) {
                            x.columnValue.forEach((k) => {
                                storedValue = tempObj[k] + storedValue;
                            });
                        } else {
                            x.columnValue.forEach((k) => {
                                storedValue += a[k];
                            });
                        }
                    } else if (x.isPerformOp == '-') {
                        if (x.isCalculated) {
                            x.columnValue.forEach((k) => {
                                storedValue = tempObj[k] - storedValue;
                            });
                        } else {
                            x.columnValue.forEach((k) => {
                                storedValue -= a[k];
                            });
                        }
                    } else {
                        storedValue = a[x.columnValue];
                    }
                    if (x.columnName) {
                        tempObj[x.columnName] = storedValue;
                    }
                    if (x.isMakeTotal) {
                        totalRow[`${x.columnName}`] += storedValue;
                    }
                });
                return tempObj;
            });

            setState((st) => ({ ...st, tableRow: sortedRows, totalRow: [totalRow] }));
        }
    }, [props.rows]);

    return (
        <>
            <div className="kpiCard">
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table" stickyHeader>
                        <EnhancedTableHead classes={classes} rowCount={state?.tableRow?.length} className="type3_Text" SelectedBtId={state.selectedFilterBtn} />
                        <TableBody>
                            {state.tableRow.map((row, i) => {
                                return (
                                    <StyledTableRow hover role="checkbox" key={i}>
                                        {headerColumn.map((q, colIndex) => {
                                            let storedValue = row[q.columnName];
                                            let formattedStoredValue = row[q.columnName];
                                            if (q.formatter) {
                                                formattedStoredValue = formatters[q.formatter](storedValue) || storedValue;
                                            }
                                            return (
                                                <TableCell
                                                    key={colIndex}
                                                    className={`type3_Text`}
                                                    align={q.align ? q.align : q.numeric ? 'right' : 'left'}
                                                    style={{
                                                        // borderBottom: colIndex != 0 && q.columnHeader ? '1px solid black' : 'none',
                                                        borderLeft: colIndex == 1 || colIndex == 6 || colIndex == 14 ? '1px solid black' : 'none',
                                                        borderRight: colIndex == 4 || colIndex == 12 || colIndex == 19 ? '1px solid black' : 'none',
                                                        color: storedValue < 0 ? 'red' : 'black'
                                                    }}
                                                >
                                                    {formattedStoredValue}
                                                </TableCell>
                                            );
                                        })}
                                    </StyledTableRow>
                                );
                            })}
                            {state.totalRow.map((totRow, i) => {
                                return (
                                    <TableRow hover role="checkbox" key={i}>
                                        {headerColumn.map((q, colIndex) => {
                                            let storedValue = totRow[q.columnName];
                                            let formattedStoredValue = totRow[q.columnName];
                                            if (q.formatter) {
                                                formattedStoredValue = storedValue ? formatters[q.formatter](storedValue) : '';
                                            }
                                            return (
                                                <>
                                                    {true ? (
                                                        <TableCell
                                                            key={colIndex}
                                                            className={`type3_Text`}
                                                            align={q.align ? q.align : q.numeric ? 'right' : 'left'}
                                                            style={{
                                                                borderTop: colIndex != 0 && q.columnHeader ? '1px solid black' : 'none',
                                                                borderBottom: colIndex != 0 && q.columnHeader ? '1px solid black' : 'none',
                                                                borderLeft: colIndex == 1 || colIndex == 6 || colIndex == 14 ? '1px solid black' : 'none',
                                                                borderRight: colIndex == 4 || colIndex == 12 || colIndex == 19 ? '1px solid black' : 'none',
                                                                color: storedValue < 0 ? 'red' : 'black'
                                                            }}
                                                        >
                                                            <b>{q.columnName == 'branch' ? 'Total' : storedValue ? formattedStoredValue : ''}</b>
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell
                                                            key={colIndex}
                                                            className={`type3_Text`}
                                                            align={q.align ? q.align : q.numeric ? 'right' : 'left'}
                                                            style={{
                                                                borderTop: colIndex != 0 && q.columnHeader ? '1px solid black' : 'none',
                                                                borderBottom: colIndex != 0 && q.columnHeader ? '1px solid black' : 'none',
                                                                borderLeft: colIndex == 1 || colIndex == 6 || colIndex == 14 ? '1px solid black' : 'none',
                                                                borderRight: colIndex == 4 || colIndex == 12 || colIndex == 19 ? '1px solid black' : 'none'
                                                            }}
                                                        >
                                                            <b>{'xxxx'}</b>
                                                        </TableCell>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};

import React, { useEffect, useState, useContext } from 'react';
import { InputLabel } from '@material-ui/core';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import { SecondaryButton, TextBox, YesNoButton } from '../../../Core/FormInput';
import { Typography } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { getPortalSettings, addPortalSettings } from '../../../Core/Service/Settings';
import './style.scss';
import AppContext from '../../../App/AppContext';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Settings', path: '/', active: true }
];

const Settings = (props) => {
    const [state, setState] = useState({
        Title: '',
        CallUs: '',
        OpeningTimes: '',
        EmailUs: '',
        HeaderTextColorCode: '',
        HeaderBackgroundColorCode: '',
        MenubarTextColorCode: '',
        MenubarBackgroundColorCode: '',
        productLogo: '',
        bannerLogo: '',
        navigationMenuActive: '',
        bannerImage: '',
        Id: ''
    });
    const { showToast } = useContext(AppContext);

    const pullSettingsAndUpdateState = async () => {
        let res = await getPortalSettings();
        const data = res?.data?.details[0];
        if (res.success) {
            setState((st) => ({
                ...st,
                Id: data.portalSettingId,
                Title: data.portalSettingTitle,
                CallUs: data.portalSettingCallUs,
                OpeningTimes: data.portalSettingOpeningTime,
                EmailUs: data.portalSettingEmailUs,
                HeaderTextColorCode: data.portalHeaderTextColour,
                HeaderBackgroundColorCode: data.portalHeaderBackgroundColour,
                MenubarTextColorCode: data.portalMenuBarTextColour,
                MenubarBackgroundColorCode: data.portalMenuBarBackgroundColour,
                navigationMenuActive: data.portalSettingDisplayTopNavCounters,
                productLogo: data.portalSettingMainLogo,
                bannerLogo: data.portalSettingBanner,
                bannerImage: data.portalSettingBannerImage,
                portalSettingTimeOut: data.portalSettingTimeOut
            }));
        }
        if (!res.success) {
            console.log(res.message);
        }
    };
    let handleImage = (bannerLogo) => (event) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            const { result } = e.target;
            setState((st) => ({
                ...st,
                [bannerLogo]: result
            }));
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    const clearImage = (name) => (e) => {
        setState((st) => ({ ...st, [name]: null }));
    };

    const modelgridStyle = {
        formatterImageContainer: { width: '100%', textAlign: 'left' },
        formatterImage: { width: 120, maxHeight: 60, backgroundSize: 'cover' }
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleSubmit = async () => {
        const data = {
            PortalSettingId: state.Id,
            PortalSettingCallUs: state.CallUs,
            PortalSettingOpeningTime: state.OpeningTimes,
            PortalSettingEmailUs: state.EmailUs,
            PortalSettingTitle: state.Title,
            PortalHeaderBackgroundColour: state.HeaderBackgroundColorCode,
            PortalHeaderTextColour: state.HeaderTextColorCode,
            PortalMenuBarBackgroundColour: state.MenubarBackgroundColorCode,
            PortalMenuBarTextColour: state.MenubarTextColorCode,
            PortalSettingBannerImage: state.bannerImage,
            PortalSettingDisplayTopNavCounters: state.navigationMenuActive,
            PortalSettingMainLogo: state.productLogo,
            PortalSettingBanner: state.bannerLogo,
            portalSettingTimeOut: +state.portalSettingTimeOut
        };

        let res = await addPortalSettings(data);
        if (res.success) {
            showToast('Portal setting saved successfully');
            props.history.push('/');
        } else {
            showToast(res.message);
        }
    };

    useEffect(async () => {
        pullSettingsAndUpdateState();
    }, []);

    return (
        <div className="setting-screen" style={{ margin: 10 }}>
            <BreadCrumbs crumbs={crumbs} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <FormLabel>Portal Title</FormLabel>
                    <TextBox inputLabel="Portal Title" placeholder="Portal Title" value={state.Title} onChange={handleFieldChange} name="Title" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormLabel>Call Us</FormLabel>
                    <TextBox inputLabel="Call Us" placeholder="Number" value={state.CallUs} onChange={handleFieldChange} name="CallUs" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormLabel>Opening Times</FormLabel>
                    <TextBox
                        inputLabel="Opening Times"
                        placeholder="Opening Times"
                        value={state.OpeningTimes}
                        onChange={handleFieldChange}
                        name="OpeningTimes"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormLabel>Email Us</FormLabel>
                    <TextBox inputLabel="Email us" placeholder="Email us" value={state.EmailUs} onChange={handleFieldChange} name="EmailUs" />
                </Grid>
            </Grid>
            <Grid item container spacing={2}>
                <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'left' }}>
                    <Typography variant="h5" color="secondary">
                        Colors
                    </Typography>
                    <hr />
                </Grid>
            </Grid>
            <Grid container spacing={0} justifyContent="space-around" style={{ textAlign: 'left' }}>
                <Grid item container spacing={4} xs={12} md={3} sm={6}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">
                            <b>Header Text</b>
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                        <SketchPicker
                            color={state.HeaderTextColorCode}
                            onChange={(updatedColor) =>
                                setState((state) => ({
                                    ...state,
                                    HeaderTextColorCode: updatedColor.hex
                                }))
                            }
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={4} xs={12} md={3} sm={6}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">
                            <b>Header Background</b>
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                        <SketchPicker
                            color={state.HeaderBackgroundColorCode}
                            onChange={(updatedColor) =>
                                setState((state) => ({
                                    ...state,
                                    HeaderBackgroundColorCode: updatedColor.hex
                                }))
                            }
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={4} xs={12} md={3} sm={6}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">
                            <b>Menu bar Text</b>
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                        <SketchPicker
                            color={state.MenubarTextColorCode}
                            onChange={(updatedColor) =>
                                setState((state) => ({
                                    ...state,
                                    MenubarTextColorCode: updatedColor.hex
                                }))
                            }
                        />
                    </Grid>
                </Grid>

                <Grid item container spacing={4} xs={12} md={3} sm={6}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">
                            <b>Menu bar Background</b>
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                        <SketchPicker
                            color={state.MenubarBackgroundColorCode}
                            onChange={(updatedColor) =>
                                setState((state) => ({
                                    ...state,
                                    MenubarBackgroundColorCode: updatedColor.hex
                                }))
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} item xs={12} sm={12} style={{ textAlign: 'left', paddingTop: '15px' }}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h5" color="secondary">
                        Logos
                    </Typography>
                    <hr />
                </Grid>
                <Grid item xs={12} lg={3} sm={3} style={{ textAlign: 'left' }}>
                    <InputLabel shrink>Product Logo</InputLabel>
                    {state.productLogo ? (
                        <SecondaryButton onClick={clearImage('productLogo')}>Remove Logo</SecondaryButton>
                    ) : (
                        <input type="file" name="productLogo" onChange={handleImage('productLogo')} accept="image/*" />
                    )}
                </Grid>
                <Grid item xs={12} sm={3} lg={3} style={modelgridStyle.formatterImageContainer}>
                    <InputLabel shrink>{'\u{2800}'}</InputLabel>
                    {state.productLogo ? (
                        <img
                            src={state.productLogo?.includes('base64') ? state.productLogo : 'data:image/png;base64,' + state.productLogo}
                            alt={'logo'}
                            style={modelgridStyle.formatterImage}
                        />
                    ) : (
                        <p> Using default logo</p>
                    )}
                </Grid>
                <Grid item xs={12} lg={3} sm={3} style={{ textAlign: 'left' }}>
                    <InputLabel shrink style={{ paddingRight: '60px' }}>
                        Banner Image
                    </InputLabel>
                    {state.bannerLogo ? (
                        <SecondaryButton onClick={clearImage('bannerLogo')}>Remove Logo</SecondaryButton>
                    ) : (
                        <input type="file" name="bannerLogo" onChange={handleImage('bannerLogo')} accept="image/*" />
                    )}
                </Grid>
                <Grid item xs={12} sm={3} lg={3} style={modelgridStyle.formatterImageContainer}>
                    <InputLabel shrink>{'\u{2800}'}</InputLabel>
                    {state.bannerLogo ? (
                        <img
                            src={state.bannerLogo?.includes('base64') ? state.bannerLogo : 'data:image/png;base64,' + state.bannerLogo}
                            alt={'logo'}
                            style={modelgridStyle.formatterImage}
                        />
                    ) : (
                        <p> Using default logo</p>
                    )}
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12}>
                <Grid item xs={12} md={12} lg={12} style={{ top: 0, textAlign: 'left' }}>
                    <Typography variant="h5" color="secondary">
                        Settings
                    </Typography>
                    <hr />
                </Grid>
                <Grid container style={{ textAlign: 'left' }} spacing={2}>
                    <Grid xs={12} sm={6} md={3} container item>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <FormLabel>Display Top Navigation Counter?</FormLabel>
                        </Grid>
                        <YesNoButton
                            state={state.navigationMenuActive}
                            name="navigationMenuActive"
                            onYesClick={() => handleClick('navigationMenuActive', true)}
                            onNoClick={() => handleClick('navigationMenuActive', false)}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={3} container item>
                        <Grid item xs={12}>
                            <InputLabel>Session Timeout In Minutes</InputLabel>
                            <TextBox
                                inputLabel="Session Timeout In Minutes"
                                placeholder="Minutes"
                                value={state.portalSettingTimeOut}
                                onChange={handleFieldChange}
                                name="portalSettingTimeOut"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} container spacing={2} style={{ marginTop: '10px' }}>
                <Grid item xs={6} className="submit_btn" style={{ textAlign: 'center' }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6} className="submit_btn" style={{ textAlign: 'center' }}>
                    <SecondaryButton
                        fullWidth
                        onClick={() => {
                            props.history.push('/');
                        }}
                    >
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default Settings;

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './salesReportTable.scss';
import { BudgetSendBtn, SalesCardDayBtn } from '../../../Core/BudgetCore/budgetButton';
import formatters from '../../../Core/Grid/inputFormatter';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { useEffect } from 'react';

const DayUnitList = {
    1: 'mon',
    2: 'tue',
    3: 'wed',
    4: 'thu',
    5: 'fri',
    6: 'sat',
    7: 'sun'
};

const LabelList = {
    1: 'MONDAY',
    2: 'TUESDAY',
    3: 'WEDNESDAY',
    4: 'THRUSDAY',
    5: 'FRIDAY',
    6: 'SATURDAY',
    7: 'SUNDAY'
};

const buttonList = [
    { id: 1, Name: 'M' },
    { id: 2, Name: 'T' },
    { id: 3, Name: 'W' },
    { id: 4, Name: 'T' },
    { id: 5, Name: 'F' },
    { id: 6, Name: 'S' },
    { id: 7, Name: 'S' }
];

const useStyles = makeStyles({
    table: {
        minWidth: 1450
    }
});

const useStyless = makeStyles({
    table: {
        minWidth: '100%'
    }
});

const StyledTotalTableCell = withStyles((theme) => ({
    body: {
        fontSize: '0.9rem',
        borderRight: '1px solid rgba(0,0,0,0.6)',
        padding: '0px',
        fontFamily: `Montserrat`,
        background: '#f5f5f5'
    }
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,

        borderBottom: 'none',
        padding: '10px',
        borderRight: '1px solid rgba(0,0,0,0.4)',
        fontFamily: `Montserrat`,
        fontSize: '0.8rem'
    },

    body: {
        fontSize: '0.9rem',
        borderRight: '1px solid rgba(0,0,0,0.6)',
        padding: '0px',
        fontFamily: `Montserrat`
    }
}))(TableCell);

const StyledTableCellMob = withStyles((theme) => ({
    body: {
        fontSize: '0.9rem',
        borderRight: '1px solid rgba(0,0,0,0.6)',
        padding: '5px 0px',
        fontFamily: `Montserrat`
    }
}))(TableCell);

const StyledTableCellGreen = withStyles((theme) => ({
    head: {
        backgroundColor: '#569101',
        color: theme.palette.common.white,
        borderRight: '1px solid black',
        padding: '0px 10px',
        fontFamily: `Montserrat`,
        fontSize: '0.7rem'
    }
}))(TableCell);

const StyledTableCellWithoutColor = withStyles((theme) => ({
    head: {
        backgroundColor: 'none',
        color: 'none',
        border: 'none',
        fontFamily: `Montserrat`
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'white'
        },
        borderTop: '1px solid rgba(0,0,0,0.1)'
    }
}))(TableRow);

export default function SalesReportingTable(props) {
    const history = useHistory();

    const [state, setState] = useState({ totalRow: [] });

    useEffect(() => {
        let TotalArray = [];

        if (props.rows.length > 0) {
            TotalArray = [...props.rows].reduce((acc, obj) => {
                var index = acc.find((item) => item);
                if (index) {
                    index.monProfit += obj.monProfit;
                    index.monUnits += obj.monUnits;
                    index.tueProfit += obj.tueProfit;
                    index.tueUnits += obj.tueUnits;
                    index.wedProfit += obj.wedProfit;
                    index.wedUnits += obj.wedUnits;
                    index.thuProfit += obj.thuProfit;
                    index.thuUnits += obj.thuUnits;
                    index.friProfit += obj.friProfit;
                    index.friUnits += obj.friUnits;
                    index.satProfit += obj.satProfit;
                    index.satUnits += obj.satUnits;
                    index.sunProfit += obj.sunProfit;
                    index.sunUnits += obj.sunUnits;
                    index.totalProfit += obj.totalProfit;
                    index.totalUnits += obj.totalUnits;
                    return acc;
                }
                acc.push({ ...obj });
                return acc;
            }, []);
        }

        setState((st) => ({ ...st, totalRow: [...TotalArray] }));
    }, [props.rows]);

    let redirectTotable = (branchID) => {
        const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));
        let array = [];

        if (branchID) {
            array.push(branchID);
        }

        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: array }));

        history.push({
            pathname: '/SalesReportDetails',
            search: `?type=${props.type}`
        });
    };

    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCellWithoutColor width="15%"></StyledTableCellWithoutColor>
                        <StyledTableCell align="center" width="10%">
                            MONDAY
                        </StyledTableCell>
                        <StyledTableCell align="center" width="10%">
                            TUESDAY
                        </StyledTableCell>
                        <StyledTableCell align="center" width="10%">
                            WEDNESDAY
                        </StyledTableCell>
                        <StyledTableCell align="center" width="10%">
                            THURSDAY
                        </StyledTableCell>
                        <StyledTableCell align="center" width="10%">
                            FRIDAY
                        </StyledTableCell>
                        <StyledTableCell align="center" width="10%">
                            SATURDAY
                        </StyledTableCell>
                        <StyledTableCell align="center" width="10%">
                            SUNDAY
                        </StyledTableCell>
                        <StyledTableCell align="center" width="10%">
                            TOTAL
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableHead>
                    <TableRow>
                        <StyledTableCellWithoutColor component="th" scope="row"></StyledTableCellWithoutColor>
                        <StyledTableCellGreen align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text br">UNIT</div>
                                <div className="w-50 type3_Text">PROFIT</div>
                            </div>
                        </StyledTableCellGreen>
                        <StyledTableCellGreen align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text br">UNIT</div>
                                <div className="w-50 type3_Text">PROFIT</div>
                            </div>
                        </StyledTableCellGreen>
                        <StyledTableCellGreen align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text br">UNIT</div>
                                <div className="w-50 type3_Text">PROFIT</div>
                            </div>
                        </StyledTableCellGreen>
                        <StyledTableCellGreen align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text br">UNIT</div>
                                <div className="w-50 type3_Text">PROFIT</div>
                            </div>
                        </StyledTableCellGreen>
                        <StyledTableCellGreen align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text br">UNIT</div>
                                <div className="w-50 type3_Text">PROFIT</div>
                            </div>
                        </StyledTableCellGreen>
                        <StyledTableCellGreen align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text br">UNIT</div>
                                <div className="w-50 type3_Text">PROFIT</div>
                            </div>
                        </StyledTableCellGreen>
                        <StyledTableCellGreen align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text br">UNIT</div>
                                <div className="w-50 type3_Text">PROFIT</div>
                            </div>
                        </StyledTableCellGreen>
                        <StyledTableCellGreen align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text br">UNIT</div>
                                <div className="w-50 type3_Text">PROFIT</div>
                            </div>
                        </StyledTableCellGreen>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.rows.map((m, i) => (
                        <StyledTableRow key={i}>
                            <StyledTableCell component="th" scope="row" width={150}>
                                <div
                                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 5px' }}
                                    className="type3_Text"
                                >
                                    {m.branchName}
                                    <BudgetSendBtn onClick={() => redirectTotable(m.branchID)} />
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <div className="salesProfitHeader">
                                    <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(m.monUnits) || '-'}</div>

                                    <div className="w-50 type3_Text">{formatters.CurrencyThousandSeparatorWithoutZero(m.monProfit) || '-'}</div>
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <div className="salesProfitHeader">
                                    <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(m.tueUnits) || '-'}</div>

                                    <div className="w-50 type3_Text ">{formatters.CurrencyThousandSeparatorWithoutZero(m.tueProfit) || '-'}</div>
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <div className="salesProfitHeader">
                                    <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(m.wedUnits) || '-'}</div>

                                    <div className="w-50 type3_Text ">{formatters.CurrencyThousandSeparatorWithoutZero(m.wedProfit) || '-'}</div>
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <div className="salesProfitHeader">
                                    <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(m.thuUnits) || '-'}</div>

                                    <div className="w-50 type3_Text ">{formatters.CurrencyThousandSeparatorWithoutZero(m.thuProfit) || '-'}</div>
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <div className="salesProfitHeader">
                                    <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(m.friUnits) || '-'}</div>

                                    <div className="w-50 type3_Text">{formatters.CurrencyThousandSeparatorWithoutZero(m.friProfit) || '-'}</div>
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <div className="salesProfitHeader">
                                    <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(m.satUnits) || '-'}</div>

                                    <div className="w-50 type3_Text">{formatters.CurrencyThousandSeparatorWithoutZero(m.satProfit) || '-'}</div>
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <div className="salesProfitHeader">
                                    <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(m.sunUnits) || '-'}</div>

                                    <div className="w-50 type3_Text">{formatters.CurrencyThousandSeparatorWithoutZero(m.sunProfit) || '-'}</div>
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <div className="salesProfitHeader">
                                    <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(m.totalUnits) || '-'}</div>

                                    <div className="w-50 type3_Text">{formatters.CurrencyThousandSeparatorWithoutZero(m.totalProfit) || '-'}</div>
                                </div>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
                <TableBody>
                    <StyledTableRow>
                        <StyledTotalTableCell component="th" scope="row" width={150}>
                            <div
                                style={{
                                    textAlign: 'center',
                                    padding: '5px 5px',
                                    fontWeight: 600
                                }}
                            >
                                TOTAL
                            </div>
                        </StyledTotalTableCell>
                        <StyledTotalTableCell align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(state?.totalRow[0]?.monUnits) || '-'}</div>

                                <div className="w-50 type3_Text">{formatters.CurrencyThousandSeparatorWithoutZero(state?.totalRow[0]?.monProfit) || '-'}</div>
                            </div>
                        </StyledTotalTableCell>
                        <StyledTotalTableCell align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(state?.totalRow[0]?.tueUnits) || '-'}</div>

                                <div className="w-50 type3_Text ">{formatters.CurrencyThousandSeparatorWithoutZero(state?.totalRow[0]?.tueProfit) || '-'}</div>
                            </div>
                        </StyledTotalTableCell>
                        <StyledTotalTableCell align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(state?.totalRow[0]?.wedUnits) || '-'}</div>

                                <div className="w-50 type3_Text ">{formatters.CurrencyThousandSeparatorWithoutZero(state?.totalRow[0]?.wedProfit) || '-'}</div>
                            </div>
                        </StyledTotalTableCell>
                        <StyledTotalTableCell align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(state?.totalRow[0]?.thuUnits) || '-'}</div>

                                <div className="w-50 type3_Text ">{formatters.CurrencyThousandSeparatorWithoutZero(state?.totalRow[0]?.thuProfit) || '-'}</div>
                            </div>
                        </StyledTotalTableCell>
                        <StyledTotalTableCell align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(state?.totalRow[0]?.friUnits) || '-'}</div>

                                <div className="w-50 type3_Text">{formatters.CurrencyThousandSeparatorWithoutZero(state?.totalRow[0]?.friProfit) || '-'}</div>
                            </div>
                        </StyledTotalTableCell>
                        <StyledTotalTableCell align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(state?.totalRow[0]?.satUnits) || '-'}</div>

                                <div className="w-50 type3_Text">{formatters.CurrencyThousandSeparatorWithoutZero(state?.totalRow[0]?.satProfit) || '-'}</div>
                            </div>
                        </StyledTotalTableCell>
                        <StyledTotalTableCell align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(state?.totalRow[0]?.sunUnits) || '-'}</div>

                                <div className="w-50 type3_Text">{formatters.CurrencyThousandSeparatorWithoutZero(state?.totalRow[0]?.sunProfit) || '-'}</div>
                            </div>
                        </StyledTotalTableCell>
                        <StyledTotalTableCell align="center">
                            <div className="salesProfitHeader">
                                <div className="w-50 type3_Text mr">{formatters.ThousandSeparatorWithoutZero(state?.totalRow[0]?.totalUnits) || '-'}</div>

                                <div className="w-50 type3_Text">{formatters.CurrencyThousandSeparatorWithoutZero(state?.totalRow[0]?.totalProfit) || '-'}</div>
                            </div>
                        </StyledTotalTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export const SalesReportingTableMobile = (props) => {
    const [state, setState] = useState({
        tableBtn: 1
    });

    const [WindowWidths] = useWindowSize();
    const handleDays = (id) => {
        setState((st) => ({
            ...st,
            tableBtn: id
        }));
    };
    const history = useHistory();

    let redirectTotable = (branchID) => {
        const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));
        let array = [];

        if (branchID) {
            array.push(branchID);
        }

        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: array }));

        history.push({
            pathname: '/SalesReportDetails',
            search: `?type=${props.type}`
        });
    };

    const classes = useStyless();

    return (
        <div style={{ width: '100%' }}>
            <Grid container spacing={1} justifyContent="space-between" style={{ marginBottom: 10 }}>
                {buttonList.map((n, i) => {
                    return (
                        <Grid item key={i}>
                            <SalesCardDayBtn active={state.tableBtn === n.id} onClick={() => handleDays(n.id)}>
                                {n.Name}
                            </SalesCardDayBtn>
                        </Grid>
                    );
                })}
            </Grid>

            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center" colSpan={3}>
                                {LabelList[state.tableBtn]}
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <StyledTableCellGreen align="center">DEALER</StyledTableCellGreen>
                            <StyledTableCellGreen align="center">UNITS</StyledTableCellGreen>
                            <StyledTableCellGreen align="center">PROFIT</StyledTableCellGreen>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {props.rows.map((m) => (
                            <StyledTableRow>
                                <StyledTableCellMob width={'33.33%'} align="left">
                                    <div
                                        style={{
                                            fontWeight: 800,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '0px 5px'
                                        }}
                                        className="type3_Text"
                                    >
                                        {<span style={{ fontSize: 10 }}>{WindowWidths >= 600 ? m.branchName : m.branchShortName}</span>}
                                        <BudgetSendBtn onClick={() => redirectTotable(m.branchID)} />
                                    </div>
                                </StyledTableCellMob>
                                <StyledTableCellMob width={'33.33%'} align="center">
                                    {formatters.ThousandSeparatorWithoutZero(m[`${DayUnitList[state.tableBtn]}Units`])}
                                </StyledTableCellMob>
                                <StyledTableCellMob width={'33.33%'} align="center">
                                    {formatters.CurrencyThousandSeparatorWithoutZero(m[`${DayUnitList[state.tableBtn]}Profit`])}
                                </StyledTableCellMob>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Tooltip, CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AppContext from '../../../App/AppContext';
import { SecondaryButton } from '../../../Core/FormInput';
import { getBranchById, postBranchData } from '../../../Core/Service/branch-screen-service';
import '../commonStyle.scss';
import { Backup } from '@material-ui/icons';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import getMultiImgResized from '../../../Core/CommonFunctions/multiImgResizer';

function BranchModal(props) {
    const [state, setState] = useState({
        id: null,
        branchCode: '',
        branchName: '',
        branchStampImage: '',
        errors: {},
        showLoader: true
    });

    const [inputList, setInputList] = useState([]);

    const { showToast } = useContext(AppContext);

    useEffect(() => {
        const fetchData = async () => {
            if (props.id) {
                try {
                    let res = await getBranchById(props.id);
                    if (res.success) {
                        let data = res?.data?.stampImages;
                        setInputList(data);
                        setState((st) => ({
                            ...st,
                            branchName: props.branchName || '',
                            branchCode: props.branchCode || '',
                            id: props.id,
                            showLoader: false
                        }));
                    } else {
                        // Handle the case where the request was not successful
                        console.error('Request was not successful:');
                    }
                } catch (error) {
                    // Handle any other errors that might occur during the asynchronous operation
                    console.error('An error occurred:', error);
                }
            }
        };

        fetchData();
    }, []);

    const upload = async (e) => {
        getMultiImgResized(e.target.files, (fileUrl) => {
            let data = fileUrl.map((m, i) => {
                return { branchStampID: null, branchStampName: state.branchName, branchStampImage: m };
            });
            setInputList([...inputList, ...data]);
        });
    };

    const onImageRemoveAll = () => {
        setInputList([]);
    };

    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    const submitHandler = async () => {
        const branchData = {
            id: state.id,
            BranchStampImage: inputList
        };
        let res = await postBranchData(branchData);
        if (res.success) {
            showToast('Branch Updated sucessfully');
            props.onClose(true);
        } else {
            showToast(res.message);
        }
    };

    return (
        <div>
            <Grid container spacing={2} className="main-container">
                {state.showLoader ? (
                    <Grid item sm={12} container alignItems="center" justifyContent="center" style={{ width: 'calc(100vh - 230px)', height: 640 }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12} className="mandatory-fields">
                            <div>
                                <InputLabel fullWidth> Branch </InputLabel>
                                <br />
                            </div>
                            <Typography variant="button" style={lable_style}>
                                {`${props.branchCode} - ${state.branchName}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container alignContent="flex-start" style={{ width: 'calc(100vh - 230px)', height: 'calc(100vh - 230px)' }}>
                            <Grid item xs={12}>
                                <Tooltip title="Upload Document">
                                    <label>
                                        <Backup color="secondary" />
                                        <input
                                            name="branchStampImage"
                                            multiple
                                            type="file"
                                            onChange={(e) => upload(e)}
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                        />
                                    </label>
                                </Tooltip>
                                &nbsp;
                                <Tooltip title="Remove All Document" onClick={onImageRemoveAll}>
                                    <label>
                                        <RemoveCircle color="secondary" />
                                    </label>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} spacing={2} style={{ height: '85%' }}>
                                <Grid container spacing={2} style={{ overflow: 'auto', height: '100%' }}>
                                    {inputList.map((item, i) => (
                                        <Grid xs={12} sm={3} md={3} lg={3} item key={i} className="image-item" style={{ marginTop: 15 }}>
                                            <img src={item.branchStampImage} alt="No images" width="100%" height="150px" />
                                            <div className="image-item__btn-wrapper">
                                                <SecondaryButton fullWidth onClick={() => handleRemoveClick(i)}>
                                                    Remove
                                                </SecondaryButton>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} clasName="submit_btn">
                                <SecondaryButton fullWidth onClick={submitHandler}>
                                    {props.vehicleCategoryID ? 'Update' : 'Add'}
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
}
export default BranchModal;

const lable_style = {
    fontSize: '20px',
    fontWeight: 'lighter'
};

import { Checkbox, CircularProgress, FormControlLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import React, { useContext, useState } from 'react';
import { useEffect } from 'react';

import { SecondaryButton, TextBox } from '../../../Core/FormInput';

import formatters from '../../../Core/Grid/inputFormatter';
import '../commonStyle.scss';
import { AddOtherIncomeCategory, getNicolBudgetGetOtherIncomes, setNicolBudgetSetOtherIncome } from '../../../Core/Service/branch-screen-service';

import AppContext from '../../../App/AppContext';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const MonthShortNameList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const useStyles = makeStyles({
    table: {
        minWidth: 1250,

        '& .MuiTableCell-root': {
            padding: '10px'
        }
    },
    tableRowStyle: {
        backgroundColor: '#E0E0E0'
    }
});

const OtherIncomeComp = (props) => {
    const [state, setState] = useState({ isRecords: false, rows: [], isActive: false, category: '', columnTotal: {} });
    const [otherIncomeTypeList, setOtherIncomeTypeList] = useState([]);
    const [showCircularLoader, setShowCircularLoader] = useState(true);
    const [calculatedExpenseList, setCalculatedExpenseList] = useState([]);
    const { showToast } = useContext(AppContext);
    const classes = useStyles();

    const pullOtherIncomesBudgetGridData = async () => {
        setShowCircularLoader(true);
        let sortedData = [];
        let res = await getNicolBudgetGetOtherIncomes(props.yearCode?.id, props.data.departmentID, props.branchID);
        let tempResData = res.data.list || [];
        if (res?.data?.list?.length > 0) {
            tempResData.forEach((p) => {
                let objIndex = sortedData.findIndex((o) => o.otherIncomeCategoryID == p.otherIncomeCategoryID);
                if (objIndex > -1) {
                    sortedData[objIndex].data.push({ ...p });
                } else {
                    sortedData.push({
                        otherIncomeCategoryID: p.otherIncomeCategoryID,
                        otherIncomeCategoryName: p.otherIncomeCategoryName,
                        data: [{ ...p }]
                    });
                }
            });
            sortedData.forEach((r) => {
                let list2 = MonthShortNameList.map((m) => {
                    let obj = r.data.find((p) => moment(p.otherIncomeDate).format('DD-MMM-YYYY').includes(`${m}`));
                    if (obj) {
                        return obj;
                    } else {
                        return {
                            otherIncomeAmount: 0,
                            otherIncomeCategoryID: r.otherIncomeCategoryID,
                            otherIncomeCategoryName: r.otherIncomeCategoryName,
                            otherIncomeDate: moment(`01/${m}/${props.yearCode?.id}`).format('DD-MMM-YYYY'),
                            otherIncomeID: null
                        };
                    }
                });
                r.data = list2;
            });
            setState((st) => {
                return { ...st, isRecords: true };
            });
        } else {
            setState((st) => {
                return { ...st, isRecords: false };
            });
        }
        setOtherIncomeTypeList(sortedData);
        setShowCircularLoader(false);
    };

    useEffect(() => {
        pullOtherIncomesBudgetGridData();
    }, [props.yearCode?.id]);

    useEffect(() => {
        let tempArr = [...otherIncomeTypeList];
        tempArr.forEach((t) => {
            let DataTotal = 0;
            t.data.forEach((q) => {
                DataTotal += +q.otherIncomeAmount;
            });
            t.allOtherIncomeAmount = DataTotal;
        });

        let colCalculation = {
            otherIncomeAmountJanTotal: 0,
            otherIncomeAmountFebTotal: 0,
            otherIncomeAmountMarTotal: 0,
            otherIncomeAmountAprTotal: 0,
            otherIncomeAmountMayTotal: 0,
            otherIncomeAmountJunTotal: 0,
            otherIncomeAmountJulTotal: 0,
            otherIncomeAmountAugTotal: 0,
            otherIncomeAmountSepTotal: 0,
            otherIncomeAmountOctTotal: 0,
            otherIncomeAmountNovTotal: 0,
            otherIncomeAmountDecTotal: 0,
            otherIncomeAmountTotalTotal: 0
        };
        let totals = 0;
        tempArr.forEach((w) => {
            totals += w.allOtherIncomeAmount;
            w.data.forEach((q) => {
                MonthShortNameList.map((m) => {
                    if (moment(q.otherIncomeDate).format('DD-MMM-YYYY').includes(`${m}`)) {
                        colCalculation[`otherIncomeAmount${m}Total`] += q.otherIncomeAmount;
                    }
                    return null;
                });
            });
            colCalculation.otherIncomeAmountTotalTotal = totals;
        });
        tempArr.push({ otherIncomeCategoryName: 'Total', data: colCalculation });

        setCalculatedExpenseList(tempArr);
    }, [otherIncomeTypeList]);

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const handleSubmit = async () => {
        let tempOtherIncomeTypeList = [...otherIncomeTypeList];
        let data = [];
        tempOtherIncomeTypeList.forEach((f) => {
            let tempData = f.data.filter((j) => j.isChanged).map(({ otherIncomeCategoryName, isChanged, ...q }) => ({ ...q }));
            data = [...data, ...tempData];
        });
        let filterPostData = {
            BranchID: props.branchID,
            OtherIncomes: data
        };
        let res = await setNicolBudgetSetOtherIncome(filterPostData);
        if (res.success) {
            showToast('Other Income Save Successfully');
            pullOtherIncomesBudgetGridData();
        }
    };

    const handleCategorySubmit = async () => {
        let res = {};
        let data = {
            branchID: props.branchID,
            departmentID: props.data.departmentID,
            categoryName: state.category,
            categoryIncludeInProfit: state.isActive
        };

        res = await AddOtherIncomeCategory(data);
        if (res.success) {
            showToast('Income Category Added Successfully');
            pullOtherIncomesBudgetGridData();
        }
    };

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = checked;
            return nst;
        });
    };

    const gridInputChangeHandler = (index, typeID) => (e) => {
        let tempList = [...otherIncomeTypeList];
        let objIndex = tempList.findIndex((x) => x.otherIncomeCategoryID === typeID);
        tempList[objIndex].data[index] = {
            ...tempList[objIndex].data[index],
            otherIncomeAmount: +e.target.value,
            isChanged: true
        };
        setOtherIncomeTypeList(tempList);
    };

    const InputWrapper = (index, typeID, value) => {
        return (
            <input type="number" name={typeID} onChange={gridInputChangeHandler(index, typeID)} value={value} className="pa-4" style={{ textAlign: 'right' }} />
        );
    };

    return (
        <Grid container spacing={2} style={{ paddingTop: '10px' }} justifyContent="center">
            <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid xs={12} sm={7} md={8} item>
                        {(props.fromPage == 'expense' || props.fromPage == 'otherIncome') && (
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={6} md={5}>
                                    <TextBox onChange={fieldChange} value={state.category} name="category" autoFocus={true} placeholder="Category" />
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={state.isActive} onChange={handleCheckbox} name="isActive" />}
                                        label="Include in Profit/Loss?"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3} md={2}>
                                    <SecondaryButton onClick={handleCategorySubmit} className="setFullWidth" disabled={!state.category}>
                                        Add
                                    </SecondaryButton>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid xs={12} sm={5} md={4} item>
                        <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                            <Grid item xs={6} sm={5} md={4}>
                                <SecondaryButton className="setFullWidth" onClick={handleSubmit}>
                                    Submit
                                </SecondaryButton>
                            </Grid>
                            <Grid item xs={6} sm={5} md={4}>
                                <SecondaryButton className="setFullWidth">Cancel</SecondaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {showCircularLoader ? (
                <Grid item>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 320px)' }} className="input-Number-remove-spinners">
                        <Table className={classes.table} stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={240}>Category</TableCell>
                                    {MonthShortNameList.map((p) => {
                                        return (
                                            <TableCell align="right" key={p}>
                                                {p}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell width={100} align="right">
                                        Total
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!state.isRecords ? (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            No Records
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>
                                        {calculatedExpenseList.map((t) => {
                                            return (
                                                <>
                                                    {!t.otherIncomeCategoryID ? (
                                                        <TableRow key={t.otherIncomeCategoryName}>
                                                            <TableCell component="th" scope="row">
                                                                {t.otherIncomeCategoryName}
                                                            </TableCell>
                                                            {MonthShortNameList.map((p, i) => {
                                                                return (
                                                                    <TableCell align="right" key={i}>
                                                                        {formatters.ToFixedSeparator(t.data[`otherIncomeAmount${p}Total`])}
                                                                    </TableCell>
                                                                );
                                                            })}

                                                            <TableCell align="right">
                                                                {formatters.ToFixedSeparator(t.data.otherIncomeAmountTotalTotal)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                {t.otherIncomeCategoryName}
                                                            </TableCell>

                                                            {t.data.map((p, i) => {
                                                                return (
                                                                    <TableCell align="right" key={i}>
                                                                        {InputWrapper(i, t.otherIncomeCategoryID, p.otherIncomeAmount)}
                                                                    </TableCell>
                                                                );
                                                            })}

                                                            <TableCell align="right">{formatters.ToFixedSeparator(t.allOtherIncomeAmount)}</TableCell>
                                                        </TableRow>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Grid>
    );
};

export default OtherIncomeComp;

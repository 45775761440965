import { Chip, FormControlLabel, Switch } from '@material-ui/core';
import { FormLabel, Grid, Hidden } from '@material-ui/core';
import { SelectBox } from '../../../Core/FormInput';
import { useState } from 'react';
import { DetailsCards, DetailsCardsWithoutBudget, ProfitLossDetailsCards } from '../../../Core/BudgetCore/budgetCardsUi_v2';
import { PandLLineChart } from '../../../Core/BudgetCore/p&ldetailsChart';
import { postProfitLossData } from '../../../Core/Service/Nicol_Common_services';
import formatters from '../../../Core/Grid/inputFormatter';
import BudgetFilter from '../budgetFilters';
import CircularIndeterminate from '../../../Core/BudgetCore/loader';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';

const Filter = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Used' }
];

const ProfitLossDetails = () => {
    const [WindowWidths] = useWindowSize();
    const [state, setState] = useState({
        graphFilterID: 1,
        includeReserved: false
    });
    const [selectedBranchList, setSelectedBranchList] = useState([]);

    const [data, setData] = useState({
        showLoader: true
    });

    let newProfitLossList = {
        profit: data.newTotalProfit,
        budget: data.newTotalBudget,
        variance: data.newTotalProfit - data.newTotalBudget,
        TotalProfitPreviousYear: data.newTotalProfitPreviousYear || '0'
    };

    let usedProfitLossList = {
        profit: data.usedTotalProfit,
        budget: data.usedTotalBudget,
        variance: data.usedTotalProfit - data.usedTotalBudget,
        TotalProfitPreviousYear: data.usedTotalProfitPreviousYear || '0'
    };
    let afterSaleProfitLossList = {
        profit: data.afterSalesTotalProfit,
        budget: data.totalLabourBudget,
        labour: data.totalLabour,
        variance: data.totalLabour - data.totalLabourBudget
        //PROFIT - BUDGET
    };

    const getSelectedBranchList = (list) => {
        setSelectedBranchList(list || []);
    };

    const getFilterData = async (value) => {
        setData((st) => ({ ...st, showLoader: true }));
        let data = {
            filterDate: value.filterDate || [],
            franchiseCode: value.franchiseCode || [],
            branchID: value.branchID || [],
            includeReserved: state.includeReserved
        };
        let res = await postProfitLossData(data);
        if (res.success) {
            let dashData = res.data.dashboard[0];
            let newProfitLoss = res.data.newProfitLoss;
            let usedProfitLoss = res.data.usedProfitLoss;
            let afterSalesProfitLoss = res.data.afterSalesProfitLoss;
            let graph1 = res.data.graph1;
            let graph2 = res.data.graph2;

            setData((st) => ({
                ...st,

                actualBudget: dashData.actualBudget,
                combinedBudget: dashData.combinedBudget,
                combinedBudgetUnit: dashData.combinedBudgetUnit,
                combinedPPU: dashData.combinedPPU,
                combinedProfit: dashData.combinedProfit,
                priorYear: dashData.priorYear,
                totalBudgetUnit: dashData.totalBudgetUnit,
                totalUnit: dashData.totalUnit,
                newProfitLoss: newProfitLoss,
                usedProfitLoss: usedProfitLoss,
                afterSalesProfitLoss: afterSalesProfitLoss,
                graph1: graph1,
                graph2: graph2,
                newBudgetVariance: dashData.newBudgetVariance,
                newTotalBudget: dashData.newTotalBudget,
                newTotalPPU: dashData.newTotalPPU,
                newTotalProfit: dashData.newTotalProfit,
                usedBudgetVariance: dashData.usedBudgetVariance,
                usedTotalBudget: dashData.usedTotalBudget,
                usedTotalPPU: dashData.usedTotalPPU,
                usedTotalProfit: dashData.usedTotalProfit,
                usedTotalProfitPreviousYear: dashData.usedTotalProfitPreviousYear,
                newTotalProfitPreviousYear: dashData.newTotalProfitPreviousYear,
                afterSalesBudgetVariance: dashData.afterSalesBudgetVariance,
                afterSalesTotalBudget: dashData.afterSalesTotalBudget,
                afterSalesTotalProfit: dashData.afterSalesTotalProfit,
                totalLabourBudget: dashData.totalLabourBudget,
                totalLabour: dashData.totalLabour,
                usedTotalBudgetPPU: dashData.usedTotalBudgetPPU,
                newTotalBudgetPPU: dashData.newTotalBudgetPPU,
                showLoader: false
            }));
        }
    };

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    let graphList = {
        1: data.graph1,
        2: data.graph2
    };

    const handleincludeReserved = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            let newSt = { ...st };
            newSt[name] = checked;
            return newSt;
        });
    };

    return (
        <div className="budgetScreen">
            <Grid container spacing={1} alignContent="center" justifyContent="space-between">
                <Grid item xs style={{ textAlign: 'left' }}>
                    <div className="mainHeading alignLeft screenMainHeader">
                        Profit and Loss Detail
                        <FormControlLabel
                            style={{ marginLeft: '4px', marginTop: WindowWidths < 600 ? '1px' : '8px' }}
                            control={<Switch checked={state.includeReserved} onChange={handleincludeReserved} name="includeReserved" />}
                            label="Include Reserved"
                            labelPlacement="start"
                        />
                    </div>

                    {WindowWidths < 960 && (
                        <>
                            {selectedBranchList?.length > 0 ? (
                                <div>
                                    {selectedBranchList.map((p) => {
                                        return <Chip color="secondary" label={p.name} variant="outlined" style={{ margin: '2px' }} />;
                                    })}
                                </div>
                            ) : (
                                <div>
                                    <Chip color="secondary" label="All Branches" variant="outlined" style={{ margin: '2px' }} />
                                </div>
                            )}
                        </>
                    )}
                </Grid>

                <Grid item style={{ marginTop: 5 }}>
                    <Hidden only={['xl', 'lg', 'md']}>
                        <BudgetFilter getFilterData={getFilterData} getSelectedBranchList={getSelectedBranchList} includeReserved={state.includeReserved} />
                    </Hidden>
                </Grid>
            </Grid>
            <Hidden only={['sm', 'xs']}>
                <BudgetFilter getFilterData={getFilterData} includeReserved={state.includeReserved} />
            </Hidden>

            {data.showLoader ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Grid container justifyContent="space-evenly">
                                <Grid item xs={6} sm={6}>
                                    <DetailsCards
                                        up={data.totalUnit >= data.totalBudgetUnit}
                                        value={data.totalUnit}
                                        label="Total Units"
                                        profitVal={data.totalBudgetUnit}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <DetailsCards
                                        up={data.newTotalPPU >= data.newTotalBudgetPPU}
                                        value={formatters.CurrencyThousandSeparatorWithoutZero(data.newTotalPPU)}
                                        label="New PPU"
                                        profitVal={formatters.CurrencyThousandSeparatorWithoutZero(data.newTotalBudgetPPU)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Grid container justifyContent="space-evenly">
                                <Grid item xs={6} sm={6}>
                                    <DetailsCards
                                        up={data.usedTotalPPU >= data.usedTotalBudgetPPU}
                                        value={formatters.CurrencyThousandSeparatorWithoutZero(data.usedTotalPPU)}
                                        label="Used PPU"
                                        profitVal={formatters.CurrencyThousandSeparatorWithoutZero(data.usedTotalBudgetPPU)}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <DetailsCards
                                        up={data.combinedProfit >= data.combinedBudget}
                                        value={formatters.CurrencyThousandSeparatorWithoutZero(data.combinedProfit)}
                                        label="Combined Profit"
                                        profitVal={formatters.CurrencyThousandSeparatorWithoutZero(data.combinedBudget)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Grid container justifyContent="space-evenly">
                                <Grid item xs={6} sm={6}>
                                    <DetailsCardsWithoutBudget
                                        value={formatters.CurrencyThousandSeparatorWithoutZero(data.actualBudget)}
                                        label="Actual vs Budget"
                                        profitVal={22}
                                    />
                                </Grid>

                                <Grid item xs={6} sm={6}>
                                    <DetailsCards
                                        up={undefined}
                                        value={formatters.CurrencyThousandSeparatorWithoutZero(newProfitLossList.TotalProfitPreviousYear)}
                                        label="Prior Year - NEW"
                                        profitVal={formatters.CurrencyThousandSeparatorWithoutZero(usedProfitLossList.TotalProfitPreviousYear)}
                                        budgetLable={'Prior Year - USED'}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className="containerPadding">
                            <ProfitLossDetailsCards
                                data={newProfitLossList}
                                label={
                                    <span>
                                        New PROFIT & LOSS <small style={{ fontSize: 10 }}> (EXCL. FLEET)</small>
                                    </span>
                                }
                                table={data.newProfitLoss}
                                link={'/new_invoice'}
                                searchQuery={`?includeReserved=${state.includeReserved}`}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className="containerPadding">
                            <ProfitLossDetailsCards
                                data={usedProfitLossList}
                                label="USED PROFIT & LOSS"
                                table={data.usedProfitLoss}
                                link={'/used_invoice'}
                                searchQuery={`?includeReserved=${state.includeReserved}`}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className="containerPadding">
                            <ProfitLossDetailsCards
                                data={afterSaleProfitLossList}
                                label="AFTERSALES PROFIT & LOSS"
                                table={data.afterSalesProfitLoss}
                                link={'/labour'}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ textAlign: 'left', marginTop: WindowWidths >= 600 ? 0 : 10 }} className="containerPadding">
                        <Grid item xs={12} sm={6} md={3}>
                            <FormLabel component="legend" className="type3_Text" style={{ marginBottom: 5 }}>
                                CATEGORY FILTER
                            </FormLabel>
                            <SelectBox List={Filter} onChange={handleinput} name="graphFilterID" value={state.graphFilterID} insertEmpty={false} />
                        </Grid>
                        <Grid item xs={12}>
                            <PandLLineChart
                                height={WindowWidths > 1360 ? 500 : WindowWidths < 600 ? 200 : 400}
                                data={graphList[state.graphFilterID]}
                                WindowWidths={WindowWidths}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default ProfitLossDetails;

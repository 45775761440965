import React, { useState, useEffect } from 'react';
import DataGridComp from '../../../Core/Grid';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import BranchModal from './BranchModal';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb/index';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { getAllBranch } from '../../../Core/Service/branch-screen-service';
import '../commonStyle.scss';
import BranchTargetScreen from './BranchTarget';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { Backup } from '@material-ui/icons';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Branch', active: true }
];

function Braches(props) {
    const [state, setState] = useState({
        branchList: [],
        branchName: null,
        branchCode: '',
        id: null,
        loader: true,
        open: false
    });

    useEffect(async () => {
        pullData();
    }, []);

    const pullData = async () => {
        let res = await getAllBranch();
        setState((state) => ({
            ...state,
            branchList: res.data
        }));
    };

    const btnHandler = (params) => {
        setState((state) => ({ ...state, open: true, id: params.id, branchName: params.branchName, branchCode: params.branchCode }));
    };

    const targetBtnHandler = (params) => {
        setState((state) => ({ ...state, branchID: params.id, openTargetModal: true, branchCode: params.branchCode, branchName: params.branchName }));
    };

    const ActionButtons = (params) => {
        return (
            <div>
                <Tooltip title="Update Stamps" aria-label="add">
                    <IconButton size="small" onClick={() => btnHandler(params.value.row)}>
                        <Backup color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Set Target" aria-label="add">
                    <IconButton size="small" onClick={() => targetBtnHandler(params.value.row)}>
                        <TrackChangesIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const columns = [
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 100,
            renderCell: (params) => <ActionButtons value={params} />
        },
        { field: 'branchName', headerName: 'Branch Name', flex: 2 }
    ];

    const title = state.id ? 'Upload Stamps' : 'Add Stamps';

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false, openTargetModal: false, openCodeModal: false }));
        res && pullData();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <DataGridComp
                columns={columns}
                rows={state.branchList}
                headerHeight={40}
                rowHeight={80}
                isRowHovered={true}
                enableSearch={true}
                loadingData={state.loader}
                onBtnClick={btnHandler}
                offset={260}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} fullWidth maxWidth="md">
                    <BranchModal id={state.id} branchName={state.branchName} branchCode={state.branchCode} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : null}
            {state.openTargetModal ? (
                <DialogComp title={`Set Target for - ${state.branchName}`} onClose={() => closeModal(false)} fullWidth maxWidth="xl" fullScreen>
                    <BranchTargetScreen branchID={state.branchID} branchCode={state.branchCode} branchName={state.branchName} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default Braches;

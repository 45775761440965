import { Checkbox, CircularProgress, FormControlLabel, FormLabel, Grid, TableContainer, TextField } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { Autocomplete } from '@material-ui/lab';
import React, { useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import formatters from '../../../../Core/Grid/inputFormatter';
import {
    AddExpenseCategory,
    AddOtherIncomeCategory,
    getBudgetDropDown,
    getBudgetExpenses,
    getBudgetOtherIncomes,
    getNicolBudgetSalesTargets,
    getSalesTargets,
    postBudgetExpenceData,
    postSetOtherIncomes,
    setNicolBudgetSalesTargets,
    setSalesTargets
} from '../../../../Core/Service/branch-screen-service';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../../commonStyle.scss';

import moment from 'moment';
import AppContext from '../../../../App/AppContext';

const useStyles = makeStyles({
    table: {
        minWidth: 1250,

        '& .MuiTableCell-root': {
            padding: '10px'
        }
    },
    tableRowStyle: {
        backgroundColor: '#E0E0E0'
    }
});

const MonthShortNameList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function isFiniteCheck(str) {
    return isFinite(str) ? str.toFixed(2) : '0.00';
}

const UsedVehicleSalesTargetScreen = (props) => {
    const [targetTypeList, setTargetTypeList] = useState([]);

    const [showCircularLoader, setShowCircularLoader] = useState(true);
    const [calculatedTargetTypeList, setCalculatedTargetTypeList] = useState([]);
    const { showToast } = useContext(AppContext);
    const classes = useStyles();
    const pullSalesTargetGridData = async () => {
        setShowCircularLoader(true);
        let tempTargetTypeList = [
            {
                rowName: 'Retail Units',
                staticID: 1,
                data: []
            },

            {
                rowName: 'Vehicle sales - retail',
                staticID: 3,
                data: []
            },
            {
                rowName: 'Vehicle cost of sales - retail',
                staticID: 4,
                data: []
            },
            {
                rowName: '',
                data: []
            },
            {
                rowName: 'Reconditioning costs',
                staticID: 8,
                data: []
            },
            {
                rowName: 'Vehicle Gross Profit - retail',
                data: []
            },
            {
                rowName: 'Vehicle Gross Profit %',
                data: [],
                formatter: 'ThousandSeparator'
            },
            {
                rowName: 'Vehicle Profit Per Unit',
                data: []
            },
            {
                rowName: 'Reconditioning costs per unit',
                data: []
            },
            {
                rowName: 'Units sold - trade',
                staticID: 2,
                data: []
            },
            {
                rowName: 'Vehcle sales - trade',
                staticID: 9,
                data: []
            },
            {
                rowName: 'Vehicle cost of sales - trade',
                staticID: 10,
                data: []
            },
            {
                rowName: 'Vehicle Gross Profit - trade',
                data: []
            },
            {
                rowName: 'Reconditioning costs - trade',
                staticID: 11,
                data: []
            },
            {
                rowName: 'Vehicle Gross Profit - trade',
                data: []
            },
            {
                rowName: 'Vehicle Gross Profit %',
                data: [],
                formatter: 'ThousandSeparator'
            },
            {
                rowName: 'Vehicle Profit per unit',
                data: []
            },
            {
                rowName: 'Reconditioning costs per unit',
                data: []
            },
            {
                rowName: 'Accessory Sales',
                staticID: 5,
                data: []
            },
            {
                rowName: 'Accessory Cost Of Sales',
                staticID: 6,
                data: []
            },
            {
                rowName: 'Accessory Gross Profit',
                data: []
            },
            {
                rowName: 'Accessory Gross Profit %',
                data: [],
                formatter: 'ThousandSeparator'
            },
            {
                rowName: 'Accessory Profit Per Unit',
                data: []
            },
            {
                rowName: 'Warranty costs',
                staticID: 7,
                data: []
            },
            {
                rowName: 'Total vehicle sales',
                data: []
            },
            {
                rowName: 'Total vehicle cost of sales',
                data: []
            },
            {
                rowName: 'Total vehicle Gross Profit',
                data: []
            },
            {
                rowName: 'Total vehicle Gross Profit %',
                data: [],
                formatter: 'ThousandSeparator'
            },
            {
                rowName: 'Total Gross Profit per unit',
                data: []
            }
        ];

        let res = await getNicolBudgetSalesTargets(props.yearCode?.id, props.data.departmentID, props.branchID);
        let tempResData = res.data.list || [];

        tempResData.forEach((q) => {
            let objIndex = tempTargetTypeList.findIndex((p) => p.staticID === q.salesTargetTypeID);
            if (objIndex > -1) {
                tempTargetTypeList[objIndex].data.push(q);
            }
        });

        tempTargetTypeList.forEach((r) => {
            let list2 = MonthShortNameList.map((m) => {
                let obj = r.data.find((p) => moment(p.salesTargetDate).format('DD-MMM-YYYY').includes(`${m}`));
                if (obj) {
                    return obj;
                } else {
                    return {
                        salesTargetAmount: 0,
                        salesTargetBranchID: props.branchID,
                        salesTargetDate: moment(`01/${m}/${props.yearCode?.id}`).format('DD-MMM-YYYY'),
                        salesTargetDepartmentID: props.data.departmentID,
                        salesTargetID: null,
                        salesTargetTypeID: r.staticID
                    };
                }
            });
            r.data = list2;
        });

        setTargetTypeList(tempTargetTypeList);
        setShowCircularLoader(false);
    };

    useEffect(() => {
        pullSalesTargetGridData();
    }, [props.yearCode?.id]);

    useEffect(() => {
        if (targetTypeList.length > 0) {
            let tempArr = [...targetTypeList];

            const valueReAssignedWrapper = (listIndex, obj) => {
                tempArr[listIndex].data = MonthShortNameList.map((q, i) => {
                    return {
                        salesTargetAmount: obj[i],
                        salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                    };
                });
            };

            let RetailUnits = tempArr[0];
            let VehicleSalesRetail = tempArr[1];
            let VehicleCostOfSalesRetail = tempArr[2];
            let abcd = tempArr[3];
            let ReconditioningCostsRetail = tempArr[4];
            let VehicleGrossProfitRetail = tempArr[5];
            let VehicleGrossProfitRetailPercentage = tempArr[6];
            let VehicleProfitPerUnitRetail = tempArr[7];
            let ReconditioningCostsPerUnitRetail = tempArr[8];
            let UnitsSoldTrade = tempArr[9];
            let VehcleSalesTrade = tempArr[10];
            let VehicleCostOfSalesTrade = tempArr[11];
            let VehicleGrossProfitTradeAbcd = tempArr[12];
            let ReconditioningCostsTrade = tempArr[13];
            let VehicleGrossProfitTrade = tempArr[14];
            let VehicleGrossProfitPercentage = tempArr[15];
            let VehicleProfitPerUnit = tempArr[16];
            let ReconditioningCostsPerUnit = tempArr[17];
            let AccessorySales = tempArr[18];
            let AccessoryCostOfSales = tempArr[19];
            let AccessoryGrossProfit = tempArr[20];
            let AccessoryGrossProfitPercentage = tempArr[21];
            let AccessoryProfitPerUnit = tempArr[22];
            let WarrentyCosts = tempArr[23];
            let TotalVehicleSales = tempArr[24];
            let TotalVehicleCostOfSales = tempArr[25];
            let TotalVehicleGrossProfit = tempArr[26];
            let TotalVehicleGrossProfitPercentage = tempArr[27];
            let TotalGrossProfitPerUnit = tempArr[28];

            ////// Vehicle sales and cost of sales Calculations//////
            let tempVehicleSalesNCostOfSalesCalObj = {};
            [VehicleCostOfSalesRetail, VehicleSalesRetail].forEach((q) => {
                q.data.forEach((r, i) => {
                    tempVehicleSalesNCostOfSalesCalObj[i] = r.salesTargetAmount - (+tempVehicleSalesNCostOfSalesCalObj[i] || 0);
                });
            });
            valueReAssignedWrapper(3, tempVehicleSalesNCostOfSalesCalObj);

            //// Vehicle Gross Profit - retail Calculations//////
            let tempVehicleGrossProfitRetailCalObj = {};
            [ReconditioningCostsRetail, abcd].forEach((q) => {
                q.data.forEach((r, i) => {
                    tempVehicleGrossProfitRetailCalObj[i] = r.salesTargetAmount - (+tempVehicleGrossProfitRetailCalObj[i] || 0);
                });
            });
            valueReAssignedWrapper(5, tempVehicleGrossProfitRetailCalObj);

            /////  Vehicle Gross Profit % retail Calculations //////
            let tempVehicleGrossProfitRetailPercentageCalObj = {};
            MonthShortNameList.forEach((r, i) => {
                tempVehicleGrossProfitRetailPercentageCalObj[i] =
                    (VehicleGrossProfitRetail.data[i].salesTargetAmount / VehicleSalesRetail.data[i].salesTargetAmount) * 100;
            });
            valueReAssignedWrapper(6, tempVehicleGrossProfitRetailPercentageCalObj);

            /////  Vehicle Profit Per Unit retail Calculations //////
            let tempVehicleProfitPerUnitRetailCalObj = {};
            MonthShortNameList.forEach((r, i) => {
                tempVehicleProfitPerUnitRetailCalObj[i] = abcd.data[i].salesTargetAmount / RetailUnits.data[i].salesTargetAmount;
            });
            valueReAssignedWrapper(7, tempVehicleProfitPerUnitRetailCalObj);

            /////  Reconditioning Costs Per Unit Retail Calculations //////
            let tempReconditioningCostsPerUnitRetailCalObj = {};
            MonthShortNameList.forEach((r, i) => {
                tempReconditioningCostsPerUnitRetailCalObj[i] = ReconditioningCostsRetail.data[i].salesTargetAmount / RetailUnits.data[i].salesTargetAmount;
            });
            valueReAssignedWrapper(8, tempReconditioningCostsPerUnitRetailCalObj);

            //// Vehicle Gross Profit - Trade Calculations//////
            let tempVehicleGrossProfitTradeCalObj = {};
            [VehicleCostOfSalesTrade, VehcleSalesTrade].forEach((q) => {
                q.data.forEach((r, i) => {
                    tempVehicleGrossProfitTradeCalObj[i] = r.salesTargetAmount - (+tempVehicleGrossProfitTradeCalObj[i] || 0);
                });
            });
            valueReAssignedWrapper(12, tempVehicleGrossProfitTradeCalObj);

            //// Vehicle Gross Profit - Trade with Reconditioning costs - trade Calculations//////
            let tempVehicleGrossProfitTradeWithReconditioningCostsCalObj = {};
            [ReconditioningCostsTrade, VehicleGrossProfitTradeAbcd].forEach((q) => {
                q.data.forEach((r, i) => {
                    tempVehicleGrossProfitTradeWithReconditioningCostsCalObj[i] =
                        r.salesTargetAmount - (+tempVehicleGrossProfitTradeWithReconditioningCostsCalObj[i] || 0);
                });
            });
            valueReAssignedWrapper(14, tempVehicleGrossProfitTradeWithReconditioningCostsCalObj);

            /////  Vehicle Gross Profit % Trade Calculations //////
            let tempVehicleGrossProfitTradePercentageCalObj = {};
            MonthShortNameList.forEach((r, i) => {
                tempVehicleGrossProfitTradePercentageCalObj[i] =
                    (VehicleGrossProfitTrade.data[i].salesTargetAmount / VehcleSalesTrade.data[i].salesTargetAmount) * 100;
            });
            valueReAssignedWrapper(15, tempVehicleGrossProfitTradePercentageCalObj);

            /////  Vehicle Profit Per Unit Trade Calculations //////
            let tempVehicleProfitPerUnitTradeCalObj = {};
            MonthShortNameList.forEach((r, i) => {
                tempVehicleProfitPerUnitTradeCalObj[i] = VehicleGrossProfitTradeAbcd.data[i].salesTargetAmount / UnitsSoldTrade.data[i].salesTargetAmount;
            });
            valueReAssignedWrapper(16, tempVehicleProfitPerUnitTradeCalObj);

            /////  Reconditioning Costs Per Unit Retail Calculations //////
            let tempReconditioningCostsPerUnitTradeCalObj = {};
            MonthShortNameList.forEach((r, i) => {
                tempReconditioningCostsPerUnitTradeCalObj[i] = ReconditioningCostsTrade.data[i].salesTargetAmount / UnitsSoldTrade.data[i].salesTargetAmount;
            });
            valueReAssignedWrapper(17, tempReconditioningCostsPerUnitTradeCalObj);

            //// Accessory  Gross Profit Calculations//////
            let tempAccessoryGrossProfitCalObj = {};
            [AccessoryCostOfSales, AccessorySales].forEach((q) => {
                q.data.forEach((r, i) => {
                    tempAccessoryGrossProfitCalObj[i] = r.salesTargetAmount - (+tempAccessoryGrossProfitCalObj[i] || 0);
                });
            });
            valueReAssignedWrapper(20, tempAccessoryGrossProfitCalObj);

            /////  Accessory Gross Profit % Calculations //////
            let tempAccessoryGrossProfitPercentageCalObj = {};
            MonthShortNameList.forEach((r, i) => {
                tempAccessoryGrossProfitPercentageCalObj[i] = (AccessoryGrossProfit.data[i].salesTargetAmount / AccessorySales.data[i].salesTargetAmount) * 100;
            });
            valueReAssignedWrapper(21, tempAccessoryGrossProfitPercentageCalObj);

            ////// Total vehicle sales Calculations//////
            let tempTotalVehicleSalesCalObj = {};
            [VehicleSalesRetail, VehcleSalesTrade].forEach((q) => {
                q.data.forEach((r, i) => {
                    tempTotalVehicleSalesCalObj[i] = r.salesTargetAmount + (+tempTotalVehicleSalesCalObj[i] || 0);
                });
            });
            valueReAssignedWrapper(24, tempTotalVehicleSalesCalObj);

            ////// Total vehicle cost of sales Calculations//////
            let tempTotalVehicleCostOfSales = {};
            [VehicleCostOfSalesRetail, ReconditioningCostsRetail, VehicleCostOfSalesTrade, ReconditioningCostsTrade].forEach((q) => {
                q.data.forEach((r, i) => {
                    tempTotalVehicleCostOfSales[i] = r.salesTargetAmount + (+tempTotalVehicleCostOfSales[i] || 0);
                });
            });
            valueReAssignedWrapper(25, tempTotalVehicleCostOfSales);

            ////// Total vehicle Gross Profit Calculations//////
            let tempTotalVehicleGrossProfit = {};
            [VehicleGrossProfitRetail, VehicleGrossProfitTrade].forEach((q) => {
                q.data.forEach((r, i) => {
                    tempTotalVehicleGrossProfit[i] = r.salesTargetAmount + (+tempTotalVehicleGrossProfit[i] || 0);
                });
            });
            valueReAssignedWrapper(26, tempTotalVehicleGrossProfit);

            /////  Total vehicle Gross Profit % Calculations //////

            let tempTotalVehicleGrossProfitPercentageCalObj = {};
            MonthShortNameList.forEach((r, i) => {
                tempTotalVehicleGrossProfitPercentageCalObj[i] =
                    (TotalVehicleGrossProfit.data[i].salesTargetAmount / TotalVehicleSales.data[i].salesTargetAmount) * 100;
            });
            valueReAssignedWrapper(27, tempTotalVehicleGrossProfitPercentageCalObj);

            /////  Total Gross Profit per unit Calculations //////

            let tempTotalGrossProfitPerUnitCalObj = {};
            MonthShortNameList.forEach((r, i) => {
                tempTotalGrossProfitPerUnitCalObj[i] =
                    TotalVehicleGrossProfit.data[i].salesTargetAmount / (RetailUnits.data[i].salesTargetAmount + UnitsSoldTrade.data[i].salesTargetAmount);
            });
            valueReAssignedWrapper(28, tempTotalGrossProfitPerUnitCalObj);

            tempArr.forEach((t) => {
                let DataTotal = 0;
                t.data.forEach((q) => {
                    DataTotal += +q.salesTargetAmount;
                });
                t.allSalesTargetAmount = isFiniteCheck(DataTotal);
            });

            setCalculatedTargetTypeList(tempArr);
        }
    }, [targetTypeList]);

    const gridInputChangeHandler = (index, typeID) => (e) => {
        let tempList = [...targetTypeList];
        let objIndex = tempList.findIndex((x) => x.staticID === typeID);
        tempList[objIndex].data[index] = {
            ...tempList[objIndex].data[index],
            salesTargetAmount: +e.target.value,
            salesTargetTypeID: typeID,
            isChanged: true
        };
        setTargetTypeList(tempList);
    };

    const InputWrapper = (index, typeID, value) => {
        return (
            <input type="number" name={typeID} onChange={gridInputChangeHandler(index, typeID)} value={value} className="pa-4" style={{ textAlign: 'right' }} />
        );
    };

    const handleSubmit = async () => {
        let tempTargetTypeList = [...targetTypeList];
        let data = [];
        tempTargetTypeList.forEach((f) => {
            let tempData = f.data.filter((j) => j.isChanged).map(({ salesTargetDepartmentID, salesTargetBranchID, isChanged, ...q }) => ({ ...q }));
            data = [...data, ...tempData];
        });
        let filterPostData = {
            BranchID: props.branchID,
            SalesTargetDepartmentID: props.data.departmentID,
            SalesTargets: data
        };

        let res = await setNicolBudgetSalesTargets(filterPostData);
        if (res.success) {
            showToast('Sales Target Save Successfully');
            pullSalesTargetGridData();
        }
    };

    return (
        <Grid container item style={{ paddingTop: '10px' }} justifyContent="center">
            <Grid container item spacing={2} justifyContent="space-between">
                <Grid xs={8} item></Grid>
                <Grid xs={4} container item spacing={2} alignItems="center" justifyContent="flex-end">
                    <Grid item xs={4}>
                        <SecondaryButton className="setFullWidth" onClick={handleSubmit}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={4}>
                        <SecondaryButton className="setFullWidth">Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
            {showCircularLoader ? (
                <Grid item>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 290px)' }} className="input-Number-remove-spinners">
                        <Table className={classes.table} stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={170}></TableCell>
                                    {MonthShortNameList.map((p) => {
                                        return <TableCell align="right">{p}</TableCell>;
                                    })}
                                    <TableCell width={100} align="right">
                                        Total
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {calculatedTargetTypeList.map((t, i) => {
                                    return (
                                        <TableRow className={t.staticID ? {} : classes.tableRowStyle} key={i}>
                                            <TableCell component="th" scope="row">
                                                {t.rowName}
                                            </TableCell>

                                            {t.data.map((p, i) => {
                                                return t.staticID ? (
                                                    <TableCell align="right">{InputWrapper(i, t.staticID, p.salesTargetAmount)}</TableCell>
                                                ) : (
                                                    <TableCell align="right">
                                                        {formatters[t.formatter ? t.formatter : 'ThousandSeparatorWithRoundnWithoutZero'](+p.salesTargetAmount)}
                                                    </TableCell>
                                                );
                                            })}

                                            <TableCell align="right">
                                                {formatters[t.formatter ? t.formatter : 'ThousandSeparatorWithRoundnWithoutZero'](+t.allSalesTargetAmount)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Grid>
    );
};

export default UsedVehicleSalesTargetScreen;

import TableContainer from '@material-ui/core/TableContainer';
import { Grid, FormLabel, Hidden, CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import '../../../Core/BudgetCore/budget.scss';
import BudgetFilter from '../budgetFilters';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { FilterBtn, LinkedButton } from '../../../Core/BudgetCore/budgetButton';
import { WhitePLIcon } from '../NicolImages';
import { postSalesProfitLossTable, postSalesProfitLossTableGetCSVFile, postSalesProfitLossTableGetPDFFile } from '../../../Core/Service/Nicol_Common_services';
import { useHistory } from 'react-router-dom';
import { CombineProfitTable } from './profitlossCombineTable';
import { ProfitNewTable } from './profitlossNewTable';
import { ProfitUsedTable } from './profitlossUsedTable';

const filterBtnList = [
    { label: 'COMBINED', id: 'COMBINED' },
    { label: 'NEW', id: 'New' },
    { label: 'USED', id: 'Used' }
];

export const ProfitTable = (props) => {
    const [WindowWidths] = useWindowSize();
    const [state, setState] = useState({
        btn: 1,
        tableRow: [],
        selectedFilterBtn: props.location.state || filterBtnList[0].id,
        isLoader: true,
        type: '',
        downloadBtnLoader: false,
        downloadBtnPDFLoader: false
    });

    let history = useHistory();

    const handleTable = (value) => {
        history.push({ pathname: '/profitloss', state: state.selectedFilterBtn });
    };

    const getFilterData = async (value) => {
        setState((st) => ({ ...st, isLoader: true }));
        let data = {
            filterDate: value.filterDate || [],
            franchiseCode: value.franchiseCode || [],
            branchID: value.branchID || [],
            Type: value.type === 'COMBINED' ? '' : value.type
        };

        let res = await postSalesProfitLossTable(data);
        if (res.success) {
            let data = res.data?.list;
            setState((st) => ({
                ...st,
                tableRow: data,
                isLoader: false,
                filterDate: value.filterDate || [],
                franchiseCode: value.franchiseCode || [],
                branchID: value.branchID || [],
                type: value.type === 'COMBINED' ? '' : value.type
            }));
        }
    };

    const filterBtnChange = (id) => {
        setState((st) => ({
            ...st,
            selectedFilterBtn: id,
            tableRow: []
        }));
    };

    const downloadTableCSVData = async () => {
        setState((st) => ({
            ...st,
            downloadBtnLoader: true
        }));
        let data = {
            filterDate: state.filterDate || [],
            franchiseCode: state.franchiseCode || [],
            branchID: state.branchID || [],
            Type: state.type === 'COMBINED' ? '' : state.type
        };
        await postSalesProfitLossTableGetCSVFile(data, 'Sales_Profit_and_Loss_List');
        setState((st) => ({
            ...st,
            downloadBtnLoader: false
        }));
    };
    const downloadTablePDFData = async () => {
        setState((st) => ({
            ...st,
            downloadBtnPDFLoader: true
        }));
        let data = {
            filterDate: state.filterDate || [],
            franchiseCode: state.franchiseCode || [],
            branchID: state.branchID || [],
            Type: state.type === 'COMBINED' ? '' : state.type
        };
        await postSalesProfitLossTableGetPDFFile(data, 'Sales_Profit_and_Loss_List');
        setState((st) => ({
            ...st,
            downloadBtnPDFLoader: false
        }));
    };

    return (
        <div className="budgetScreen">
            <>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={6} sm={9} md={9} lg={6} xl={8}>
                        <div className="mainHeading alignLeft screenMainHeader">Sales Profit and Loss Table</div>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={6} xl={4}>
                        <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                            <Hidden only={['xl', 'lg', 'md']}>
                                <Grid item>
                                    <BudgetFilter getFilterData={getFilterData} type={state.selectedFilterBtn} />
                                </Grid>
                            </Hidden>
                            {state.type === '' ? (
                                <>
                                    <Grid item>
                                        <LinkedButton onClick={state.downloadBtnPDFLoader ? null : downloadTablePDFData} style={{ width: '60px' }}>
                                            {state.downloadBtnPDFLoader ? <CircularProgress size={18} /> : 'PDF'}
                                        </LinkedButton>
                                    </Grid>
                                    <Grid item>
                                        <LinkedButton onClick={state.downloadBtnLoader ? null : downloadTableCSVData} style={{ width: '60px' }}>
                                            {state.downloadBtnLoader ? <CircularProgress size={18} /> : 'CSV'}
                                        </LinkedButton>
                                    </Grid>
                                </>
                            ) : null}
                            <Grid item>
                                <LinkedButton onClick={handleTable}>
                                    <WhitePLIcon style={{ marginBottom: 3 }} />
                                    {WindowWidths > 1024 ? <> &nbsp;&nbsp;SALES P&L OVERVIEW</> : ''}
                                </LinkedButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Hidden only={['sm', 'xs']}>
                    <BudgetFilter getFilterData={getFilterData} type={state.selectedFilterBtn} />
                </Hidden>

                <Grid item xs={12} md={8} lg={4} xl={4} className="containerPadding">
                    <Grid container>
                        <Grid item xs={12}>
                            <FormLabel component="legend" className="budgetLebels" style={{ marginLeft: '2px' }}>
                                SELECT FILTER
                            </FormLabel>
                        </Grid>
                        {filterBtnList?.map((p) => {
                            return (
                                <Grid item xs={4} className="containerPadding" key={p.id}>
                                    <FilterBtn active={state.selectedFilterBtn == p.id} onClick={() => filterBtnChange(p.id)}>
                                        {p.label}
                                    </FilterBtn>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                <div className="kpiCard">
                    <TableContainer>
                        {state.isLoader ? (
                            <Grid item sm={12} container alignItems="center" justifyContent="center" style={{ width: '100%', height: 200 }}>
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <>
                                {state.selectedFilterBtn === 'COMBINED' && <CombineProfitTable rows={state?.tableRow || []} />}
                                {state.selectedFilterBtn === 'New' && <ProfitNewTable rows={state?.tableRow || []} />}
                                {state.selectedFilterBtn === 'Used' && <ProfitUsedTable rows={state?.tableRow || []} />}
                            </>
                        )}
                    </TableContainer>
                </div>
            </>
        </div>
    );
};

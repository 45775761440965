import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { SmallLinkedButton } from '../../../Core/BudgetCore/budgetButton';
import { AgedStockCard, AgedStockCardMobileView, AgedStocksDayRangeCard } from '../../../Core/BudgetCore/budgetCardsUi_v2';
import CallMadeIcon from '@material-ui/icons/CallMade';
import EventIcon from '@material-ui/icons/Event';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { getAgedStockValuation } from '../../../Core/Service/Nicol_Common_services';
import formatters from '../../../Core/Grid/inputFormatter';
import { useHistory } from 'react-router-dom';
import CircularIndeterminate from '../../../Core/BudgetCore/loader';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { AppStorage } from '../../../Core/Service/storage-service';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div
                style={{
                    backgroundColor: 'white',
                    border: '1px solid black',
                    padding: '10px',
                    textAlign: 'center',
                    display: 'inline-flex'
                }}
            >
                <div>{label}&nbsp;</div>
                <div style={{ color: '#1ECBE1' }}>
                    {+payload[0].value} <span style={{ color: 'black' }}>Avg. DIS</span>
                </div>
            </div>
        );
    }

    return null;
};

const agedStockCellColor = {
    1: '#569101',
    2: '#FFBF00',
    3: 'red'
};

const AgedStocks = () => {
    const [WindowWidths] = useWindowSize();

    const history = useHistory();
    const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));
    const [state, setState] = useState({
        avgVehicle: [],
        mainData: [],
        vehilceSplitByDays: [],
        showLoader: true
    });
    const isUsedVehicleStockAllowed = AppStorage.getReportingCanAccessUsedVehicleStock();
    const isOveragePriorityListAllowed = AppStorage.getReportingCanAccessOveragePriorityList();

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await getAgedStockValuation();
                if (res.success) {
                    let groupStock = res.data.gpStock[0];
                    setState((st) => ({
                        ...st,
                        avgSIV: groupStock.avgSIV,
                        totalCAPValue: groupStock.totalCAPValue,
                        totalStockValue: groupStock.totalStockValue,
                        totalUnitStock: groupStock.totalUnitStock,
                        avgDis: groupStock.avgDis,
                        stockTurn: groupStock.stockTurn,
                        avgVehicle: res.data.avgVehicle,
                        totalVariance: groupStock.totalCAPValue - groupStock.totalStockValue,
                        mainData: res.data.mainData,
                        vehilceSplitByDays: res.data.vehilceSplitByDays,
                        showLoader: false
                    }));
                } else {
                    // Handle the case where the request was not successful
                    console.error('Request was not successful:', res.error);
                }
            } catch (error) {
                // Handle any other errors that might occur during the asynchronous operation
                console.error('An error occurred:', error);
            }
        };

        fetchData();
    }, []);

    const handleRedirect = (link) => {
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: [] }));
        history.push({
            pathname: link
        });
    };

    return (
        <div className="budgetScreen">
            {state.showLoader ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <Grid container spacing={1} alignContent="center" justifyContent="space-between">
                        <Grid item>
                            <div className="mainHeading alignLeft screenMainHeader">Aged Vehicle Stock</div>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="space-evenly">
                        <Grid item xs={12} sm={12} lg={5} xl={5} className="containerPadding">
                            <Grid container alignItems="flex-start" justifyContent="space-between" className="USVGropupStockViewKip_card">
                                <Grid item xs={8} sm={9}>
                                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                                        <Grid item xs={12}>
                                            <div className="AVSsecondaryHeading">GROUP STOCK</div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                <EventIcon className="AvatarFont" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ width: '100%' }}>
                                                                <div className="AVStype1_Text alignLeft">
                                                                    {formatters.ThousandSeparatorWithoutZero(state.totalUnitStock || 0)}
                                                                </div>
                                                                <div className="AVStype3_Text alignLeft">Total Units in Stock</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                <EventIcon className="AvatarFont" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ width: '100%', marginBottom: WindowWidths >= 600 ? 10 : 0 }}>
                                                                <div className="AVStype1_Text alignLeft">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state.totalStockValue)}
                                                                </div>
                                                                <div className="AVStype3_Text alignLeft">Total Stock Value</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                <EventIcon className="AvatarFont" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ width: '100%', marginBottom: WindowWidths >= 600 ? 10 : 0 }}>
                                                                <div className="AVStype1_Text alignLeft">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state.totalCAPValue)}
                                                                </div>
                                                                <div className="AVStype3_Text alignLeft">Total CAP Clean</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                <EventIcon className="AvatarFont" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div className="AVStype1_Text alignLeft">
                                                                <span style={{ color: state.totalVariance < 0 ? 'red' : '#569101' }}>
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(Math.abs(state.totalVariance))}
                                                                </span>
                                                                <span style={{ verticalAlign: 'middle' }}>
                                                                    {state.totalVariance < 0 ? (
                                                                        <ArrowDownwardIcon className="AVStype1_Text" style={{ color: 'red' }} />
                                                                    ) : (
                                                                        <ArrowUpwardIcon className="AVStype1_Text" style={{ color: '#569101' }} />
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="AVStype3_Text alignLeft">Total Variance</div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                                        <Grid item xs={12}>
                                            <Grid container spacing={1} justifyContent="flex-end">
                                                {isUsedVehicleStockAllowed && (
                                                    <Grid item xs={8}>
                                                        <SmallLinkedButton onClick={() => handleRedirect('/usedStock')}>
                                                            STOCK LIST &nbsp; {WindowWidths >= 600 && <CallMadeIcon style={{ fontSize: 18 }} />}
                                                        </SmallLinkedButton>
                                                    </Grid>
                                                )}
                                                {isOveragePriorityListAllowed && (
                                                    <Grid item xs={8}>
                                                        <SmallLinkedButton onClick={() => handleRedirect('/overagePriority')}>
                                                            OVERAGE &nbsp;{WindowWidths >= 600 && <CallMadeIcon style={{ fontSize: 18 }} />}
                                                        </SmallLinkedButton>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>

                                        {WindowWidths >= 600 ? (
                                            <Grid item xs={12} container>
                                                <Grid container spacing={1} style={{ marginTop: 15 }}>
                                                    <Grid item>
                                                        <div style={{ textAlign: 'center', textTransform: 'uppercase', fontWeight: 500, fontSize: 12 }}>
                                                            AVG DIS
                                                        </div>
                                                        <div className="AVStype1_Text" style={{ textAlign: 'center' }}>
                                                            {state.avgDis}
                                                        </div>
                                                    </Grid>
                                                    <Grid item>
                                                        <div style={{ borderRight: '1px solid', height: '100%' }}></div>
                                                    </Grid>
                                                    <Grid item style={{ textAlign: 'center' }}>
                                                        <div
                                                            className="AVStype3_Text"
                                                            style={{ textAlign: 'center', textTransform: 'uppercase', fontWeight: 500, fontSize: 12 }}
                                                        >
                                                            SOCK TURN{' '}
                                                        </div>
                                                        <div
                                                            className="AVStype1_Text"
                                                            style={{
                                                                textAlign: 'center',
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            {state.stockTurn}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12} container>
                                                <Grid container justifyContent="flex-end" spacing={3} style={{ marginTop: 15 }}>
                                                    <Grid item xs={8} style={{ borderBottom: '1px solid' }}>
                                                        <div className="AVStype3_Text" style={{ textAlign: 'center' }}>
                                                            AVG DIS
                                                        </div>
                                                        <div className="AVStype1_Text" style={{ textAlign: 'center' }}>
                                                            {state.avgDis}
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={8} style={{ textAlign: 'center' }}>
                                                        <div className="AVStype3_Text" style={{ textAlign: 'center' }}>
                                                            SOCK TURN{' '}
                                                        </div>
                                                        <div
                                                            className="AVStype1_Text"
                                                            style={{
                                                                textAlign: 'center',
                                                                fontWeight: 'bold'
                                                                //
                                                            }}
                                                        >
                                                            {state.stockTurn}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={7} xl={7} className="containerPadding">
                            <div>
                                <div style={{ display: 'flex', height: '100%' }}>
                                    <div style={{ width: '100%' }}>
                                        <div className="f-RegularSize alignLeft mb10">AVERAGE VEHICLE AGE PER SITE</div>{' '}
                                        <ResponsiveContainer className="AVSGraph">
                                            <BarChart
                                                data={state.avgVehicle}
                                                margin={{ top: 20, right: WindowWidths >= 600 ? 30 : 10, left: WindowWidths >= 600 ? -10 : -30, bottom: 5 }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />

                                                <Tooltip content={<CustomTooltip />} />
                                                <Bar dataKey="count" fill={'black'} barSize={30} height={40}>
                                                    <LabelList dataKey="count" position="top" style={{ fontSize: 10, fontWeight: 'bold' }} />
                                                    {state.avgVehicle.map((entry, index) => {
                                                        let colorCode;
                                                        if (entry.count <= 50) {
                                                            colorCode = 1;
                                                        } else if (entry.count >= 50 && entry.count < 150) {
                                                            colorCode = 2;
                                                        } else if (entry.count >= 150) {
                                                            colorCode = 3;
                                                        }
                                                        return <Cell key={`cell-${index}`} fill={agedStockCellColor[colorCode]} />;
                                                    })}
                                                </Bar>

                                                <XAxis
                                                    dataKey="branchName"
                                                    textAnchor="end"
                                                    sclaeToFit="true"
                                                    style={{
                                                        fontSize: 10,
                                                        fontWeight: 700
                                                    }}
                                                />
                                                <YAxis orientation="left" style={{ fontSize: 8 }} />
                                                <Tooltip />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    {state.mainData.map((m, i) => {
                        return (
                            <React.Fragment key={`${i}_AVS`}>
                                <Grid container xl={12} justifyContent="space-evenly" key={i}>
                                    {WindowWidths >= 600 && (
                                        <Grid item xl={1} lg={1} md={4} sm={4} xs={12} className="containerPadding">
                                            <div
                                                className="AVSUnitKipOne_card"
                                                style={{
                                                    background: '#569101',
                                                    color: 'white',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <div>
                                                    <div className="AVSUnitHeading">{m.units}</div>
                                                    <div className="AVSUnitHeading">Units</div>
                                                </div>
                                            </div>
                                        </Grid>
                                    )}
                                    {WindowWidths >= 600 ? (
                                        <Grid item xl={4} lg={4} md={8} sm={8} xs={12} className="containerPadding">
                                            <AgedStockCard
                                                data={m}
                                                color="#569101"
                                                stockLink={'/usedStock'}
                                                priorityLink={'/overagePriority'}
                                                branchID={m.branchID}
                                                history={history}
                                                showStock={isUsedVehicleStockAllowed}
                                                showOverage={isOveragePriorityListAllowed}
                                                units={m.units}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xl={4} lg={4} md={8} sm={8} xs={12} className="containerPadding">
                                            <AgedStockCardMobileView
                                                data={m}
                                                color="#569101"
                                                stockLink={'/usedStock'}
                                                priorityLink={'/overagePriority'}
                                                branchID={m.branchID}
                                                history={history}
                                                showStock={isUsedVehicleStockAllowed}
                                                showOverage={isOveragePriorityListAllowed}
                                                units={m.units}
                                                list={state.vehilceSplitByDays}
                                            />
                                        </Grid>
                                    )}

                                    {WindowWidths >= 600 && (
                                        <Grid item xl={7} lg={7} md={12} sm={12} xs={12} className="containerPadding">
                                            <AgedStocksDayRangeCard branchID={m.branchID} list={state.vehilceSplitByDays} />
                                        </Grid>
                                    )}
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default AgedStocks;

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Hidden } from '@material-ui/core';
import { useState } from 'react';
import formatters from '../../../Core/Grid/inputFormatter';
import '../../../Core/BudgetCore/budget.scss';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { LinkedButton } from '../../../Core/BudgetCore/budgetButton';
import { postGetAfterSalesOverviewTable } from '../../../Core/Service/Nicol_Common_services';
import { useHistory } from 'react-router-dom';
import BudgetFilter from '../budgetFilters';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';

const useStyles = makeStyles({
    table: {
        minWidth: 1280
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#0e2132',
        color: theme.palette.common.white,
        padding: 8,
        fontSize: 12,
        fontFamily: `Montserrat Regular`
    },
    body: {
        fontSize: 14,
        fontFamily: `Montserrat Regular`
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'white'
        }
    }
}))(TableRow);

export const AfterSandLTable = (props) => {
    const history = useHistory();
    const [WindowWidths] = useWindowSize();
    const [state, setState] = useState({
        tableRow: []
    });
    const classes = useStyles();

    const handleTable = () => {
        history.push({ pathname: '/AfterSaleProfitLoss' });
    };

    const getFilterData = async (value) => {
        setState((st) => ({ ...st, showLoader: true }));

        let data = {
            filterDate: value.filterDate || [],
            franchiseCode: value.franchiseCode || [],
            branchID: value.branchID || []
        };

        let res = await postGetAfterSalesOverviewTable(data);
        if (res.success) {
            let data = res.data.list;
            setState((st) => ({ ...st, tableRow: data, showLoader: false }));
        }
    };

    return (
        <div className="budgetScreen">
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={6} sm={9} md={9} lg={10} xl={10}>
                    <div className="mainHeading alignLeft screenMainHeader">Aftersales Profit and Loss Table</div>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={2} xl={2}>
                    <Grid container spacing={1} justifyContent="flex-end">
                        <Hidden only={['xl', 'lg', 'md']}>
                            <Grid item>
                                <BudgetFilter getFilterData={getFilterData} />
                            </Grid>
                        </Hidden>
                        <Grid item>
                            <LinkedButton onClick={handleTable}>
                                <ViewComfyIcon /> {WindowWidths > 1024 ? <> &nbsp;&nbsp;ASALES P&L TABLE</> : ''}
                            </LinkedButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Hidden only={['sm', 'xs']}>
                <BudgetFilter getFilterData={getFilterData} />
            </Hidden>
            <TableContainer style={{ border: '2px solid lightgrey' }} className="aSalePanLtableContainerHeight">
                <Table className={classes.table} aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell width="20%"> </StyledTableCell>
                            <StyledTableCell align="right" width="10%">
                                RETAIL
                            </StyledTableCell>
                            <StyledTableCell align="right" width="10%">
                                INTERNAL
                            </StyledTableCell>
                            <StyledTableCell align="right" width="10%">
                                WARRANTY
                            </StyledTableCell>
                            <StyledTableCell align="right" width="10%">
                                TOTAL LABOUR
                            </StyledTableCell>
                            <StyledTableCell align="right" width="10%">
                                LABOUR TARGET
                            </StyledTableCell>
                            <StyledTableCell align="right" width="10%">
                                GROSS PROFIT
                            </StyledTableCell>
                            <StyledTableCell align="right">BUDGET</StyledTableCell>
                            <StyledTableCell align="right" width="10%">
                                DIFFERENCE
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state?.tableRow?.map((row, i) => {
                            return (
                                <StyledTableRow key={i}>
                                    <TableCell component="th" scope="row">
                                        <b>{row.branch}</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{formatters.ThousandSeparatorWithoutZero(row.retail)}</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{formatters.ThousandSeparatorWithoutZero(row.internal)}</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{formatters.ThousandSeparatorWithoutZero(row.warranty)}</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{formatters.ThousandSeparatorWithoutZero(row.totalLabour)}</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{formatters.ThousandSeparatorWithoutZero(row.labourTarget)}</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{formatters.ThousandSeparatorWithoutZero(row.grossProfit)}</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{formatters.ThousandSeparatorWithoutZero(row.budget)}</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{formatters.ThousandSeparatorWithoutZero(row.diff)}</b>
                                    </TableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

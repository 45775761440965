import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        border: '2px solid #f5f5f5',
        boxShadow: theme.shadows[3],
        padding: theme.spacing(2, 3, 2)
    }
}));

export const IdleTimeOutModal = ({ Logout, showModal, handleContinue, handleLogout, remainingTime }) => {
    const [counter, setCounter] = React.useState(60);

    useEffect(() => {
        if (showModal) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        } else {
            setCounter(60);
        }
        if (counter === 0) {
            Logout();
        }
    }, [counter, showModal]);
    const classes = useStyles();
    return (
        <div>
            <Modal className={classes.modal} open={showModal} closeAfterTransition>
                <Fade in={showModal}>
                    <div className={classes.paper}>
                        <div style={{ fontWeight: 600, fontSize: 21, margin: '10px 0px 5px 0px' }} id="transition-modal-title">
                            SESSION TIMEOUT
                        </div>
                        <div style={{ fontWeight: 500, fontSize: 17, margin: '15px 0px 20px 0px' }} id="transition-modal-description">
                            Your session is about to timeout in {counter} seconds
                        </div>
                        <Button variant="contained" onClick={handleContinue} style={{ background: '#5baa55', color: 'white' }}>
                            Extend Session
                        </Button>
                        <Button style={{ marginLeft: 10 }} variant="contained" color="secondary" onClick={handleLogout}>
                            Logout
                        </Button>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

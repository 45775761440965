import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';

import { Drawer, AppBar, Toolbar, List, Divider, ListItem, ListItemText, Collapse, Grid, MenuItem, MenuList, Popover } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Link, NavLink, Route, Switch } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useWindowSize } from '../Controls/ScreenResolution';
import AppContext from '../../App/AppContext';
import { getMenuForRole } from './_menu';
import MySignature from '../Modal/SignatureModal';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { postSignature, postSignatureStatus } from '../Service/login-service';
import { AppStorage } from '../Service/storage-service';
import TemporaryDrawer from './Drawer';
const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .MuiTypography-body1': {
            fontSize: '0.8rem',
            fontFamily: 'Montserrat !important',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938em'
        }
    },
    appBar: {
        height: '48px',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: (props) => (props.WindowWidths < 900 ? 0 : drawerWidth),
        width: (props) => (props.WindowWidths < 900 ? '100%' : `calc(100% - ${drawerWidth}px)`),
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        background: (props) => `${props.portalMenuBarBackgroundColour}`,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        background: (props) => `${props.portalMenuBarBackgroundColour}`,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(6.5) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(6.5) + 1
        }
    },
    toolbar: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(6.5) + 1
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar
    },
    app_toolbar: {
        height: '48px',
        minHeight: '48px',
        display: 'flex',
        padding: '0px 10px'
    },
    drawerPaper: {
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },

    labelBtn: {
        color: '#569101',
        textTransform: 'none',
        textDecoration: 'none',
        fontSize: '16px',
        fontFamily: 'Montserrat'
    },
    menuIcon: {
        minWidth: '40px'
    },
    logoutBtn: {
        display: 'inline-flex',
        VerticalAlign: 'text-bottom',
        BoxSizing: 'inherit',
        textAlign: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        cursor: 'pointer',
        marginLeft: 1
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        marginTop: (props) => (props.WindowWidths > 750 ? 4 : 0),
        width: '180px !important',
        maxHeight: '30px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 0.9),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit'
    },
    inputInput: {
        padding: '4px 8px 8px 0px',
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    },
    linknav: {
        background: (props) => `${props.portalMenuBarBackgroundColour}`,
        fontFamily: 'Montserrat',
        textDecoration: 'none',
        color: (props) => `${props.portalMenuBarTextColour}`,
        fontSize: '15px',
        '&:hover': {
            backgroundColor: '#4b573b'
        }
    },
    activeLink: {
        '& .MuiTypography-root': {
            color: '#DAFFA7 !important'
        }
    },
    itemText: {
        color: (props) => `${props.portalMenuBarTextColour}`,
        '& span, & svg': {
            fontSize: '0.875rem',
            color: (props) => `${props.portalMenuBarTextColour}`
        }
    },
    ChevronRightIconBtn: {
        color: (props) => `${props.portalMenuBarTextColour}`
    }
}));

export default function MiniDrawer(props) {
    const { loggedIn, userRoles, displayName, showToast, portalSettings, showModal, tempMenus } = useContext(AppContext);
    const [WindowWidths] = useWindowSize();
    const classes = useStyles({ ...portalSettings, WindowWidths });
    const theme = useTheme();
    const [open, setOpen] = React.useState((localStorage.getItem('_sideBarIsOpen_') || '').toUpperCase() === 'TRUE' || false);
    const [state, setState] = React.useState({
        isError404: false,
        WorkflowJobs: []
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSign, setOpenSign] = React.useState(false);

    let isAdmin = userRoles.includes('admin');

    const currentMenus = useMemo(() => {
        let menus = getMenuForRole(userRoles, loggedIn);

        return menus;
    }, [loggedIn, tempMenus]);

    const handleDrawerOpen = (isFlag) => {
        if (isFlag == true) {
            localStorage.setItem('_sideBarIsOpen_', true);
        }
        setOpen(true);
    };

    const handleDrawerClose = (isFlag) => {
        if (isFlag == true) {
            localStorage.setItem('_sideBarIsOpen_', false);
        }

        setOpen(false);
    };

    const handleClick = (item) => {
        setState((st) => ({ ...st, [item]: !st[item] }));
        handleDrawerOpen();
    };

    const handler = (children, isFiltered, isIcon) => {
        let currentNav = children;
        return currentNav?.map((subOption, index) => {
            if (!subOption.children) {
                return (
                    <div key={subOption.label}>
                        <NavLink activeClassName={classes.activeLink} exact to={subOption.path} className={classes.linknav}>
                            <ListItem className={classes.linknav} button size="small" key={subOption.label} title={subOption.label} onClick={handleDrawerClose}>
                                {isIcon ? (
                                    <ListItemIcon style={{ color: portalSettings.portalMenuBarTextColour }} className={classes.menuIcon}>
                                        {subOption.icon}
                                    </ListItemIcon>
                                ) : (
                                    ''
                                )}
                                {!open && !isIcon ? <div className={classes.menuIcon}></div> : ''}
                                <ListItemText className={classes.itemText}>{subOption.label}</ListItemText>
                            </ListItem>
                        </NavLink>
                    </div>
                );
            }
            return (
                <div key={subOption.label}>
                    <ListItem className={classes.linknav} button onClick={() => handleClick(subOption.label)} title={subOption.label}>
                        {isIcon ? (
                            <ListItemIcon style={{ color: portalSettings.portalMenuBarTextColour }} className={classes.menuIcon}>
                                {subOption.icon}
                            </ListItemIcon>
                        ) : (
                            ''
                        )}
                        {!open && !isIcon ? <div className={classes.menuIcon}></div> : ''}
                        <ListItemText className={classes.itemText}>{subOption.label}</ListItemText>
                        <ListItemIcon
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                minWidth: '35px'
                            }}
                            className={classes.linknav}
                        >
                            {state[subOption.label] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                    </ListItem>
                    <Collapse className={classes.nested} in={state[subOption.label]} timeout="auto" unmountOnExit>
                        {handler(subOption.children, true, false)}
                    </Collapse>
                </div>
            );
        });
    };
    const openInfo = Boolean(anchorEl);
    const id = openInfo ? 'simple-popover' : undefined;
    const handlePopupClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopupClose = () => {
        setAnchorEl(null);
    };

    const closeSign = () => {
        setOpenSign(!openSign);
    };

    const showPasswordChangeModal = (e) => {
        e.preventDefault();
        showModal('PasswordChange');
    };

    const postSignData = async (sign, id) => {
        const data = {
            signature: sign
        };
        let resSign = sign && (await postSignature(data));

        if (resSign) {
            if (resSign?.success) {
                AppStorage.setSignature(sign);
                showToast('Signature Updated Successfully!');
            } else {
                showToast(resSign?.message || 'Something went wrong!');
            }
        }
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={`ScaleResizerClass appBar-height ${clsx(classes.appBar, {
                    [classes.appBarShift]: open
                })}`}
                style={{
                    backgroundColor: 'white',
                    color: '#569101'
                }}
            >
                <Toolbar className={classes.app_toolbar}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            {loggedIn && (
                                <>
                                    {WindowWidths > 900 ? (
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={() => handleDrawerOpen(true)}
                                            edge="start"
                                            style={{ padding: '0px', marginLeft: '7px' }}
                                            className={clsx(classes.menuButton, {
                                                [classes.hide]: open
                                            })}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            onClick={toggleDrawer('left', true)}
                                            edge="start"
                                            className={classes.menuButton}
                                            color="inherit"
                                            aria-label="menu"
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    )}
                                </>
                            )}
                        </Grid>
                        <Grid item style={{ flex: 1 }}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item></Grid>
                                <Grid item>
                                    <div style={{ display: 'inline-block' }}>
                                        {loggedIn ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Grid
                                                    style={{
                                                        marginRight: 2,
                                                        display: 'inline-flex',
                                                        marginTop: -7
                                                    }}
                                                ></Grid>
                                                &nbsp;
                                                <Grid
                                                    container
                                                    aria-describedby={id}
                                                    onClick={handlePopupClick}
                                                    className={classes.logoutBtn}
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        <span className={classes.labelBtn}>{displayName}</span>
                                                    </Grid>
                                                    <Grid item>
                                                        <ExpandMore />
                                                    </Grid>
                                                </Grid>
                                                <Popover
                                                    id={id}
                                                    open={openInfo}
                                                    anchorEl={anchorEl}
                                                    onClose={handlePopupClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center'
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center'
                                                    }}
                                                >
                                                    <MenuList style={{}}>
                                                        <MenuItem>
                                                            <Link to="/logout" className={`${classes.logoutBtn} ${classes.labelBtn}`}>
                                                                <ExitToAppIcon style={{ marginRight: '4px' }} />
                                                                <span>Logout</span>
                                                            </Link>
                                                        </MenuItem>
                                                        {!isAdmin && (
                                                            <MenuItem className={classes.labelBtn} onClick={closeSign}>
                                                                <BorderColorIcon style={{ marginRight: '4px' }} />
                                                                <span onClick={closeSign}>My Signature</span>
                                                            </MenuItem>
                                                        )}
                                                        <MenuItem className={classes.labelBtn}>
                                                            <VpnKeyIcon style={{ marginRight: '4px' }} />
                                                            <span onClick={showPasswordChangeModal}>Change Password</span>
                                                        </MenuItem>
                                                        <MenuItem className={classes.labelBtn} style={{ cursor: 'none' }}>
                                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                            <span>Version 1.1</span>
                                                        </MenuItem>
                                                    </MenuList>
                                                </Popover>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            {openSign && <MySignature onClose={closeSign} postData={postSignData} />}
            {loggedIn && (
                <div className="ScaleResizerClass">
                    {WindowWidths > 900 ? (
                        <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open
                            })}
                            classes={{
                                paper: clsx({
                                    [classes.drawerOpen]: open,
                                    [classes.drawerClose]: !open
                                })
                            }}
                            PaperProps={{ className: `appBar-height ${classes.drawerPaper}` }}
                        >
                            <div className={classes.toolbar}>
                                <IconButton onClick={() => handleDrawerClose(true)} className={classes.ChevronRightIconBtn}>
                                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                </IconButton>
                            </div>
                            <Divider />
                            <List>
                                {handler(
                                    currentMenus.filter((m) => !m.hidden),
                                    false,
                                    true
                                )}
                            </List>
                        </Drawer>
                    ) : (
                        <Drawer
                            anchor={'left'}
                            open={state.left}
                            onClose={toggleDrawer('left', false)}
                            PaperProps={{ className: `appBar-height ${classes.drawerPaper}` }}
                            BackdropProps={{ className: `appBar-height` }}
                        >
                            <TemporaryDrawer currentMenus={currentMenus} onClose={toggleDrawer} />
                        </Drawer>
                    )}
                </div>
            )}
            <main className="content">
                <div className="ScaleResizerClass">
                    <div className={classes.toolbar} style={{ minHeight: '48px' }} />
                    <Switch>
                        {currentMenus.map((m, i) => {
                            return m.children ? (
                                m.children.map((a) => {
                                    return a.children ? (
                                        a.children.map((b) => {
                                            return <Route key={b.label} exact={b.exact} path={b.path} component={b.component}></Route>;
                                        })
                                    ) : (
                                        <Route key={a.label} exact={a.exact} path={a.path} component={a.component}></Route>
                                    );
                                })
                            ) : (
                                <Route key={m.label} exact={m.exact} path={m.path} component={m.component}></Route>
                            );
                        })}
                    </Switch>
                </div>
            </main>
        </div>
    );
}

import React, { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppContext from '../../App/AppContext';
import { useWindowSize } from '../Controls/ScreenResolution';
import MiniDrawer from '../Root/LeftDrawer';
import { useState } from 'react';
import IdleTimeOutHandler from '../Root/sessionClose';
import { AppStorage } from '../Service/storage-service';

export let Header = () => {
    const { portalSettings, isTopNav } = useContext(AppContext);
    const [WindowWidths] = useWindowSize();
    const [isActive, setIsActive] = useState(true);
    const [isLogout, setLogout] = useState(false);

    const img = !!portalSettings.portalSettingMainLogo ? portalSettings.portalSettingMainLogo : `${process.env.PUBLIC_URL}/${portalSettings.portalSettingLogo}`;

    const handleLogout = () => {
        AppStorage.performLogout();
        window.location = '/';
    };

    return (
        <BrowserRouter>
            <>
                {AppStorage.isLoggedIn() ? (
                    <IdleTimeOutHandler
                        onActive={() => {
                            setIsActive(true);
                        }}
                        onIdle={() => {
                            setIsActive(false);
                        }}
                        onLogout={() => {
                            setLogout(true);
                        }}
                        Logout={handleLogout}
                    />
                ) : null}
                <div className="header ScaleResizerClass">
                    <>
                        <div
                            className="portal-info portal-info-height"
                            style={{
                                backgroundColor: portalSettings.portalHeaderBackgroundColour,
                                color: portalSettings.portalHeaderTextColour,
                                display: isTopNav == false ? 'none' : 'flex',
                                alignItems: 'center',
                                justifyContent: WindowWidths >= 600 ? 'flex-start' : 'center',
                                height: '96px',
                                position: 'fixed',
                                zIndex: 999,
                                width: '100%'
                            }}
                        >
                            {WindowWidths >= 600 ? (
                                <div className="branding-logo">
                                    <img src={img} className="brandLogo" alt="" />
                                </div>
                            ) : (
                                <div className="branding-logo" style={{ marginLeft: '-124px' }}>
                                    <img src={img} className="brandLogo" alt="" />
                                </div>
                            )}
                        </div>
                        <div
                            className="portal-info portal-info-height"
                            style={{
                                backgroundColor: '#569101'
                            }}
                        ></div>
                    </>
                </div>
                <div>
                    <MiniDrawer />
                </div>
            </>
        </BrowserRouter>
    );
};

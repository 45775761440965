import moment from 'moment';
import { get, post } from './http-calls';

export const nicoleDownloadFileWithExt = async (res, fileExt, fileName) => {
    if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
        link.id = 'tempDownloadPDFLink';
        link.href = url;
        link.setAttribute('download', `${fileName}${moment().format('DD-MMM-YYYY HH:mm')}.${fileExt}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return true;
    }
};

export const getBranchOverview = async (SD, ED, FC, BI) => {
    let res = await get(`Nicol/GetBranchOverViewDashBoardData?StartDate=${SD}&EndDate=${ED}&FranchiseCode=${FC}&BranchID=${BI}`, { useAuthToken: true });
    return res;
};

export const postBranchOverView = async (data) => {
    let res = await post(`Nicol/GetBranchOverViewDashBoardData`, data, { useAuthToken: true });
    return res;
};

export const postSalesProfitLossTable = async (data) => {
    let res = await post(`Nicol/GetSalesProfitLossTable`, data, { useAuthToken: true });
    return res;
};

export const postSalesProfitLossTableGetCSVFile = async (data, fileName) => {
    let res = await post(`Nicol/GetExcelCombineSalesProfitLoss`, data, { responseType: 'blob', returnOrgRes: true, useAuthToken: true });
    if (res.status != 200) {
        return false;
    } else {
        return nicoleDownloadFileWithExt(res, 'xlsx', fileName);
    }
};

export const postSalesProfitLossTableGetPDFFile = async (data, fileName) => {
    let res = await post(`Nicol/GetPDFCombineSalesProfitLoss`, data, { responseType: 'blob', returnOrgRes: true, useAuthToken: true });
    if (res.status != 200) {
        return false;
    } else {
        return nicoleDownloadFileWithExt(res, 'pdf', fileName);
    }
};

export const postSalesProfitLossDashBoardData = async (data) => {
    let res = await post(`Nicol/GetSalesProfitLossDashBoardData`, data, { useAuthToken: true });
    return res;
};

export const postGetAfterSalesOverviewTable = async (data) => {
    let res = await post(`Nicol/GetAfterSalesOverviewTable`, data, { useAuthToken: true });
    return res;
};

export const postAfterSalesOverviewData = async (data) => {
    let res = await post(`Nicol/GetAfterSalesOverviewData`, data, { useAuthToken: true });
    return res;
};

export const postProfitLossData = async (data) => {
    let res = await post(`ProfitLoss/DashBoardData`, data, { useAuthToken: true });
    return res;
};

export const postSalesReportingDashBoardData = async (data) => {
    let res = await post(`ProfitLoss/GetSalesReportingDashBoardData`, data, { useAuthToken: true });
    return res;
};

export const getAgedStockValuation = async (data) => {
    let res = await get(`ProfitLoss/GetAgedStockValuationDashBoardData`, { useAuthToken: true });
    return res;
};

export const getUsedStockValuation = async (data, includeReserved) => {
    let res = await get(`ProfitLoss/GetUsedStockValuationDashBoardData?Type=${data}&IncludeReserved=${includeReserved}`, { useAuthToken: true });
    return res;
};

export const postUsedVehicleStockValuation = async (data) => {
    let res = await post(`ProfitLoss/GetUsedVehicleStockDashBoardData`, data, { useAuthToken: true });
    return res;
};
export const postSalesReportingDetail = async (data) => {
    let res = await post(`ProfitLoss/GetSalesReportingDetail`, data, { useAuthToken: true });
    return res;
};

export const postOveragePriorityList = async (data) => {
    let res = await post(`ProfitLoss/GetOveragePriorityListDashBoardData`, data, { useAuthToken: true });
    return res;
};

export const postOveragePriorityGetExcelFile = async (data, fileName) => {
    let res = await post(`ProfitLoss/OveragePriority/GetExcelFile`, data, { responseType: 'blob', returnOrgRes: true, useAuthToken: true });
    nicoleDownloadFileWithExt(res, 'xlsx', fileName);
};

export const postUsedVehicleStockGetExcelFile = async (data, fileName) => {
    let res = await post(`ProfitLoss/UsedVehicleStock/GetExcelFile`, data, { responseType: 'blob', returnOrgRes: true, useAuthToken: true });
    nicoleDownloadFileWithExt(res, 'xlsx', fileName);
};

export const postSalesReportingDetailGetExcelFile = async (data, fileName) => {
    let res = await post(`ProfitLoss/SalesReportingDetail/GetExcelFile`, data, { responseType: 'blob', returnOrgRes: true, useAuthToken: true });
    nicoleDownloadFileWithExt(res, 'xlsx', fileName);
};

export const getVehicleProfileListDropDown = async (data) => {
    let res = await get(`AgedMeasures/ReportingGetProgressCodes`, { useAuthToken: true });
    return res;
};

export const postBranchOverViewSoldUnits = async (data, isFileDownload, fileName) => {
    let url = `Nicol/GetBranchOverviewKPISoldUnits`;
    if (isFileDownload) {
        data.fileType = 'excel';
        let res = await post(`${url}`, data, { responseType: 'blob', returnOrgRes: true, useAuthToken: true });
        nicoleDownloadFileWithExt(res, 'xlsx', fileName);
    } else {
        let res = await post(`${url}`, data, { useAuthToken: true });
        return res;
    }
};
export const postBranchOverViewNewReserved = async (data, isFileDownload, fileName) => {
    let url = `Nicol/GetNewRegistered`;
    if (isFileDownload) {
        data.fileType = 'excel';
        let res = await post(`Nicol/GetNewRegistered`, data, { responseType: 'blob', returnOrgRes: true, useAuthToken: true });
        nicoleDownloadFileWithExt(res, 'xlsx', fileName);
    } else {
        let res = await post(`${url}`, data, { useAuthToken: true });
        return res;
    }
};
export const postBranchOverViewUsedReserved = async (data, isFileDownload, fileName) => {
    let url = `Nicol/GetUsedReserved`;
    if (isFileDownload) {
        data.fileType = 'excel';
        let res = await post(`Nicol/GetUsedReserved`, data, { responseType: 'blob', returnOrgRes: true, useAuthToken: true });
        nicoleDownloadFileWithExt(res, 'xlsx', fileName);
    } else {
        let res = await post(`${url}`, data, { useAuthToken: true });
        return res;
    }
};

export const postReservedForUnits = async (data, type) => {
    let url = '';
    if (type === 'new') {
        url = 'Nicol/GetNewRegistered';
    } else if (type === 'used') {
        url = 'Nicol/GetUsedReserved';
    }
    let res = await post(`${url}`, data, { useAuthToken: true });
    return res;
};

export const postBranchOverviewKPIInvoicedNewUnits = async (data, isFileDownload, fileName) => {
    let url = `Nicol/GetBranchOverviewKPIInvoicedNewUnits`;
    if (isFileDownload) {
        data.fileType = 'excel';
        let res = await post(`${url}`, data, { responseType: 'blob', returnOrgRes: true, useAuthToken: true });
        nicoleDownloadFileWithExt(res, 'xlsx', fileName);
    } else {
        let res = await post(`${url}`, data, { useAuthToken: true });
        return res;
    }
};

export const postBranchOverviewKPIInvoicedUsedUnits = async (data, isFileDownload, fileName) => {
    let url = `Nicol/GetBranchOverviewKPIInvoicedUsedUnits`;
    if (isFileDownload) {
        data.fileType = 'excel';
        let res = await post(`${url}`, data, { responseType: 'blob', returnOrgRes: true, useAuthToken: true });
        nicoleDownloadFileWithExt(res, 'xlsx', fileName);
    } else {
        let res = await post(`${url}`, data, { useAuthToken: true });
        return res;
    }
};
export const postBranchOverviewKPLabour = async (data, isFileDownload, fileName) => {
    let url = `Nicol/Nicol_RetailGetAfterSalesData`;
    if (isFileDownload) {
        data.fileType = 'excel';
        let res = await post(`Nicol/DownloadRetailData`, data, { responseType: 'blob', returnOrgRes: true, useAuthToken: true });
        nicoleDownloadFileWithExt(res, 'xlsx', fileName);
    } else {
        let res = await post(`${url}`, data, { useAuthToken: true });
        return res;
    }
};

export const getCAPCleanHistory = async (stockID) => {
    let res = await get(`CAPClean/History?Id=${stockID}`, { useAuthToken: true });
    return res;
};

export const getUncappedVehicles = async (getData) => {
    let res = await get(`CAPClean/UncappedVehicles?GetData=${getData}`, { useAuthToken: true });
    return res;
};

export const postManuallyUpdateCAPValuation = async (data) => {
    let res = await post(`CAPClean/ManuallyUpdateCAPValuation`, data, { useAuthToken: true });
    return res;
};

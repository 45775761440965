const storage = localStorage;

const getName = () => storage.getItem('_displayName_');
const getToken = () => storage.getItem('_token_');
const getLastLogin = () => storage.getItem('_lastLogin_');
const getCanResetPassword = () => (storage.getItem('_canResetPassword_') || '').toUpperCase() === 'TRUE';
const getUserRoles = () => (storage.getItem('_userRoles_') || '').split(',');
const getSignature = () => {
    let sign = storage.getItem('_user_sign_');
    return sign == 'absent' ? null : sign;
};
const setSignature = (sign) => {
    sign && storage.setItem('_user_sign_', sign);
};
const getSignatureStatus = () => {
    return storage.getItem('_signature_status_');
};

const getReportingAllowed = () => {
    return (storage.getItem('_eReporting_Allowed_') || '').toUpperCase() === 'TRUE';
};

const menuPermissionCounter = () => {
    return getReportingAllowed();
};

const setSignatureStatus = (ID) => {
    ID && storage.setItem('_signature_status_', ID);
};

const getReportingCanAccessBranchOverview = () => {
    return (storage.getItem('_canAccessBranchOverview_') || '').toUpperCase() === 'TRUE';
};
const getReportingCanAccessSalesProfitAndLoss = () => {
    return (storage.getItem('_canAccessSalesProfitAndLoss_') || '').toUpperCase() === 'TRUE';
};
const getReportingCanAccessAfterSalesProfitAndLoss = () => {
    return (storage.getItem('_canAccessAfterSalesProfitAndLoss_') || '').toUpperCase() === 'TRUE';
};
const getReportingCanAccessProfitAndLossDetail = () => {
    return (storage.getItem('_canAccessProfitAndLossDetail_') || '').toUpperCase() === 'TRUE';
};
const getReportingCanAccessUsedStockValuation = () => {
    return (storage.getItem('_canAccessUsedStockValuation_') || '').toUpperCase() === 'TRUE';
};
const getReportingCanAccessUsedVehicleStock = () => {
    return (storage.getItem('_canAccessUsedVehicleStock_') || '').toUpperCase() === 'TRUE';
};
const getReportingCanAccessAgedVehicleStock = () => {
    return (storage.getItem('_canAccessAgedVehicleStock_') || '').toUpperCase() === 'TRUE';
};
const getReportingCanAccessOveragePriorityList = () => {
    return (storage.getItem('_canAccessOveragePriorityList_') || '').toUpperCase() === 'TRUE';
};
const getReportingCanAccessSalesReporting = () => {
    return (storage.getItem('_canAccessSalesReporting_') || '').toUpperCase() === 'TRUE';
};
const getReportingCanAccessUncappedVehicled = () => {
    return (storage.getItem('_canAccessUncappedVehicled_') || '').toUpperCase() === 'TRUE';
};
const getPortalSetting = () => {
    const setting = storage.getItem('_portal_setting_');
    if (setting) {
        return JSON.parse(setting);
    }
    return setting;
};
const setPortalSetting = (setting) => {
    storage.setItem('_portal_setting_', JSON.stringify(setting));
};

export const AppStorage = {
    getItem: storage.getItem,
    setItem: storage.setItem,
    removeItem: storage.removeItem,
    getName,
    getToken,
    getLastLogin,
    getCanResetPassword,
    getUserRoles,
    getPortalSetting,
    setPortalSetting,
    getSignature,
    setSignature,
    getSignatureStatus,
    setSignatureStatus,
    getReportingAllowed,
    menuPermissionCounter,
    getReportingCanAccessBranchOverview,
    getReportingCanAccessSalesProfitAndLoss,
    getReportingCanAccessAfterSalesProfitAndLoss,
    getReportingCanAccessProfitAndLossDetail,
    getReportingCanAccessUsedStockValuation,
    getReportingCanAccessUsedVehicleStock,
    getReportingCanAccessAgedVehicleStock,
    getReportingCanAccessOveragePriorityList,
    getReportingCanAccessSalesReporting,
    getReportingCanAccessUncappedVehicled,
    isLoggedIn: () => !!getName() && !!getToken(),
    performLogin: ({ data }) => {
        storage.setItem('_token_', data.token);
        storage.setItem('_displayName_', data.displayName);
        storage.setItem('_lastLogin_', data.lastLogin);
        storage.setItem('_canResetPassword_', data.canResetPassword);
        storage.setItem('_userRoles_', data.roles);
        storage.setItem('_user_sign_', data.defaultSignature || 'absent');
        storage.setItem('_eReporting_Allowed_', data.eReportingAllowed);
        storage.setItem('_canAccessBranchOverview_', data.canAccessBranchOverview);
        storage.setItem('_canAccessSalesProfitAndLoss_', data.canAccessSalesProfitAndLoss);
        storage.setItem('_canAccessAfterSalesProfitAndLoss_', data.canAccessAfterSalesProfitAndLoss);
        storage.setItem('_canAccessProfitAndLossDetail_', data.canAccessProfitAndLossDetail);
        storage.setItem('_canAccessUsedStockValuation_', data.canAccessUsedStockValuation);
        storage.setItem('_canAccessUsedVehicleStock_', data.canAccessUsedVehicleStock);
        storage.setItem('_canAccessAgedVehicleStock_', data.canAccessAgedVehicleStock);
        storage.setItem('_canAccessOveragePriorityList_', data.canAccessOveragePriorityList);
        storage.setItem('_canAccessSalesReporting_', data.canAccessSalesReporting);
        storage.setItem('_defaultBranchID', data.defaultBranchID);
    },
    performLogout: () => {
        const portalSetting = getPortalSetting();
        storage.clear();

        if (portalSetting) {
            setPortalSetting({ ...portalSetting, expired: true });
        }
    }
};

import { Grid, Hidden } from '@material-ui/core';
import '../../../Core/BudgetCore/budget.scss';
import { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';

import { SalesCard } from '../../../Core/BudgetCore/budgetCardsUi_v2';
import { FilterBtn } from '../../../Core/BudgetCore/budgetButton';
import SalesReportingTable, { SalesReportingTableMobile } from './salesProfitTable';
import { postSalesReportingDashBoardData } from '../../../Core/Service/Nicol_Common_services';
import BudgetFilter from '../budgetFilters';
import CircularIndeterminate from '../../../Core/BudgetCore/loader';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';

const filterBtnList = [
    { label: 'COMBINED', id: 'C' },
    { label: 'NEW', id: 'N' },
    { label: 'USED', id: 'U' },
    { label: 'MOTAB', id: 'M' },
    { label: 'FLEET', id: 'F' }
];

const SalesReporting = () => {
    const [state, setState] = useState({
        branchList: [],
        franchise: [],
        unitsList: {},
        branchBudgets: {},
        groupIds: [],
        franchiseIds: [],
        mainData: [],
        tabledata: [],
        selectedFilterBtn: filterBtnList[0].id,
        franchiseCode: [],
        branchID: [],
        isReload: '',
        showLoader: true
    });

    const [WindowWidths] = useWindowSize();

    const filterBtnChange = (id) => {
        if (id._reactName === 'onChange') {
            setState((st) => ({
                ...st,
                selectedFilterBtn: id.target.value
            }));
        } else {
            setState((st) => ({
                ...st,
                selectedFilterBtn: id
            }));
        }
    };

    const getFilterData = async (value) => {
        setState((st) => ({ ...st, showLoader: true }));

        let data = {
            filterDate: value.newWeekFilter || [],
            franchiseCode: value.franchiseCode,
            branchID: value.branchID,
            Type: value.type
        };

        let res = await postSalesReportingDashBoardData(data);
        if (res.success) {
            let mainData = res.data.mainData[0];
            setState((st) => ({
                ...st,
                mainData: [
                    {
                        label: 'Total New Retail Units',
                        subLabelOne: 'NEW PROFIT',
                        subLabelTwo: 'NEW PPU',
                        units: mainData.totalNewRetailUnits,
                        profit: mainData.newProfit,
                        ppu: mainData.newPPU
                    },
                    {
                        label: 'Total Used Retail Units',
                        subLabelOne: 'Used PROFIT',
                        subLabelTwo: 'Used PPU',
                        units: mainData.totalUsedRetailUnits,
                        profit: mainData.usedProfit,
                        ppu: mainData.usedPPU
                    },
                    {
                        label: 'Total Motability Units',
                        subLabelOne: 'MOTAB PROFIT',
                        subLabelTwo: 'MOTAB PPU',
                        units: mainData.totalMotabilityRetailUnits,
                        profit: mainData.motabilityProfit,
                        ppu: mainData.motabilityPPU
                    },
                    {
                        label: 'Total Fleet Units',
                        subLabelOne: 'Fleet PROFIT',
                        subLabelTwo: 'Fleet PPU',
                        units: mainData.totalFleetUnits,
                        profit: mainData.fleetProfit,
                        ppu: mainData.fleetPPU
                    },
                    {
                        label: 'Total Units',
                        subLabelOne: 'Total PROFIT',
                        subLabelTwo: 'Total PPU',
                        units: mainData.totalUnits,
                        profit: mainData.totalProfit,
                        ppu: mainData.totalPPU
                    }
                ],
                tabledata: res.data.tabledata,
                showLoader: false
            }));
        }
    };

    return (
        <div className="budgetScreen">
            <Grid container>
                <Grid container spacing={1} alignContent="center" justifyContent="space-between">
                    <Grid item xs={6} sm={9} md={9} lg={10} xl={10}>
                        <div className="mainHeading alignLeft screenMainHeader">Sales Reporting</div>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={2} xl={2}>
                        <Grid container spacing={2} justifyContent="flex-end" style={{ paddingTop: 5 }}>
                            <Hidden only={['xl', 'lg', 'md']}>
                                <Grid item>
                                    <BudgetFilter getFilterData={getFilterData} type={state.selectedFilterBtn} sales={true} isPermissionBased={false} />
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>

                <Hidden only={['sm', 'xs']}>
                    <BudgetFilter getFilterData={getFilterData} type={state.selectedFilterBtn} sales={true} isPermissionBased={false} />
                </Hidden>

                {state.showLoader ? (
                    <CircularIndeterminate />
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-start">
                                {state.mainData.map((m, i) => (
                                    <Grid item xs={6} sm={6} md={4} lg={2} xl={2} key={i}>
                                        <SalesCard
                                            label={m.label}
                                            value={m.units}
                                            newProfit={m.profit}
                                            newPpu={m.ppu}
                                            subLabelOne={m.subLabelOne}
                                            subLabelTwo={m.subLabelTwo}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        {WindowWidths >= 600 ? (
                            <>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="flex-start" spacing={WindowWidths > 600 ? 0 : 1}>
                                        {filterBtnList.map((p) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={3}
                                                    md={3}
                                                    lg={2}
                                                    xl={2}
                                                    key={p.id}
                                                    className={WindowWidths > 600 ? 'containerPadding' : ''}
                                                >
                                                    <FilterBtn active={state.selectedFilterBtn == p.id} onClick={() => filterBtnChange(p.id)}>
                                                        {p.label}
                                                    </FilterBtn>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="space-around" className="containerPadding">
                                        <SalesReportingTable rows={state.tabledata} type={state.selectedFilterBtn} />
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12} style={{ marginTop: 20 }}>
                                    <div className="SRtype1_Text p4" style={{ marginBottom: 10 }}>
                                        Daily Sales Report
                                    </div>

                                    <select
                                        name="selectedFilterBtn"
                                        value={state.selectedFilterBtn}
                                        onChange={filterBtnChange}
                                        style={{
                                            width: '100%',
                                            padding: 10,
                                            background: '#dcd4fc',
                                            color: '#522bd2',
                                            textAlign: 'center',
                                            fontFamily: `Montserrat`,
                                            fontWeight: 600,
                                            border: '0.0625rem solid #522bd2',
                                            marginBottom: 10
                                        }}
                                    >
                                        {filterBtnList.map((p, i) => {
                                            return (
                                                <option key={i + 1} value={p.id} style={{ background: 'white' }}>
                                                    {p.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="space-around" className="containerPadding">
                                        <SalesReportingTableMobile rows={state.tabledata} type={state.selectedFilterBtn} />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Grid>
        </div>
    );
};

export default SalesReporting;

import { FormLabel, Grid, TextField } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { useEffect } from 'react';
import TabComponent from '../../../Core/Controls/Tabs';

import '../commonStyle.scss';
import { getBudgetDropDown } from '../../../Core/Service/branch-screen-service';
import SalesTargetScreen from './SalesTargetScreen';

import ExpenseComp from './expenseBudgetScreen';
import OtherIncomeComp from './otherIncomeBudgetScreen';
import PartsSalesTargetScreen from './Sales/PartsTargetSales';
import ServiceSalesTargetScreen from './Sales/ServiceTargateSales';
import UsedVehicleSalesTargetScreen from './Sales/UsedVehicleSales';

const DepartmentDetailsComp = (props) => {
    const insideTabs = [
        ...(props.data.includeSales
            ? [
                  {
                      label: 'Sales',
                      body:
                          props.data.departmentID == 4 ? (
                              <PartsSalesTargetScreen yearCode={props.yearCode} branchID={props.branchID} data={props.data} fromPage="sales" />
                          ) : props.data.departmentID == 5 ? (
                              <ServiceSalesTargetScreen yearCode={props.yearCode} branchID={props.branchID} data={props.data} fromPage="sales" />
                          ) : props.data.departmentID == 3 ? (
                              <UsedVehicleSalesTargetScreen yearCode={props.yearCode} branchID={props.branchID} data={props.data} fromPage="sales" />
                          ) : (
                              <SalesTargetScreen yearCode={props.yearCode} branchID={props.branchID} data={props.data} fromPage="sales" />
                          )
                  }
              ]
            : []),
        ...(props.data.includeExpenses
            ? [
                  {
                      label: 'Expense',
                      body: <ExpenseComp yearCode={props.yearCode} branchID={props.branchID} data={props.data} fromPage="expense" />
                  }
              ]
            : []),
        ...(props.data.includeExpenses
            ? [
                  {
                      label: 'Other Income',
                      body: <OtherIncomeComp yearCode={props.yearCode} branchID={props.branchID} data={props.data} fromPage="otherIncome" />
                  }
              ]
            : [])
    ];
    return (
        <Grid container item>
            <Grid item style={{ height: '100%', width: '100%' }}>
                <TabComponent tabList={insideTabs} />
            </Grid>
        </Grid>
    );
};

const BranchTargetScreen = (props) => {
    const [state, setState] = useState({
        filterList: [],
        branchList: [],
        branchID: props.branchID || '',
        branchName: props.branchName || '',
        yearCode: { description: `${new Date().getFullYear()}`, id: `${new Date().getFullYear()}` },
        currentMonthDays: [],
        customerList: [],
        customerCode: {},
        filterData: [],
        budgetYear: [],
        salesDepartments: []
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await getBudgetDropDown();

                setState((st) => {
                    let newSt = { ...st };
                    newSt.budgetYear = res.data.budgetYear.map((o) => {
                        return { description: `${o.budgetYear}`, id: `${o.budgetYear}` };
                    });
                    newSt.salesDepartments = res.data.salesDepartments;

                    return newSt;
                });
            } catch (error) {
                // Handle any errors that might occur during the asynchronous operation
                console.error('An error occurred:', error);
            }
        };

        fetchData();
    }, []);

    const handleAutoChange = (fieldName) => (e, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt[fieldName] = val;
            return newSt;
        });
    };

    const tabs = state.salesDepartments.map((q, i) => {
        return { label: `${q.name}`, body: <DepartmentDetailsComp data={q} yearCode={state.yearCode} branchID={state.branchID} key={i} /> };
    });

    return (
        <Grid container>
            <Grid container alignItems="center">
                <Grid item>
                    <FormLabel>Select Year&nbsp;&nbsp;</FormLabel>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <Autocomplete
                        disableClearable
                        autoSelect
                        options={state.budgetYear}
                        name="Years"
                        value={state.yearCode}
                        onChange={handleAutoChange('yearCode')}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                        renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item style={{ height: '800px', width: '100%' }}>
                    <TabComponent tabList={tabs} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BranchTargetScreen;

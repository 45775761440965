import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const closeIconStyle = {
    marginRight: 7
};
let DialogComp = ({ title, children, footer, onClose, maxWidth = "sm", overflow = "hidden", height = "none",...rest }) => {
    return (
        <>
            <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={true} maxWidth={maxWidth} {...rest}>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>{title && <DialogTitle>{title}</DialogTitle>}</Box>
                    <Box>
                        <IconButton onClick={onClose} style={closeIconStyle}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <DialogContent style={{ overflow: overflow, height: height }}>{children}</DialogContent>
                {footer && <DialogActions>{footer}</DialogActions>}
            </Dialog>
        </>
    );
};

export default DialogComp;

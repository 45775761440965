import WBO from './Nicol_Graphics/Nav_Bar_Icons/WBO.png';
import WPL from './Nicol_Graphics/Nav_Bar_Icons/WPL.png';
import WPLD from './Nicol_Graphics/Nav_Bar_Icons/WPLD.png';
import WUSV from './Nicol_Graphics/Nav_Bar_Icons/WUSV.png';
import WUVS from './Nicol_Graphics/Nav_Bar_Icons/WUVS.png';
import WAVS from './Nicol_Graphics/Nav_Bar_Icons/WAVS.png';
import WOPL from './Nicol_Graphics/Nav_Bar_Icons/WOPL.png';
import WSALE from './Nicol_Graphics/Nav_Bar_Icons/WSALE.png';

import BrandLogo from './Nicol_Graphics/125x50_White.png';
import WhitePL from './Nicol_Graphics/Nav_Bar_Icons/WPL.png';

const BranchOverView = () => {
    return <img src={WBO} alt="WBO" />;
};
const ProfitLoss = () => {
    return <img src={WPL} alt="WPL" />;
};
const ProfitLossDetails = () => {
    return <img src={WPLD} alt="WPLD" />;
};
const UsedStockValuation = () => {
    return <img src={WUSV} alt="WUSV" />;
};
const UsedVehicleStock = () => {
    return <img src={WUVS} alt="WUVS" />;
};
const AgeVehicleSTock = () => {
    return <img src={WAVS} alt="WAVS" />;
};
const OveragePriorityIcon = () => {
    return <img src={WOPL} alt="WOPL" />;
};
const SaleIcon = () => {
    return <img src={WSALE} alt="WSALE" />;
};

const WhitePLIcon = (props) => {
    return <img src={WhitePL} alt="WhitePL" {...props} />;
};

export {
    SaleIcon,
    BranchOverView,
    ProfitLoss,
    ProfitLossDetails,
    UsedStockValuation,
    UsedVehicleStock,
    AgeVehicleSTock,
    OveragePriorityIcon,
    BrandLogo,
    WhitePLIcon
};

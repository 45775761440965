import { useContext } from 'react';
import AppContext from '../../App/AppContext';
import PasswordChange from './PasswordChange';
import DeleteModal from './DeleteModal';
import ForgotPassword from './ForgotPassword';

function getModalToShow(visibleModalId) {
    switch (visibleModalId) {
        case 'PasswordChange':
            return PasswordChange;
        case 'DeleteModal':
            return DeleteModal;

        case 'ForgotPassword':
            return ForgotPassword;
        default:
            // Handle the default case here
            console.log('');
            break;
    }
}
const ProfileModal = (props) => {
    const { visibleModalId } = useContext(AppContext);
    const ModalToShow = getModalToShow(visibleModalId);
    return <>{ModalToShow && <ModalToShow />}</>;
};
export { ProfileModal };

import { useEffect } from 'react';

const Logout = (props) => {
    useEffect(() => {
        window.top.postMessage({ type: 'trigger-logout' }, '*');
    }, []);
    return <div />;
};

export default Logout;

import React, { useState, useEffect } from 'react';
import { Grid, Hidden, TableContainer, Table, TableBody, TableCell, TableRow, TablePagination, makeStyles, TableHead, TableSortLabel } from '@material-ui/core';
import { TextBox } from '../../../Core/FormInput';
import formatters from './../../../Core/Grid/inputFormatter';
import BudgetFilter from '../budgetFilters';
import { postBranchOverviewKPLabour } from '../../../Core/Service/Nicol_Common_services';
import CircularIndeterminate from '../../../Core/BudgetCore/loader';
import { LinkedButton } from '../../../Core/BudgetCore/budgetButton';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { some } from 'lodash';

let searchTimer;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = (props, screenWidth) => [
    {
        id: 'st',
        width: '3%',
        label: 'Type'
    },
    {
        id: 'fr',
        width: '7%',
        label: 'Franchise'
    },
    {
        id: 'ld',
        width: '11%',
        label: 'Line Description'
    },
    {
        id: 'cust',
        width: '11%',
        label: 'Customer'
    },
    {
        id: 'sp',
        width: '11%',
        label: 'Exec'
    },
    {
        id: 'di',
        width: '7%',
        label: 'Added'
    },

    {
        id: 'dc',
        width: '7%',
        label: 'Costed',
        align: 'right'
    },

    {
        id: 'wn',
        width: '7%',
        label: 'WIP Number'
    },
    {
        id: 'sold',
        width: '3%',
        label: 'Hours Sold'
    },
    {
        id: 'taken',
        width: '3%',
        label: 'Hours Taken'
    },

    {
        id: 'sv',
        width: '5%',
        label: 'Sale Value',
        numeric: true,
        align: 'right'
    },
    {
        id: 'cv',
        width: '5%',
        label: 'Cost Value',
        numeric: true,
        align: 'right'
    },
    {
        id: 'gp',
        width: '5%',
        label: 'Profit',
        numeric: true,
        align: 'right'
    }
];

function EnhancedTableHead(props) {
    const [WindowWidths] = useWindowSize();
    const { classes, order, orderBy, onRequestSort, screenWidth = WindowWidths } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    let headerColumn = headCells(props, screenWidth);
    return (
        <TableHead>
            <TableRow>
                {headerColumn.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        width={headCell.width}
                        style={{ fontSize: 12, fontWeight: 600, padding: '0px 2px' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 800,
        '& .MuiTableCell-root': {
            padding: '5px 7px'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

const LabourGrid = (props) => {
    const classes = useStyles();
    const [WindowWidths] = useWindowSize();

    const [state, setState] = useState({
        isReload: new Date(),
        newEnquiryList: [],
        showLoader: true,
        filterText: '',
        filterTableData: []
    });

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const getFilterData = async (value) => {
        setState((st) => ({ ...st, showLoader: true }));

        let data = {
            filterDate: value.filterDate,
            franchiseCode: value.franchiseCode,
            branchID: value.branchID,
            type: props?.location?.state
        };
        let res = await postBranchOverviewKPLabour(data);
        if (res.success) {
            setState((st) => ({
                ...st,
                newEnquiryList: res.data.list,
                isReload: new Date(),
                showLoader: false,
                filterData: data
            }));
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const csvDownloadHandler = async () => {
        postBranchOverviewKPLabour(state.filterData, true, `${props?.location?.state === 'R' ? 'Retail' : 'Total'} Labour`);
    };

    const setFilterText = (e) => {
        const vl = e.target.value;
        setState((st) => ({ ...st, filterText: vl }));
        searchTimer && clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setFilteredRow();
        }, 500);
    };

    const setFilteredRow = () => {
        setState((st) => {
            const newSt = { ...st };
            const { filterText, newEnquiryList } = newSt;
            let filterd = [];
            filterd = [...newEnquiryList];
            if (filterText.trim().length > 0) {
                filterText
                    .trim()
                    .split(' ')
                    .map((t) => t.trim().toLowerCase())
                    .forEach((src) => {
                        filterd = filterd.filter((rw) => some(rw, (vl) => `${vl}`.toLowerCase().includes(src)));
                    });
            }
            newSt.filterTableData = filterd;
            return newSt;
        });
    };

    useEffect(() => {
        setFilteredRow();
    }, [state.newEnquiryList]);

    let RenderList = rowsPerPage > 0 ? [...state.filterTableData].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : state.filterTableData;

    return (
        <div className="budgetScreen">
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={6} sm={9} md={9} lg={10} xl={10}>
                    <div className="mainHeading alignLeft screenMainHeader">
                        {props?.location?.state === 'R' ? 'Retail' : props?.location?.state === 'I' ? 'Internal' : 'Total'} Labour
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={2} xl={2} style={{ marginTop: 5 }}>
                    <Grid container spacing={1} justifyContent="flex-end">
                        <Hidden only={['xl', 'lg', 'md']}>
                            <Grid item>
                                <BudgetFilter getFilterData={getFilterData} />
                            </Grid>
                        </Hidden>
                        <Grid item style={{ paddingTop: '4px' }}>
                            <LinkedButton onClick={csvDownloadHandler}>
                                <ViewComfyIcon />
                                {WindowWidths > 1024 ? <> &nbsp;&nbsp;DOWNLOAD CSV</> : ''}
                            </LinkedButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden only={['sm', 'xs']}>
                    <BudgetFilter getFilterData={getFilterData} />
                </Hidden>
                {state.showLoader ? (
                    <CircularIndeterminate />
                ) : (
                    <>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                required
                                name="Search"
                                label="Search"
                                onChange={setFilterText}
                                value={state.filterText}
                                style={{ maxHeight: 30, marginBottom: 20 }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TableContainer style={{ maxHeight: 500, width: '100%' }}>
                                <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table" stickyHeader>
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={state.filterTableData?.length}
                                        className="type3_Text"
                                    />
                                    <TableBody>
                                        {[...stableSort([...RenderList], getComparator(order, orderBy))].map((k, i) => (
                                            <>
                                                <TableRow key={i}>
                                                    <TableCell>{k.st}</TableCell>
                                                    <TableCell>{k.fr}</TableCell>
                                                    <TableCell>{k.ld}</TableCell>
                                                    <TableCell>{k.cust}</TableCell>
                                                    <TableCell>{k.sp}</TableCell>
                                                    <TableCell>{formatters.DateFormatter(k.di)}</TableCell>
                                                    <TableCell>{formatters.DateFormatter(k.dc)}</TableCell>
                                                    <TableCell>{k.wn}</TableCell>
                                                    <TableCell>{k.sold}</TableCell>
                                                    <TableCell>{k.taken}</TableCell>
                                                    <TableCell align="right">{formatters.CurrencyThousandSeparatorWithoutZero(k.sv)}</TableCell>
                                                    <TableCell align="right">{formatters.CurrencyThousandSeparatorWithoutZero(k.cv)}</TableCell>
                                                    <TableCell align="right">{formatters.CurrencyThousandSeparatorWithoutZero(k.gp)}</TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}
                                component="div"
                                count={state.filterTableData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
};

export default LabourGrid;

import { Grid, Hidden } from '@material-ui/core';
import '../../../Core/BudgetCore/budget.scss';
import { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { SalesCard } from '../../../Core/BudgetCore/budgetCardsUi_v2';

import { LinkedButton } from '../../../Core/BudgetCore/budgetButton';
import ReportingDetailsTable from './detailsTable';
import { postSalesReportingDetail, postSalesReportingDetailGetExcelFile } from '../../../Core/Service/Nicol_Common_services';
import BudgetFilter from '../budgetFilters';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';

const SalesReportDetails = () => {
    const [WindowWidths] = useWindowSize();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamType = params.get('type') || '';

    const [state, setState] = useState({
        branchList: [],
        franchise: [],
        unitsList: {},
        branchBudgets: {},
        groupIds: [],
        franchiseIds: [],
        mainData: [],
        tabledata: []
    });

    const getFilterData = async (value) => {
        setState((st) => ({ ...st, showLoader: true }));

        let data = {
            filterDate: value.newWeekFilter || [],
            franchiseCode: value.franchiseCode,
            branchID: value.branchID,
            Type: value.type
        };
        let res = await postSalesReportingDetail(data);
        if (res.success) {
            let mainData = res.data.mainData[0];
            setState((st) => ({
                ...st,
                mainData: [
                    {
                        label: 'Total New Retail Units',
                        subLabelOne: 'NEW PROFIT',
                        subLabelTwo: 'NEW PPU',
                        units: mainData.totalNewRetailUnits,
                        profit: mainData.newProfit,
                        ppu: mainData.newPPU
                    },
                    {
                        label: 'Total Used Retail Units',
                        subLabelOne: 'Used PROFIT',
                        subLabelTwo: 'Used PPU',
                        units: mainData.totalUsedRetailUnits,
                        profit: mainData.usedProfit,
                        ppu: mainData.usedPPU
                    },
                    {
                        label: 'Total Motability Units',
                        subLabelOne: 'MOTABILITY PROFIT',
                        subLabelTwo: 'MOTABILITY PPU',
                        units: mainData.totalMotabilityRetailUnits,
                        profit: mainData.motabilityProfit,
                        ppu: mainData.motabilityPPU
                    },
                    {
                        label: 'Total Fleet Units',
                        subLabelOne: 'Fleet PROFIT',
                        subLabelTwo: 'Fleet PPU',
                        units: mainData.totalFleetUnits,
                        profit: mainData.fleetProfit,
                        ppu: mainData.fleetPPU
                    },
                    {
                        label: 'Total Units',
                        subLabelOne: 'Total PROFIT',
                        subLabelTwo: 'Total PPU',
                        units: mainData.totalUnits,
                        profit: mainData.totalProfit,
                        ppu: mainData.totalPPU
                    }
                ],
                tabledata: res.data.tabledata
            }));
        }
    };

    const downloadCSVWrapper = async () => {
        let data = {
            filterDate: state.filterDate || [],
            franchiseCode: state.franchiseCode || [],
            branchID: state.branchID || []
        };
        await postSalesReportingDetailGetExcelFile(data, 'Sales_Reporting_Detail');
    };

    return (
        <div className="budgetScreen">
            <Grid container>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={6} sm={9} md={9} lg={10} xl={10}>
                        <div className="mainHeading alignLeft screenMainHeader">Sales Reporting Details</div>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={2} xl={2}>
                        <Grid container spacing={1} justifyContent="flex-end">
                            <Hidden only={['xl', 'lg', 'md']}>
                                <Grid item>
                                    <BudgetFilter getFilterData={getFilterData} type={ParamType} sales={true} isPermissionBased={false} />
                                </Grid>
                            </Hidden>
                            <Grid item>
                                <LinkedButton onClick={downloadCSVWrapper}>
                                    <ViewComfyIcon />
                                    {WindowWidths > 1024 ? <> &nbsp;&nbsp;DOWNLOAD CSV</> : ''}
                                </LinkedButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Hidden only={['sm', 'xs']}>
                    <BudgetFilter getFilterData={getFilterData} type={ParamType} sales={true} isPermissionBased={false} />
                </Hidden>

                <Grid item container xs={12} md={12} lg={12} xl={12} justifyContent="flex-start">
                    {state.mainData.map((m, i) => (
                        <Grid xs={6} sm={6} lg={2} xl={2} key={i}>
                            <SalesCard
                                label={m.label}
                                value={m.units}
                                newProfit={m.profit}
                                newPpu={m.ppu}
                                subLabelOne={m.subLabelOne}
                                subLabelTwo={m.subLabelTwo}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Grid item container xs={12} md={12} lg={12} xl={12} justifyContent="flex-start">
                    <div className="kpiCard">
                        <ReportingDetailsTable rows={state.tabledata} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default SalesReportDetails;

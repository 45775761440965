import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';

const BreadCrumbs = (props) => {
    return (
        <Breadcrumbs separator=">>" aria-label="breadcrumb">
            {props.crumbs
                ? props.crumbs.map((crumb, i) => {
                      return (
                          <div key={i}>
                              {crumb.active ? (
                                  <Typography color="secondary">{crumb.name}</Typography>
                              ) : (
                                  <Link to={crumb.path} style={{ textDecoration: 'none' }}>
                                      {crumb.name}
                                  </Link>
                              )}
                          </div>
                      );
                  })
                : null}
        </Breadcrumbs>
    );
};

export default BreadCrumbs;

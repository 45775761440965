import React from 'react';
import {
    Grid,
    Hidden,
    FormControlLabel,
    Switch,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    makeStyles,
    TableContainer,
    Table,
    TableBody,
    TablePagination
} from '@material-ui/core';
import { TextBox } from '../../Core/FormInput';
import { useState } from 'react';
import BudgetFilter from './budgetFilters';
import { postBranchOverviewKPIInvoicedNewUnits } from '../../Core/Service/Nicol_Common_services';
import formatters from '../../Core/Grid/inputFormatter';
import CircularIndeterminate from '../../Core/BudgetCore/loader';
import { LinkedButton } from '../../Core/BudgetCore/budgetButton';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { useWindowSize } from '../../Core/Controls/ScreenResolution';
import { useEffect } from 'react';
import { some } from 'lodash';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DialogComp from '../../Core/Controls/Dialog/DialogComp';

let searchTimer;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = (props, screenWidth) => [
    {
        id: 'action',
        width: '2%',
        label: ''
    },
    {
        id: 'branch',
        width: '12%',
        label: 'BRANCH'
    },
    {
        id: 'reg',
        width: '10%',
        label: 'Reg'
    },
    {
        id: 'v',
        label: 'Vehicle'
    },
    {
        id: 'sp',
        label: 'Exec'
    },
    {
        id: 'cust',
        width: '12%',
        label: 'Customer'
    },

    {
        id: 'id',
        width: '14%',
        label: 'Invoice Date',
        align: 'right'
    },
    {
        id: 'gp',
        width: '10%',
        label: 'Gross Profit',
        numeric: true,
        align: 'right'
    }
];

function EnhancedTableHead(props) {
    const [WindowWidths] = useWindowSize();
    const { classes, order, orderBy, onRequestSort, screenWidth = WindowWidths } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    let headerColumn = headCells(props, screenWidth);
    return (
        <TableHead>
            <TableRow>
                {headerColumn.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        width={headCell.width}
                        style={{ fontSize: 12, fontWeight: 600, padding: '0px 2px' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 800,
        '& .MuiTableCell-root': {
            padding: '5px 7px'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

const DetailTemplate = ({ props }) => {
    return (
        <div style={{ width: '100%', padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>VSB</b>
                        <br />
                        {props.vsb}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Order Number </b>
                        <br />
                        {props.ord}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Sale Price</b>
                        <br />
                        {props.sale}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Cost Price</b>
                        <br />
                        {props.cost}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Disc O/A </b>
                        <br />
                        {props.DiscOval}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Bonuses </b>
                        <br />
                        {props.bonuses}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Subsidy </b>
                        <br />
                        {props.subsidy}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>CPI</b>
                        <br />
                        {props.cpi}
                    </span>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Bought By</b>
                        <br />
                        {props.buyer}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Finance Company</b>
                        <br />
                        {props.fcompany}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Finance</b>
                        <br />
                        {props.finance}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>PDI</b>
                        <br />
                        {props.pdi}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>DFA Sale</b>
                        <br />
                        {props.dfaSale}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>DFA Cost</b>
                        <br />
                        {props.dfaCost}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Intro Comm</b>
                        <br />
                        {props.introCom}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Total Cost </b>
                        <br />
                        {props.tc}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Total Sale </b>
                        <br />
                        {props.ts}
                    </span>
                </Grid>
            </Grid>
        </div>
    );
};

const NewVehicleProfitReport = (props) => {
    const classes = useStyles();

    const [WindowWidths] = useWindowSize();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    let includeReservedParams = params.get('includeReserved') === 'true' ? true : false;

    const [state, setState] = useState({
        invoiceList: [],
        showLoader: true,
        includeReserved: includeReservedParams || false,
        filterText: '',
        showRow: 0,
        showData: false,
        gridData: {},
        branchName: '',
        filterTableData: []
    });

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const handleModal = (value) => {
        setState((st) => ({ ...st, gridData: value ? value : {}, showData: !st.showData, branchName: value?.loc || '' }));
    };

    const getFilterData = async (value) => {
        let data = {
            filterDate: value.filterDate,
            franchiseCode: value.franchiseCode,
            branchID: value.branchID,
            type: props?.location?.state,
            includeReserved: state.includeReserved
        };

        let isCurrencyFormatterList = [
            { paramName: 'sale', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'cost', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'DiscOval', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'bonuses', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'subsidy', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'cpi', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'finance', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'pdi', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'introCom', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'tc', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'ts', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'dfaSale', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'dfaCost', formatterName: 'CurrencyThousandSeparator' }
        ];

        let res = await postBranchOverviewKPIInvoicedNewUnits(data);
        if (res.success) {
            setState((st) => ({
                ...st,
                invoiceList: res.data.list.map((p) => {
                    isCurrencyFormatterList.forEach((o) => {
                        p[o.paramName] = formatters[o.formatterName](p[o.paramName]);
                    });
                    return { ...p };
                }),
                isReload: new Date(),
                filterData: data,
                showLoader: false
            }));
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const csvDownloadHandler = async () => {
        postBranchOverviewKPIInvoicedNewUnits(state.filterData, true, `New Invoiced`);
    };

    const setFilterText = (e) => {
        const vl = e.target.value;
        setState((st) => ({ ...st, filterText: vl }));
        searchTimer && clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setFilteredRow();
        }, 500);
    };

    const setFilteredRow = () => {
        setState((st) => {
            const newSt = { ...st };
            const { filterText, invoiceList } = newSt;
            let filterd = [];
            filterd = [...invoiceList];
            if (filterText.trim().length > 0) {
                filterText
                    .trim()
                    .split(' ')
                    .map((t) => t.trim().toLowerCase())
                    .forEach((src) => {
                        filterd = filterd.filter((rw) => some(rw, (vl) => `${vl}`.toLowerCase().includes(src)));
                    });
            }
            newSt.filterTableData = filterd;
            return newSt;
        });
    };

    useEffect(() => {
        setFilteredRow();
    }, [state.invoiceList]);

    let RenderList = rowsPerPage > 0 ? [...state.filterTableData].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : state.filterTableData;

    const handleincludeReserved = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            let newSt = { ...st };
            newSt[name] = checked;
            return newSt;
        });
    };

    return (
        <div className="budgetScreen">
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} sm={9} md={9} lg={10} xl={10}>
                        <div className="mainHeading alignLeft screenMainHeader">
                            New Invoiced
                            <FormControlLabel
                                control={<Switch checked={state.includeReserved} onChange={handleincludeReserved} name="includeReserved" />}
                                label="Include Reserved"
                                labelPlacement="start"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={2} xl={2} style={{ marginTop: 5 }}>
                        <Grid container spacing={1} justifyContent="flex-end">
                            <Hidden only={['xl', 'lg', 'md']}>
                                <Grid item>
                                    <BudgetFilter getFilterData={getFilterData} includeReserved={state.includeReserved} />
                                </Grid>
                            </Hidden>
                            <Grid item style={{ paddingTop: '4px' }}>
                                <LinkedButton onClick={csvDownloadHandler}>
                                    <ViewComfyIcon />
                                    {WindowWidths > 1024 ? <> &nbsp;&nbsp;DOWNLOAD CSV</> : ''}
                                </LinkedButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Hidden only={['sm', 'xs']}>
                    <BudgetFilter getFilterData={getFilterData} includeReserved={state.includeReserved} />{' '}
                </Hidden>

                {state.showLoader ? (
                    <CircularIndeterminate />
                ) : (
                    <>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                required
                                name="Search"
                                label="Search"
                                onChange={setFilterText}
                                value={state.filterText}
                                style={{ maxHeight: 30, marginBottom: 20 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer style={{ maxHeight: 500, width: '100%' }}>
                                <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table" stickyHeader>
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={state.filterTableData?.length}
                                        className="type3_Text"
                                    />
                                    <TableBody>
                                        {[...stableSort([...RenderList], getComparator(order, orderBy))].map((k, i) => (
                                            <>
                                                <TableRow key={i}>
                                                    <TableCell>
                                                        <NavigateNextIcon
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleModal(k);
                                                            }}
                                                        />
                                                    </TableCell>

                                                    {/* <TableCell>{k.vsb}</TableCell> */}
                                                    <TableCell>{k.loc}</TableCell>
                                                    <TableCell>{k.reg}</TableCell>
                                                    <TableCell>{k.v}</TableCell>
                                                    <TableCell>{k.sp}</TableCell>
                                                    <TableCell>{k.cust}</TableCell>
                                                    <TableCell>{formatters.DateFormatter(k.id)}</TableCell>
                                                    <TableCell align="right">{formatters.CurrencyThousandSeparatorWithoutZero(k.gp)}</TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}
                                component="div"
                                count={state.filterTableData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </>
                )}

                {state.showData ? (
                    <DialogComp maxWidth="lg" onClose={handleModal} title={state.branchName}>
                        <DetailTemplate props={state.gridData} />
                    </DialogComp>
                ) : null}
            </Grid>
        </div>
    );
};

export default NewVehicleProfitReport;

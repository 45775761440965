import React, { useEffect, useState } from 'react';
import { IdleTimeOutModal } from './SessionModal';
import { AppStorage } from '../Service/storage-service';
import moment from 'moment';

const IdleTimeOutHandler = (props) => {
    let timer = undefined;
    const events = ['click', 'load', 'keydown'];
    let appStorageTimeOut = AppStorage?.getPortalSetting()?.portalSettingTimeOut;
    let userMinutes = appStorageTimeOut ? appStorageTimeOut != null && appStorageTimeOut - 1 : 59;
    let TimeUserMilliSecond = userMinutes * 60000;
    const [showModal, setShowModal] = useState(false);
    const [isLogout, setLogout] = useState(false);

    const eventHandler = (eventType) => {
        if (!isLogout) {
            localStorage.setItem('lastInteractionTime', moment());
            if (timer) {
                props.onActive();
                startTimer();
            }
        }
    };

    useEffect(() => {
        addEvents();
        return () => {
            removeEvents();
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('_LastLoginTime_')) > new Date().getTime()) {
            props.Logout();
        }
    }, []);

    const startTimer = () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(
            () => {
                let lastInteractionTime = localStorage.getItem('lastInteractionTime');
                const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
                let timeOutInterval = props.timeOutInterval ? props.timeOutInterval : 6000;
                if (isLogout) {
                    clearTimeout(timer);
                } else {
                    if (diff._milliseconds < timeOutInterval) {
                        startTimer();
                        props.onActive();
                    } else {
                        props.onIdle();
                        setShowModal(true);
                    }
                }
            },
            TimeUserMilliSecond ? TimeUserMilliSecond : 300000
        );
    };

    const addEvents = () => {
        events.forEach((eventName) => {
            window.addEventListener(eventName, eventHandler);
            startTimer();
        });
    };

    const removeEvents = () => {
        events.forEach((eventName) => {
            window.removeEventListener(eventName, eventHandler);
        });
    };

    const handleContinueSession = () => {
        setShowModal(false);
        setLogout(false);
    };
    const handleLogout = () => {
        removeEvents();
        clearTimeout(timer);
        setLogout(true);
        props.onLogout();
        setShowModal(false);
        props.Logout();
    };

    return (
        <div>
            <IdleTimeOutModal showModal={showModal} handleContinue={handleContinueSession} handleLogout={handleLogout} Logout={props.Logout} />
        </div>
    );
};

export default IdleTimeOutHandler;

import * as Components from './../../../../Components';
import { TypeOfAuth } from './../menu-auth-type';
// import { Navbar } from './../../Navbar';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';

export default [
    {
        label: 'Home',
        path: '/',
        exact: true,
        component: Components.home,
        authType: TypeOfAuth.Auth,
        icon: <HomeIcon />
    },
    {
        label: 'Branches',
        path: '/branches',
        exact: true,
        component: Components.Braches,
        authType: TypeOfAuth.Auth,
        icon: <BuildIcon />
    },
    {
        label: 'Users',
        path: '/users',
        component: Components.userManagement,
        authType: TypeOfAuth.Auth,
        icon: <PersonIcon />
    },
    {
        label: 'Settings',
        path: '/Settings',
        icon: <SettingsIcon />,
        exact: true,
        component: Components.Settings,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Logout',
        path: '/logout',
        hidden: true,
        component: Components.logout,
        authType: TypeOfAuth.Auth
    }
];

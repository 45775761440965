import { Grid, FormLabel, FormControlLabel } from '@material-ui/core';
import React from 'react';
import TabView from '../../../Core/Controls/Tabs';
import { SecondaryCheckbox, Multiselect } from '../../../Core/FormInput';

export default function UserModules(props) {
    const { state, handleCheckbox, AutoFranchiseChange, selectedData, franchiseData } = props;
    const tabs = [
        ...(state.userType == 'internal'
            ? [
                {
                    label: 'Reporting',
                    body: (
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormLabel component="legend" className="btn_add">
                                    Reporting Access
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    control={<SecondaryCheckbox checked={state.eReportingAllowed} onChange={handleCheckbox} name="eReportingAllowed" />}
                                    label="Reporting Allowed?"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel component="legend">Allowed Reports</FormLabel>
                            </Grid>
                            <Grid container item spacing={1}>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={
                                            <SecondaryCheckbox
                                                checked={state.canAccessBranchOverview}
                                                onChange={handleCheckbox}
                                                name="canAccessBranchOverview"
                                            />
                                        }
                                        label="Branch Overview?"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={
                                            <SecondaryCheckbox
                                                checked={state.canAccessSalesProfitAndLoss}
                                                onChange={handleCheckbox}
                                                name="canAccessSalesProfitAndLoss"
                                            />
                                        }
                                        label="Sales Profit And Loss?"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={
                                            <SecondaryCheckbox
                                                checked={state.canAccessAfterSalesProfitAndLoss}
                                                onChange={handleCheckbox}
                                                name="canAccessAfterSalesProfitAndLoss"
                                            />
                                        }
                                        label="After Sales Profit And Loss?"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={
                                            <SecondaryCheckbox
                                                checked={state.canAccessProfitAndLossDetail}
                                                onChange={handleCheckbox}
                                                name="canAccessProfitAndLossDetail"
                                            />
                                        }
                                        label="Profit And Loss Detail?"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={
                                            <SecondaryCheckbox
                                                checked={state.canAccessUsedStockValuation}
                                                onChange={handleCheckbox}
                                                name="canAccessUsedStockValuation"
                                            />
                                        }
                                        label="Used Stock Valuation?"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={
                                            <SecondaryCheckbox
                                                checked={state.canAccessUsedVehicleStock}
                                                onChange={handleCheckbox}
                                                name="canAccessUsedVehicleStock"
                                            />
                                        }
                                        label="Used Vehicle Stock?"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={
                                            <SecondaryCheckbox
                                                checked={state.canAccessAgedVehicleStock}
                                                onChange={handleCheckbox}
                                                name="canAccessAgedVehicleStock"
                                            />
                                        }
                                        label="Aged Vehicle Stock?"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={
                                            <SecondaryCheckbox
                                                checked={state.canAccessOveragePriorityList}
                                                onChange={handleCheckbox}
                                                name="canAccessOveragePriorityList"
                                            />
                                        }
                                        label="Overage Priority List?"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={
                                            <SecondaryCheckbox
                                                checked={state.canAccessSalesReporting}
                                                onChange={handleCheckbox}
                                                name="canAccessSalesReporting"
                                            />
                                        }
                                        label="Sales Reporting?"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={
                                            <SecondaryCheckbox
                                                checked={state.canAccessUncappedVehicled}
                                                onChange={handleCheckbox}
                                                name="canAccessUncappedVehicled"
                                            />
                                        }
                                        label="Uncapped Vehicles?"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Multiselect
                                    label="Franchise Record"
                                    options={franchiseData.franchises}
                                    value={selectedData.franchises}
                                    onChange={AutoFranchiseChange}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            ]
            : [])
    ];

    return (
        <div className="screen">
            <TabView tabList={tabs} id="testing_tabs" tabLableSize="1.0rem" tabLableWeight="600" />
        </div>
    );
}

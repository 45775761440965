import { FormControlLabel, Switch } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import { FilterBtn, SmallLinkedButton } from '../../../Core/BudgetCore/budgetButton';
import { UsedStocksCard, UsedStocksCardMobileView, UsedStocksCardTWO } from '../../../Core/BudgetCore/budgetCardsUi_v2';
import CallMadeIcon from '@material-ui/icons/CallMade';
import EventIcon from '@material-ui/icons/Event';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, LabelList, Cell } from 'recharts';
import { getUsedStockValuation } from '../../../Core/Service/Nicol_Common_services';
import formatters from '../../../Core/Grid/inputFormatter';
import { useHistory } from 'react-router-dom';
import CircularIndeterminate from '../../../Core/BudgetCore/loader';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { AppStorage } from '../../../Core/Service/storage-service';

const filterBtnList = [
    { label: 'COMBINED', id: 'C' },
    { label: 'RETAIL', id: 'R' },
    { label: 'TRADE', id: 'T' }
];

const renderCustomizedLabel = (props) => {
    const { x, y, width, value, formatter } = props;

    return (
        <text
            x={x + width / 2}
            y={value > 0 ? y - 10 : y + 10}
            fill={'red'}
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={10}
            fontWeight={'bold'}
            color="red"
        >
            {formatter ? formatters[`${formatter}`](value) : value}
        </text>
    );
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div
                style={{
                    backgroundColor: 'white',
                    border: '1px solid black',
                    padding: '10px',
                    textAlign: 'center'
                }}
            >
                <div>{label}</div>
                <div style={{ color: '#1ECBE1' }}>
                    {payload[0].name}: &nbsp;
                    {formatters.CurrencyThousandSeparatorWithoutZero(+payload[0].value)}
                </div>
            </div>
        );
    }

    return null;
};

const UsedStockValuation = () => {
    const [state, setState] = useState({
        groupIds: [],
        gpStock: {},
        mainData: [],
        selectedFilterBtn: filterBtnList[0].id,
        showLoader: true,
        includeReserved: true
    });

    const [WindowWidths] = useWindowSize();

    const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));

    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            try {
                let combineData = [];
                let res = await getUsedStockValuation(state.selectedFilterBtn, state.includeReserved);
                if (res.success) {
                    if (res?.data?.mainData != null && res?.data?.mainData.length > 0) {
                        combineData = res?.data?.mainData.map((p) => {
                            let weeklyDataByIdList = res?.data?.weeklyData.filter((q) => q.branchID == p.branchID);
                            p.weeklyDataList = [...weeklyDataByIdList].sort(
                                (a, b) => moment(b.valuationDate1).format('YYYYMMDD') - moment(a.valuationDate1).format('YYYYMMDD')
                            );
                            p.weeklyDataList = p.weeklyDataList.map((m, index) => ({
                                ...m,
                                getCalculatedvalue:
                                    ((m.stockValue1 - p.weeklyDataList[index + 1]?.stockValue1) / p.weeklyDataList[index + 1]?.stockValue1) * 100
                            }));
                            return p;
                        });
                    }
                }

                setState((st) => ({
                    ...st,
                    gpStock: res?.data?.gpStock[0],
                    gpValueData: res?.data?.gpValue,
                    mainData: combineData || [],
                    showLoader: false
                }));
            } catch (error) {
                // Handle any errors that might occur during the asynchronous operation
                console.error('An error occurred:', error);
            }
        };

        fetchData();
    }, [state.startDate, state.endDate, state.groupIds, state.selectedFilterBtn, state.includeReserved]);

    const filterBtnChange = (id) => {
        if (id._reactName === 'onChange') {
            setState((st) => ({
                ...st,
                selectedFilterBtn: id.target.value
            }));
        } else {
            setState((st) => ({
                ...st,
                selectedFilterBtn: id
            }));
        }
    };

    const handleRedirect = (link) => {
        history.push({
            pathname: link
        });
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: [] }));
    };
    const isUsedVehicleStockAllowed = AppStorage.getReportingCanAccessUsedVehicleStock();
    const isOveragePriorityListAllowed = AppStorage.getReportingCanAccessOveragePriorityList();

    const handleincludeReserved = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            let newSt = { ...st };
            newSt[name] = checked;
            return newSt;
        });
    };

    return (
        <div className="budgetScreen">
            {state.showLoader ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <Grid container spacing={2} alignContent="center" justifyContent="space-between">
                        <Grid item>
                            <div className="mainHeading alignLeft screenMainHeader">
                                Used Stock Valuation{' '}
                                <FormControlLabel
                                    style={{ marginLeft: '4px', marginTop: WindowWidths < 600 ? '1px' : '8px' }}
                                    control={<Switch checked={state.includeReserved} onChange={handleincludeReserved} name="includeReserved" />}
                                    label="Include Vehicle Reserved"
                                    labelPlacement="start"
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} sm={6} lg={5} xl={5}>
                        <>
                            {WindowWidths >= 600 ? (
                                filterBtnList.map((p) => {
                                    return (
                                        <Grid item xs={4} className="containerPadding" key={p.id}>
                                            <FilterBtn active={state.selectedFilterBtn == p.id} onClick={() => filterBtnChange(p.id)}>
                                                {p.label}
                                            </FilterBtn>
                                        </Grid>
                                    );
                                })
                            ) : (
                                <select
                                    name="selectedFilterBtn"
                                    value={state.selectedFilterBtn}
                                    onChange={filterBtnChange}
                                    style={{
                                        width: '99%',
                                        padding: 10,
                                        background: '#dcd4fc',
                                        color: '#522bd2',
                                        textAlign: 'center',
                                        fontFamily: `Montserrat`,
                                        fontWeight: 600,
                                        border: '0.0625rem solid #522bd2',
                                        marginTop: 10,
                                        marginBottom: 10
                                    }}
                                >
                                    {filterBtnList.map((p, i) => {
                                        return (
                                            <option key={i + 1} value={p.id} style={{ background: 'white' }}>
                                                {p.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            )}
                        </>
                    </Grid>

                    <Grid container justifyContent="space-evenly">
                        <Grid item xs={12} sm={12} lg={5} xl={5} className="containerPadding">
                            <Grid container alignItems="flex-start" justifyContent="space-between" className="USVbranchOverViewKip_card">
                                <Grid item xs={8} lg={10} xl={10}>
                                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                                        <Grid item xs={12}>
                                            <div className="USVsecondaryHeading">GROUP STOCK</div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={WindowWidths >= 600 ? 2 : 1}>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                <EventIcon className="AvatarFont" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ width: '100%' }}>
                                                                <div className="USVtype1_Text alignLeft">
                                                                    {formatters.ThousandSeparatorWithoutZero(state.gpStock?.totalUnitStock)}
                                                                </div>
                                                                <div className="USVtype3_Text alignLeft">Total Units in Stock</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                <EventIcon className="AvatarFont" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ width: '100%', marginBottom: WindowWidths >= 600 ? 10 : 0 }}>
                                                                <div className="USVtype1_Text alignLeft">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state.gpStock?.totalStockValue)}
                                                                </div>
                                                                <div className="USVtype3_Text alignLeft">Total Stock Value</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                <EventIcon className="AvatarFont" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ width: '100%', marginBottom: WindowWidths >= 600 ? 10 : 0 }}>
                                                                <div className="USVtype1_Text alignLeft">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state.gpStock?.avgSIV)}
                                                                </div>
                                                                <div className="USVtype3_Text alignLeft">Average SIV</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                <EventIcon className="AvatarFont" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ width: '100%', marginBottom: 10 }}>
                                                                <div className="USVtype1_Text alignLeft">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state.gpStock?.previousYearAvg)}
                                                                </div>
                                                                <div className="USVtype3_Text alignLeft">Previous Year Average</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} lg={2} xl={2}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                {isUsedVehicleStockAllowed && (
                                                    <Grid item xs={12}>
                                                        <SmallLinkedButton onClick={() => handleRedirect('/usedStock')}>
                                                            STOCK LIST &nbsp; <CallMadeIcon style={{ fontSize: 18 }} />
                                                        </SmallLinkedButton>
                                                    </Grid>
                                                )}
                                                {isOveragePriorityListAllowed && (
                                                    <Grid item xs={12}>
                                                        <SmallLinkedButton onClick={() => handleRedirect('/overagePriority')}>
                                                            OVERAGE &nbsp; <CallMadeIcon style={{ fontSize: 18 }} />
                                                        </SmallLinkedButton>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={7} xl={7} className="containerPadding">
                            <div style={{ display: 'flex', height: '100%' }}>
                                <div style={{ width: '100%' }}>
                                    <div className="f-RegularSize alignLeft mb10">GROUP STOCK VALUE - COMBINED (YR)</div>{' '}
                                    <ResponsiveContainer className="AVSGraph">
                                        <BarChart
                                            data={state.mainData}
                                            margin={{ top: 20, right: WindowWidths >= 600 ? 30 : 10, left: WindowWidths >= 600 ? -10 : -30, bottom: 5 }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Bar dataKey="stockValue" fill={'black'} barSize={35} height={40}>
                                                <LabelList
                                                    dataKey={`stockValue`}
                                                    content={(props) => renderCustomizedLabel({ ...props, formatter: 'CurrencyThousandSeparatorWithoutZero' })}
                                                    angle="45"
                                                />

                                                {state.mainData.map((entry, index) => {
                                                    return <Cell key={`cell-${index}`} fill={'#569101'} />;
                                                })}
                                            </Bar>
                                            <XAxis
                                                dataKey="branchShortCode"
                                                textAnchor="end"
                                                sclaeToFit="true"
                                                style={{
                                                    fontSize: 10,
                                                    fontWeight: 700
                                                }}
                                            />
                                            <YAxis orientation="left" style={{ fontSize: 8 }} />
                                            <Tooltip />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    {state.mainData.map((p, i) => {
                        return (
                            <React.Fragment key={`${i}_USV`}>
                                <Grid item container xl={12} justifyContent="space-evenly" alignItems="center">
                                    {WindowWidths >= 600 && (
                                        <Grid item xl={1} lg={1} md={4} sm={4} xs={12} className="containerPadding">
                                            <div
                                                className="USVUnitKipOne_card"
                                                style={{
                                                    background: '#569101',
                                                    color: 'white',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <div>
                                                    <div className="USVUnitHeading">{p.units}</div>
                                                    <div className="USVUnitHeading">Units</div>
                                                </div>
                                            </div>
                                        </Grid>
                                    )}
                                    {WindowWidths >= 600 ? (
                                        <Grid item xl={4} lg={4} md={8} sm={8} xs={12} className="containerPadding">
                                            <UsedStocksCard
                                                CurrentStockValue={p.stockValue}
                                                AverageStockValue={p.avgStockValue}
                                                PreviousYearAverage={p.prevYearAvgValue}
                                                stockLink={'/usedStock'}
                                                showStock={isUsedVehicleStockAllowed}
                                                showOverage={isOveragePriorityListAllowed}
                                                priorityLink={'/overagePriority'}
                                                history={history}
                                                branchID={p.branchID}
                                                branch={p.branch}
                                                setState={setState}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} className="containerPadding" key={i}>
                                            <UsedStocksCardMobileView
                                                CurrentStockValue={p.stockValue}
                                                AverageStockValue={p.avgStockValue}
                                                PreviousYearAverage={p.prevYearAvgValue}
                                                stockLink={'/usedStock'}
                                                showStock={isUsedVehicleStockAllowed}
                                                showOverage={isOveragePriorityListAllowed}
                                                priorityLink={'/overagePriority'}
                                                history={history}
                                                branchID={p.branchID}
                                                branch={p.branchShortName}
                                                Units={p.units}
                                                setState={setState}
                                            />
                                        </Grid>
                                    )}

                                    {WindowWidths >= 600 && (
                                        <Grid item xl={7} lg={7} md={12} sm={12} xs={12} className="containerPadding">
                                            <UsedStocksCardTWO list={p.weeklyDataList || []} />
                                        </Grid>
                                    )}
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default UsedStockValuation;
